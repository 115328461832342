import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'axios'
import customAxios from '../../utils/axios'
import CONFIG from '../../config'
import { handleLogout } from '../../utils'

export const userLogin = createAsyncThunk('USER_LOGIN', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await Axios.post(CONFIG.api.auth, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getRefreshToken = createAsyncThunk('GET_REFRESH_TOKEN', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.refreshToken).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const userForgotPassword = createAsyncThunk('USER_FORGOT_PASSWORD', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await Axios.post(CONFIG.api.forgotPassword(payload.email)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const userResetPassword = createAsyncThunk('USER_RESET_PASSWORD', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await Axios.post(CONFIG.api.resetPassword(payload.resetString, payload.password)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
