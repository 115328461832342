import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { getDefaultCustomer, createCustomer, updateCustomer, clearDefaultCustomerData, setToast, createLogSnag } from '../../redux'
import config from '../../config'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { PageLoader } from '../../components/common/PageLoader'
import CustomerDetails from '../../components/customers/customerDetails'
import BankDetails from '../../components/customers/bankDetails'
import CustomerInfo from '../../components/customers/customerInfo'
import { posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

import { MdOutlineBorderColor } from 'react-icons/md'
import { Breadcrumb, Space, Button, Collapse } from 'antd'
import type { CollapseProps } from 'antd'

const CreateCustomer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const { customerId }: any = useParams()

  const { isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, customerActionLoading, defaultCustomer, success } = useAppSelector((state: RootState) => state.customer)
  const { elementSize } = useAppSelector((state: RootState) => state.common)

  const [viewCustomer, setViewCustomer] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isWhatsappNumber, setIsWhatsappNumber] = useState<boolean>(true)
  const [whatsappNumber, setWhatsappNumber] = useState<string>('')
  const [email, setEmail] = useState<any>('')
  const [address, setAddress] = useState<string>('')
  const [gender, setGender] = useState<string>('M')
  const [homeBranch, setHomeBranch] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [bankName, setBankName] = useState<string>('')
  const [accountNumber, setAccountNumber] = useState<number>()
  const [ifscCode, setIfscCode] = useState<string>('')
  const [accountHolderName, setAccountHolderName] = useState<string>('')

  const [firstNameErr, setFirstNameErr] = useState<string>('')
  const [phoneNumberErr, setPhoneNumberErr] = useState<string>('')
  const [whatsappNoErr, setWhatsappNoErr] = useState<string>('')
  const [emailErr, setEmailErr] = useState<string>('')

  useEffect(() => {
    customerId && dispatch(getDefaultCustomer({ customerId }))
    handleViewCustomer()
  }, [dispatch, dispatch])

  useEffect(() => {
    handleSetCustomerValues()
  }, [defaultCustomer])

  useEffect(() => {
    success.type === 'Success' && handleNavigateToCustomer()
  }, [success])

  const handleNavigateToCustomer = () => {
    dispatch(clearDefaultCustomerData())
    navigate(config.pages.customer)
  }

  const handleSetCustomerValues = () => {
    const data = defaultCustomer
    if (Object.keys(defaultCustomer).length) {
      setFirstName(data.firstName)
      setLastName(data.lastName)
      setPhoneNumber(data.phoneNumber)
      setIsWhatsappNumber(data.isWhatsappNumber)
      setWhatsappNumber(data.whatsappNumber)
      setEmail(data.email)
      setAddress(data.address)
      setGender(data.gender)
      setHomeBranch(data.homeBranch)
      setDescription(data.description)
      setBankName(data.bankName)
      setAccountNumber(data.accountNumber)
      setIfscCode(data.ifscCode)
      setAccountHolderName(data.accountHolderName)
    }
  }

  const handleViewCustomer = () => {
    setViewCustomer(location.pathname.includes('view'))
  }

  const handleCreateCustomerSave = async () => {
    setFirstNameErr(firstName.length < 3 ? 'ERROR' : '')
    setPhoneNumberErr(!phoneNumber.toString()?.length ? 'ERROR' : '')
    setWhatsappNoErr(!isWhatsappNumber?.toString()?.length ? 'ERROR' : '')

    if (firstName.length > 3 && phoneNumber.toString().length && (!isWhatsappNumber ? whatsappNumber.toString().length : true)) {
      const payload: any = {
        firstName: firstName.trim(),
        lastName: lastName ? lastName.trim() : '',
        extension: '91',
        phoneNumber: phoneNumber.toString(),
        isWhatsappNumber,
        address: address ? address.trim() : '',
        description: description ? description.trim() : '',
        whatsappNumber: !isWhatsappNumber ? whatsappNumber.toString() : '',
        email: email ? email.trim() : '',
        gender,
        dateOfBirth: null,
        homeBranch: homeBranch ? homeBranch.trim() : '',
        isActive: defaultCustomer.isActive,
        bankName: bankName ? bankName.trim() : '',
        accountNumber: accountNumber?.toString(),
        ifscCode: ifscCode ? ifscCode.trim() : '',
        accountHolderName: accountHolderName ? accountHolderName.trim() : ''
      }
      if (customerId) {
        payload['ID'] = parseInt(customerId, 10)
        const res: any = await dispatch(updateCustomer(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.updatedCustomerSuccess')
          }))
          posthogCaptureEvent('update_action', { type: 'customer' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      } else {
        const logSnagPayload = {
          channel: 'customers',
          event: 'New Customer Created',
          description: JSON.stringify(payload)
        }
        dispatch(createLogSnag(logSnagPayload))
        const res: any = await dispatch(createCustomer(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.createdCustomerSuccess')
          }))
          posthogCaptureEvent('create_action', { type: 'customer' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      }
    }
  }

  const handleViewClick = () => {
    setViewCustomer(true)
    posthogCaptureEvent('view_screen', { type: 'customer' })
    navigate(`${config.pages.view_customer}/${customerId}`)
  }

  const handleEditClick = () => {
    setViewCustomer(false)
    posthogCaptureEvent('edit_screen', { type: 'customer' })
    navigate(`${config.pages.edit_customer}/${customerId}`)
  }

  const defaultItems: CollapseProps['items'] = [
    {
      key: '1',
      label: t('createCustomer.basicDetails'),
      children: (
        <CustomerDetails
          viewCustomer={viewCustomer}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          whatsappNumber={whatsappNumber}
          isWhatsappNumber={isWhatsappNumber}
          email={email}
          address={address}
          gender={gender}
          homeBranch={homeBranch}
          description={description}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setPhoneNumber={setPhoneNumber}
          setIsWhatsappNumber={setIsWhatsappNumber}
          setWhatsappNumber={setWhatsappNumber}
          setEmail={setEmail}
          setAddress={setAddress}
          setGender={setGender}
          setHomeBranch={setHomeBranch}
          setDescription={setDescription}
          firstNameErr={firstNameErr}
          phoneNumberErr={phoneNumberErr}
          whatsappNoErr={whatsappNoErr}
          emailErr={emailErr}
          setFirstNameErr={setFirstNameErr}
          setPhoneNumberErr={setPhoneNumberErr}
          setWhatsappNoErr={setWhatsappNoErr}
          setEmailErr={setEmailErr}
        />
      )
    },
    {
      key: '2',
      label: t('createCustomer.bankDetails'),
      children: (
        <BankDetails
          viewCustomer={viewCustomer}
          bankName={bankName}
          accountNumber={accountNumber}
          ifscCode={ifscCode}
          accountHolderName={accountHolderName}
          setBankName={setBankName}
          setAccountNumber={setAccountNumber}
          setIfscCode={setIfscCode}
          setAccountHolderName={setAccountHolderName}
        />
      )
    }
  ]

  const items: CollapseProps['items'] = [
    ...defaultItems,
    {
      key: '3',
      label: t('createCustomer.info.title'),
      children: <CustomerInfo customerInfo={defaultCustomer} />
    }
  ]

  return (
    <div>
      {isLoading || customerActionLoading ? (
        <PageLoader />
      ) : (
        <div style={{ padding: '10px 0' }}>
          <div className="d-flex justify-between align-center" style={{ margin: '10px 0 30px 0' }}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <div className="c-pointer" onClick={handleNavigateToCustomer}>
                      {t('createCustomer.customer')}
                    </div>
                  )
                },
                { title: <div>{firstName || t('common.createCustomer')}</div> }
              ]}
            />
            <Space className="d-flex justify-between" style={{ gap: '10px' }}>
              {!isUser && customerId &&
                (viewCustomer ? (
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost onClick={handleEditClick}>
                    <span> {t('common.edit')} </span>
                    <MdOutlineBorderColor />
                  </Button>
                ) : (
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} onClick={handleViewClick}>
                    <span> {t('common.view')} </span>
                  </Button>
                ))}
            </Space>
          </div>
          <Collapse accordion={viewCustomer} items={viewCustomer ? items : defaultItems} defaultActiveKey={viewCustomer ? ['1'] : ['1', '2']} />
          {!viewCustomer && (
            <div className="create-footer">
              <Button size={elementSize} loading={customerActionLoading} style={{ marginRight: '10px' }} onClick={handleNavigateToCustomer}>
                {t('common.cancel')}
              </Button>
              <Button size={elementSize} type="primary" loading={customerActionLoading} onClick={handleCreateCustomerSave}>
                {customerId ? t('common.update') : t('common.create')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CreateCustomer
