import React, { useState, useEffect } from 'react'
import { statusOptions } from '../../utils/data'
import { getChannelList, createChannel, updateChannel, clearDefaultChannelData, getAutocompleteUserList, setToast, createLogSnag } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { handleFilterValuesFromData } from '../../utils'
import constants from '../../constants'
import { Input, Button, Modal, Select, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { ModalLoader } from '../../components/common/PageLoader'
import ChannelInfo from '../../components/channels/channelInfo'
import useDebounce from '../../hooks/useDebounce'
import type { RootState } from '../../redux'

interface ModalProps {
  visibleModal: boolean
  isView: boolean
  closeModal: any
  currentPage: number
}

const CreateChannelModal: React.FC<ModalProps> = ({ visibleModal, isView, closeModal, currentPage }) => {
  const [channelName, setChannelName] = useState<string>('')
  const [ownerUserId, setOwnerUserId] = useState<any>('')
  const [status, setStatus] = useState<any>(statusOptions[0])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500)

  const [channelNameErr, setChannelNameErr] = useState<boolean>(false)
  const [userNameErr, setUserNameErr] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)
  const { isLoading, channelActionLoading, defaultChannel, success } = useAppSelector((state: RootState) => state.channel)
  const { userAutoCompleteLoading, filteredUserList } = useAppSelector((state: RootState) => state.user)

  useEffect(() => {
    setChannelNameErr(false)
    setUserNameErr(false)
  }, [visibleModal])

  useEffect(() => {
    handleSetChannelValues()
  }, [defaultChannel])

  useEffect(() => {
    if (success.type === 'Success') {
      dispatch(getChannelList({ currentPage, size: constants.PAGE_SIZE }))
      closeModal()
    }
  }, [success])

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(getAutocompleteUserList(debouncedSearchTerm))
    }
  }, [debouncedSearchTerm])

  const handleSetChannelValues = () => {
    const data = defaultChannel
    if (Object.keys(defaultChannel).length) {
      setChannelName(data.name)
      setOwnerUserId({
        label: `${data.channelOwnerFirstName} ${data.channelOwnerLastName || ''}`,
        value: data.ownerUserId
      })
      setStatus(handleFilterValuesFromData(data.status, statusOptions))
    } else {
      setChannelName('')
      setOwnerUserId('')
      setStatus(statusOptions[0])
    }
  }

  const handleCreateChannel = async () => {
    setChannelNameErr(channelName?.length < 4)
    setUserNameErr(!ownerUserId)

    if (channelName?.length > 4 && ownerUserId) {
      const payload: any = {
        name: channelName.trim(),
        ownerUserId: ownerUserId?.value || ownerUserId,
        isActive: true,
        status: status?.value || status
      }
      if (defaultChannel.ID) {
        payload['ID'] = parseInt(defaultChannel.ID, 10)
        const res: any = await dispatch(updateChannel(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.updatedChannelSuccess')
          }))
        }  else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      } else {
        const logSnagPayload = {
          channel: 'channel-activity',
          event: 'New Channel Created',
          description: JSON.stringify(payload)
        }
        const res: any = await dispatch(createChannel(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(createLogSnag(logSnagPayload))
          dispatch(setToast({
            show: true,
            content: t('common.info.createdChannelSuccess')
          }))
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      }
    }
  }

  const handleNavigateToChannels = () => {
    dispatch(clearDefaultChannelData())
    closeModal()
  }

  const getUserOptions = () => {
    return filteredUserList.map((custom: { firstName: string; lastName: string; userId: string }) => ({
      label: `${custom.firstName} ${custom.lastName || ''}`,
      value: custom.userId
    }))
  }

  const modalStyle = {
    body: {
      padding: '10px 0 20px 0'
    }
  }

  return (
    <Modal
      open={visibleModal}
      title={`${isView ? t('common.view') : defaultChannel.ID ? t('common.edit') : t('common.create')} ${t('createChannel.channel')}`}
      onOk={handleCreateChannel}
      onCancel={handleNavigateToChannels}
      maskClosable={false}
      style={{ top: isMobile ? 20 : undefined }}
      styles={modalStyle}
      width={500}
      footer={
        isView
          ? [
              <Button size={elementSize} key="back" onClick={handleNavigateToChannels}>
                {isView ? t('common.close') : t('common.cancel')}
              </Button>
            ]
          : [
              <Button size={elementSize} key="back" disabled={channelActionLoading} onClick={handleNavigateToChannels}>
                {isView ? t('common.close') : t('common.cancel')}
              </Button>,
              <Button size={elementSize} key="submit" type="primary" loading={channelActionLoading} onClick={handleCreateChannel}>
                {defaultChannel.ID ? t('common.update') : t('common.create')}
              </Button>
            ]
      }
    >
      {isLoading || channelActionLoading ? (
        <ModalLoader />
      ) : (
        <div className="modal-split">
          <div className="w-100">
            <div className="input-container">
              <label className="input-label">
                {t('createChannel.channelName')}
                <span className="input-mandatory">*</span>
              </label>
              <Input
                allowClear={true}
                status={channelNameErr ? 'error' : ''}
                readOnly={isView}
                value={channelName}
                onBlur={() => setChannelNameErr(channelName.length < 4)}
                onChange={(e) => {
                  setChannelName(e.target.value)
                  setChannelNameErr(false)
                }}
                placeholder={t('createChannel.placeholder.channelName')}
              />
              {channelNameErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChannel.ownerName')}
                <span className="input-mandatory">*</span>
              </label>
              <Select
                allowClear={true}
                showSearch
                value={ownerUserId || null}
                placeholder={t('createChannel.placeholder.ownerName')}
                filterOption={false}
                onSearch={e => setSearchTerm(e)}
                disabled={isView}
                onChange={(option: any) => setOwnerUserId(option)}
                notFoundContent={userAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getUserOptions()}
                className="select-width"
              />
              {userNameErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChannel.status')}
                <span className="input-mandatory">*</span>
              </label>
              <Select value={status} defaultValue={status} disabled={isView} options={statusOptions} onChange={(option) => setStatus(option)} />
            </div>
            {isView && <ChannelInfo channelInfo={defaultChannel} />}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CreateChannelModal
