// Access Control List (ACL)

// Dashboard
export const CHIT_DASHBOARD_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']

// Chit group
export const CHIT_CHIT_GROUP_LIST_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
export const CHIT_CHIT_GROUP_DETAIL_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
export const CHIT_CHIT_GROUP_CREATE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CHIT_GROUP_UPDATE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CHIT_GROUP_DELETE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']

// Customers
export const CHIT_CUSTOMER_LIST_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
export const CHIT_CUSTOMER_DETAIL_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
export const CHIT_CUSTOMER_CRETAE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CUSTOMER_UPDATE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CUSTOMER_DELETE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']

// Chit group transactions
export const CHIT_CHIT_TRANSACTION_LIST_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
export const CHIT_CHIT_TRANSACTION_UPDATE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CHIT_TRANSACTION_DELETE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']

// Customer transactions
export const CHIT_CUSTOMER_TRANSACTION_LIST_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
export const CHIT_CUSTOMER_TRANSACTION_UPDATE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CUSTOMER_TRANSACTION_DELETE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']

// Reports
export const CHIT_CHIT_REPORT_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CHIT_REPORT_DOWNLOAD = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']
export const CHIT_CUSTOMER_REPORT_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']
export const CHIT_CUSTOMER_REPORT_DOWNLOAD = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']

// Analytics
export const CHIT_ANALYTICS_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']

// Notification
export const CHIT_NOTIFICATION_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']

// Support
export const CHIT_SUPPORT_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN']

// Users
export const CHIT_USER_LIST_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']
export const CHIT_USER_DETAIL_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']
export const CHIT_USER_UPDATE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']
export const CHIT_USER_DELETE = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN']

// Channels
export const CHIT_CHANNEL_LIST_VIEW = ['SYSTEM_ADMIN']
export const CHIT_CHANNEL_DETAIL_VIEW = ['SYSTEM_ADMIN']
export const CHIT_CHANNEL_UPDATE = ['SYSTEM_ADMIN']
export const CHIT_CHANNEL_DELETE = ['SYSTEM_ADMIN']

// Settings
export const CHIT_SETTINGS_VIEW = ['SYSTEM_ADMIN', 'CHANNEL_ADMIN', 'SUPERADMIN', 'ADMIN', 'USER']
