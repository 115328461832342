import { useTranslation } from 'react-i18next'
import { Space, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { currencyFormatter, getDate } from '../../../utils'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'

interface ChitGroupTransactionTableDesktopProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

interface DataType {
  collectionTransactionId: number
  settlementTransactionId: number
  chitName: string
  installmentNumber: number
  transactionDate: Date
  discountAmount: number
  dividendAmount: number
  settlementAmount: number
  settlementDueDate: Date
  customerPayableAmount: number
  customerDueDate: Date
  triedUserCount: number
}

const ChitGroupTransactionTableDesktop: React.FC<ChitGroupTransactionTableDesktopProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, chitTransactionActionLoading, pageMetaData, chitGroupTransactionList } = useAppSelector((state: RootState) => state.chitGroupTransaction)

  const columns: ColumnsType<DataType> = [
    {
      title: t('transactions.chitGroup.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.chitName'),
      dataIndex: 'chitName',
      key: 'chitName'
    },
    {
      title: t('transactions.chitGroup.tableHeaders.monthlyInstallment'),
      dataIndex: 'installmentNumber',
      key: 'installmentNumber'
    },
    {
      title: t('transactions.chitGroup.tableHeaders.transactionDate'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.settlementAmount'),
      dataIndex: 'settlementAmount',
      key: 'settlementAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.discount'),
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.settlementDate'),
      dataIndex: 'settlementDueDate',
      key: 'settlementDueDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.customerPayableAmount'),
      dataIndex: 'customerPayableAmount',
      key: 'customerPayableAmount',
      render: (customerPayableAmount) => <div>{currencyFormatter(customerPayableAmount)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.dividend'),
      dataIndex: 'dividendAmount',
      key: 'dividendAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.customerDueDate'),
      dataIndex: 'customerDueDate',
      key: 'customerDueDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.triedUserCount'),
      dataIndex: 'triedUserCount',
      key: 'triedUserCount',
      render: (value) => <div>{value || '-'}</div>
    },
    {
      title: t('transactions.chitGroup.tableHeaders.action'),
      key: 'action',
      render: (_, record) => (
        <Space>
          {!isUser && <MdOutlineBorderColor onClick={() => handleEditClick(record)} className="c-pointer" />}
          {(isSuperAdmin || isSystemAdmin) && (
            <MdOutlineDelete
              className="c-pointer"
              onClick={() => handleDeleteClick(`${record.collectionTransactionId},${record.settlementTransactionId}`, `${record.chitName} - ${record.installmentNumber}`)}
            />
          )}
        </Space>
      )
    }
  ]

  return (
    <>
      <Table
        size="middle"
        rowKey={(record: { collectionTransactionId: number }) => record.collectionTransactionId}
        loading={isLoading || chitTransactionActionLoading}
        columns={columns as any}
        dataSource={chitGroupTransactionList}
        pagination={false}
        scroll={{ x: 1300 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewClick(record)
          }
        }}
      />
    </>
  )
}

export default ChitGroupTransactionTableDesktop
