export const langOptions = [
  { key: 'ta', label: 'தமிழ்' },
  { key: 'en', label: 'English' }
]

export const statusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'IN_ACTIVE', label: 'In Active' }
]

export const roleOptions = [
  { value: 'SUPERADMIN', label: 'Super Admin' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'USER', label: 'User' }
]

export const loginDisabledOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

export const chitPriorityOptions = [
  { value: 'HIGH', label: 'High' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'LOW', label: 'Low' }
]

export const penaltyOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

export const chitStatusOptions = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'ENDED', label: 'Ended' }
]

export const paymentMethodOptions = [
  { value: 'CASH', label: 'Cash' },
  { value: 'INTERNET_BANKING', label: 'Internet Banking' },
  { value: 'GPAY', label: 'Gpay' },
  { value: 'PHONEPE', label: 'Phonepe' },
  { value: 'LESS', label: 'Less' }
]

export const paymentPaidStatusOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'PAID', label: 'Paid' },
  { value: 'UNPAID', label: 'Un Paid' }
]

export const notificationTypeOptions = [
  { value: 'ALL', label: 'All' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'WHATSAPP', label: 'Whatsapp' }
]

export const notificationTemplateOptions = [
  { value: 'CHIT_MONTH_REMINDER', label: 'Chit Month Reminder' },
  { value: 'CHIT_DAY_REMINDER', label: 'Chit Day Reminder' },
  { value: 'CHIT_PARTIAL_DETAILS', label: 'Chit Partial Details' },
  { value: 'CHIT_FULL_DETAILS', label: 'Chit Full Details' },
  { value: 'CHIT_PARTIAL_AMOUNT_RECEIVED', label: 'Chit Partial Amount Received' },
  { value: 'CHIT_AMOUNT_RECEIVED', label: 'Chit Amount Received' },
  { value: 'CHIT_AMOUNT_SENT', label: 'Chit Amount Sent' },
  { value: 'PAYMENT_REMINDER', label: 'Payment Reminder' }
]

export const notificationDayOptions = [
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
  { value: 'SUNDAY', label: 'Sunday' }
]

export const dateOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
  { value: 29, label: 29 },
  { value: 30, label: 30 },
  { value: 31, label: 31 }
]
