import { Column } from '@ant-design/plots'
import { each, groupBy } from '@antv/util'

const CollectionDetailsByCount = ({ t, data }) => {
  const annotations = []
  each(groupBy(data, 'label'), (values, k) => {
    const value = values.reduce((a, b) => a + b.value, 0)
    annotations.push({
      type: 'text',
      position: [k, value],
      content: `${value}`,
      style: {
        textAlign: 'center',
        fontSize: 14,
        fill: 'rgba(0,0,0,0.85)'
      },
      offsetY: 15
    })
  })

  const config = {
    data,
    isStack: true,
    xField: 'label',
    yField: 'value',
    seriesField: 'type',
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    },
    legend: {
      position: 'bottom-left'
    },
    minColumnWidth: 5,
    maxColumnWidth: 40,
    annotations
  }

  return (
    <div className="w-100">
      <p className="text-center">{t('analytics.collectionDetailCountTitle')}</p>
      <Column {...config} />
    </div>
  )
}

export default CollectionDetailsByCount
