import { configureStore } from '@reduxjs/toolkit'
import { authReducer, setToken, setUserData } from './slices/authSlice'
import { channelReducer, clearDefaultChannelData } from './slices/channel'
import { userReducer, clearDefaultUserData, clearChannelSetSuccessData } from './slices/userSlice'
import { chitGroupReducer, clearDefaultChitGroupData, clearAutoCompleteChitGroupData, clearAutoCompleteCustomerData, setRemovedCustomers } from './slices/chitGroupSlice'
import { customerReducer, clearDefaultCustomerData } from './slices/customerSlice'
import { commonReducer, setViewFullMenu, setIsMobile, setEnableFilter, setToast, setElementSize } from './slices/commonSlice'
import { chitGroupTransactionReducer, clearDefaultChitGroupTransactionData } from './slices/chitGroupTransaction'
import { customerTransactionReducer, clearSuccessErrorData, clearDefaultCustomerTransactionData } from './slices/customerTransaction'
import { clearAnalyticsData, analyticsReducer } from './slices/analytics'
import { reportReducer, clearReportData, clearDownloadReportData, updateChitReportPageMetaData, updateCustomerReportPageMetaData } from './slices/reportSlice'
import { publicCustomerReducer } from './slices/publicCustomer'
import { userConfigReducer, updateOnboardingTourData } from './slices/userConfig'
import { userLogout } from './actions'

import { AuthState, ChannelState, UserState, ChitGroupState, CustomerState, CommonState, ChitGroupTransactionState, CustomerTransactionState, AnalyticsState,
  ReportState, PublicCustomerState, UserConfigState } from '../types/redux'

const Store = configureStore({
  reducer: {
    auth: authReducer,
    channel: channelReducer,
    user: userReducer,
    chitGroup: chitGroupReducer,
    customer: customerReducer,
    common: commonReducer,
    chitGroupTransaction: chitGroupTransactionReducer,
    customerTransaction: customerTransactionReducer,
    analytics: analyticsReducer,
    report: reportReducer,
    publicCustomer: publicCustomerReducer,
    userConfig: userConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true,
      },
    }),
  devTools: process.env.NODE_ENV !== 'production'
})

export {
  Store,
  setToken,
  setUserData,
  userLogout,
  setViewFullMenu,
  setIsMobile,
  setEnableFilter,
  setElementSize,
  setToast,
  clearDefaultChitGroupData,
  clearAutoCompleteChitGroupData,
  clearAutoCompleteCustomerData,
  setRemovedCustomers,
  clearDefaultCustomerData,
  clearDefaultChitGroupTransactionData,
  clearSuccessErrorData,
  clearDefaultCustomerTransactionData,
  clearDefaultChannelData,
  clearDefaultUserData,
  clearChannelSetSuccessData,
  clearReportData,
  clearDownloadReportData,
  updateChitReportPageMetaData,
  updateCustomerReportPageMetaData,
  clearAnalyticsData,
  updateOnboardingTourData,
}

export type RootState = {
  auth: AuthState,
  channel: ChannelState,
  user: UserState,
  chitGroup: ChitGroupState,
  customer: CustomerState,
  common: CommonState,
  chitGroupTransaction: ChitGroupTransactionState,
  customerTransaction: CustomerTransactionState,
  analytics: AnalyticsState,
  report: ReportState,
  publicCustomer: PublicCustomerState,
  userConfig: UserConfigState,
}
export type AppDispatch = typeof Store.dispatch

export * from './thunks/authThunk'
export * from './thunks/channel'
export * from './thunks/userThunk'
export * from './thunks/chitGroupThunk'
export * from './thunks/customerThunk'
export * from './thunks/chitGroupTransaction'
export * from './thunks/customerTransaction'
export * from './thunks/commonThunk'
export * from './thunks/analytics'
export * from './thunks/report'
export * from './thunks/publicCustomer'
export * from './thunks/userConfig'
