const PROD = 'https://chit-backend-prod.onrender.com'
const UAT = 'https://chit-backend-qa.onrender.com'

const baseURL = process.env.NODE_ENV === 'development' ? PROD : process.env.REACT_APP_API_URL

const config = {
  api: {
    // User
    auth: `${baseURL}/api/chitx/v1/user/login`,
    refreshToken: `${baseURL}/api/chitx/v1/user/refresh-token`,
    forgotPassword: (email: string) => `${baseURL}/api/chitx/v1/user/forgot-password?email=${email}`,
    resetPassword: (resetString: string, password: string) => `${baseURL}/api/chitx/v1/user/reset-password?resetString=${resetString}&password=${password}`,
    // Dashboard
    getDashboardStatusCount: `${baseURL}/api/chitx/v1/dashboard`,
    getDashboardPaymentGraph: (chitGroupId: string) => `${baseURL}/api/chitx/v1/dashboard/transactions/paymentgraph?chitgroupId=${chitGroupId}`,
    // Channel
    getChannelList: (currentPage: number, size: number) => `${baseURL}/api/chitx/v1/channel?currentPage=${currentPage}&size=${size}`,
    getAutocompleteChannelList: (keyword: string) => `${baseURL}/api/chitx/v1/channel/autocomplete?name=${keyword}`,
    getDefaultChannel: (channelId: string) => `${baseURL}/api/chitx/v1/channel/${channelId}`,
    createChannel: `${baseURL}/api/chitx/v1/channel`,
    updateChannel: (channelId: string) => `${baseURL}/api/chitx/v1/channel/${channelId}`,
    // Users
    getUserList: (currentPage: number, size: number) => `${baseURL}/api/chitx/v1/user?currentPage=${currentPage}&size=${size}`,
    getAutocompleteUserList: (keyword: string) => `${baseURL}/api/chitx/v1/user/autocomplete?name=${keyword}`,
    getDefaultUser: (userId: string) => `${baseURL}/api/chitx/v1/user/${userId}`,
    createUser: `${baseURL}/api/chitx/v1/user`,
    updateUser: (userId: string) => `${baseURL}/api/chitx/v1/user/${userId}`,
    deleteUser: (userId: string) => `${baseURL}/api/chitx/v1/user/${userId}`,
    setUserChanneId: (userId: number, channelId: number) => `${baseURL}/api/chitx/v1/user/${userId}/channel?channelId=${channelId}`,
    // Chit group
    getAutocompleteChitGroupList: (keyword: string) => `${baseURL}/api/chitx/v1/chitgroup/autocomplete?${keyword}`,
    getChitGroupList: (currentPage: number, size: number, name: string) => `${baseURL}/api/chitx/v1/chitgroup/browse?currentPage=${currentPage}&size=${size}&name=${name}`,
    getChitGroup: (chitGroupId: string) => `${baseURL}/api/chitx/v1/chitgroup/${chitGroupId}`,
    createChitGroup: `${baseURL}/api/chitx/v1/chitgroup`,
    updateChitGroup: (chitGroupId: string) => `${baseURL}/api/chitx/v1/chitgroup/${chitGroupId}`,
    deleteChitGroup: (chitGroupId: string) => `${baseURL}/api/chitx/v1/chitgroup/${chitGroupId}`,
    getChitTakenList: (chitGroupId: string, currentPage: number, size: number) =>
      `${baseURL}/api/chitx/v1/chitgroup/settled-customers?id=${chitGroupId}&currentPage=${currentPage}&size=${size}`,
    getChitGroupOverview: (chitGroupId: string) => `${baseURL}/api/chitx/v1/chitgroup/overview?id=${chitGroupId}`,
    getChitGroupTimeline: (chitGroupId: string) => `${baseURL}/api/chitx/v1/chitgroup/timeline?id=${chitGroupId}`,
    getAutocompleteChitGroupCustomerList: (chitGroupId: string, keyword: string) => `${baseURL}/api/chitx/v1/chitgroup/customer-autocomplete?id=${chitGroupId}&name=${keyword}`,
    // Customer
    getFilteredCustomerList: (keyword: string) => `${baseURL}/api/chitx/v1/customer?filters=${keyword}`,
    getAutocompleteCustomerList: (keyword: string) => `${baseURL}/api/chitx/v1/customer/autocomplete?name=${keyword}`,
    getCustomerList: (currentPage: number, size: number, name: string) => `${baseURL}/api/chitx/v1/customer/browse?currentPage=${currentPage}&size=${size}&name=${name}`,
    getCustomer: (customerId: string) => `${baseURL}/api/chitx/v1/customer/${customerId}`,
    createCustomer: `${baseURL}/api/chitx/v1/customer`,
    updateCustomer: (customerId: string) => `${baseURL}/api/chitx/v1/customer/${customerId}`,
    deleteCustomer: (customerId: string) => `${baseURL}/api/chitx/v1/customer/${customerId}`,
    // Chit group transactions
    getAllChitGroupTransactionList: (currentPage: number, size: number, filters: string) =>
      `${baseURL}/api/chitx/v1/chitgroup/transaction/allpair?currentPage=${currentPage}&size=${size}&filters=${filters}`,
    getChitGroupTransactionList: (chitgroupId: BigInteger) => `${baseURL}/api/chitx/v1/chitgroup/transactions?skipPagination=true&chitgroupId=${chitgroupId}`,
    getDefaulChitGroupTransaction: (transactionId: string) => `${baseURL}/api/chitx/v1/chitgroup/transaction?idsInCSV=${transactionId}`,
    createChitGroupTransaction: `${baseURL}/api/chitx/v1/chitgroup/transaction/pair`,
    updateChitGroupTransaction: `${baseURL}/api/chitx/v1/chitgroup/transaction`,
    deleteChitGroupTransaction: (transactionId: string) => `${baseURL}/api/chitx/v1/chitgroup/transaction?idsInCSV=${transactionId}`,
    // Customer transactions
    getAllCustomerTransactionList: (currentPage: number, size: number, filters: string) =>
      `${baseURL}/api/chitx/v1/chitgroup/transaction/detail?currentPage=${currentPage}&size=${size}&filters=${filters}`,
    getCustomerTransactionList: (chitgroupId: BigInteger) => `${baseURL}/api/chitx/v1/chitgroup/transaction/browse/${chitgroupId}`,
    getDefaulCustomerTransaction: (transactionId: string) => `${baseURL}/api/chitx/v1/chitgroup/transaction/detail/${transactionId}`,
    createCustomerTransaction: `${baseURL}/api/chitx/v1/chitgroup/transaction/detail`,
    updateCustomerTransaction: (transactionId: string) => `${baseURL}/api/chitx/v1/chitgroup/transaction/detail/${transactionId}`,
    deleteCustomerTransaction: (transactionId: string) => `${baseURL}/api/chitx/v1/chitgroup/transaction/detail/${transactionId}`,
    // Analytics
    getAnalytics: `${baseURL}/api/chitx/v1/analytics`,
    // Reports
    getChitReport: (chitgroupID: number, filters: string, currentPage: number, size: number) =>
      `${baseURL}/api/chitx/v1/report/chitgroup/${chitgroupID}?${filters}&currentPage=${currentPage}&size=${size}`,
    getCustomerReport: (customerID: number, filters: number, currentPage: number, size: number) =>
      `${baseURL}/api/chitx/v1/report/customer/${customerID}?${filters}&currentPage=${currentPage}&size=${size}`,
    downloadReport: (reportName: string, filters: string) => `${baseURL}/api/chitx/v1/report/download?reportName=${reportName}&${filters}`,
    // User config
    getUserConfig: (userId: string) => `${baseURL}/api/chitx/v1/user/config?userId=${userId}`,
    createUserConfig: `${baseURL}/api/chitx/v1/user/config`,
    updateUserConfig: (userConfigId: string) => `${baseURL}/api/chitx/v1/user/config/${userConfigId}`,
    // Public customer
    getPublicCustomerInfo: (token: string) => `${baseURL}/api/chitx/v1/customer/info?token=${token}`,
    getPublicCustomerChitTransactions: (filters: string) => `${baseURL}/api/chitx/v1/customer/transaction-details?skipPagination=true&filters=${filters}`
  },
  pages: {
    landingPage: `/`,
    enquiry: `/enquiry`,
    login: `/login`,
    forgotPassword: `/forgot-password`,
    resetPassword: `/reset-password`,
    dashboard: `/dashboard`,
    chitGroup: `/chitGroup`,
    create_chitGroup: `/chitGroup/create`,
    edit_chitGroup: `/chitGroup/edit`,
    view_chitGroup: `/chitGroup/view`,
    customer: `/customer`,
    create_customer: `/customer/create`,
    edit_customer: `/customer/edit`,
    view_customer: `/customer/view`,
    analytics: `/analytics`,
    chitReports: `/reports/chit`,
    customerReports: `/reports/customer`,
    user: `/users`,
    channels: `/channels`,
    notification: `/notification`,
    chitGroupTransaction: '/transaction/chitGroup',
    customerTransaction: '/transaction/customer',
    view_transaction: '/transaction/:transactionId',
    support: `/support`,
    settings: `/settings`,
    publicCustomer: `/customer/:customerId`,
    unAuthorized: `/un-authorized`
  },
  token: {
    LOG_SNAG: '154d418449995568803d544838a84b30'
  }
}

export default config
