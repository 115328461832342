import { useTranslation } from 'react-i18next'
import { Space, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { currencyFormatter, handleFilterValuesFromData } from '../../../utils'
import { paymentMethodOptions } from '../../../utils/data'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'

interface CustomerTransactionTableDesktopProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

interface DataType {
  id: number
  firstName: string
  lastName: string
  chitName: string
  installmentNumber: number
  isPaymentToCustomer: boolean
  isPartialPayment: boolean
  amount: number
  type: boolean
  balanceAmount: number
  transactionDate: Date
  pendingMonths: number
  paymentMethod: Date
}

const CustomerTransactionTableDesktop: React.FC<CustomerTransactionTableDesktopProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, customerTransactionActionLoading, customerTransactionList, pageMetaData } = useAppSelector((state: RootState) => state.customerTransaction)

  const columns: ColumnsType<DataType> = [
    {
      title: t('transactions.customers.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('transactions.customers.tableHeaders.customerName'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (firstName, record) => (
        <>
          {firstName} {record.lastName || ''}
        </>
      )
    },
    {
      title: t('transactions.customers.tableHeaders.chitName'),
      dataIndex: 'chitName',
      key: 'chitName'
    },
    {
      title: t('transactions.customers.tableHeaders.monthlyInstallment'),
      dataIndex: 'installmentNumber',
      key: 'installmentNumber'
    },
    {
      title: t('transactions.customers.tableHeaders.type'),
      dataIndex: 'isPaymentToCustomer',
      key: 'isPaymentToCustomer',
      render: (value) => <Tag color={value ? 'blue' : 'green'}>{value ? t('common.debited') : t('common.credited')}</Tag>
    },
    {
      title: t('transactions.customers.tableHeaders.partialPayment'),
      dataIndex: 'isPartialPayment',
      key: 'isPartialPayment',
      render: (isPartialPayment) => <Tag color={isPartialPayment ? 'green' : ''}>{isPartialPayment ? t('common.yes') : t('common.no')}</Tag>
    },
    {
      title: t('transactions.customers.tableHeaders.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.balanceAmount'),
      dataIndex: 'balanceAmount',
      key: 'balanceAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.payableAmount'),
      dataIndex: 'payableAmount',
      key: 'payableAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.transactionDate'),
      dataIndex: 'transactionDate',
      key: 'transactionDate'
    },
    {
      title: t('transactions.customers.tableHeaders.paymentMethod'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (value) => {
        const paymentMethodValue = handleFilterValuesFromData(value, paymentMethodOptions)
        return <div>{paymentMethodValue ? paymentMethodValue.label : '-'}</div>
      }
    },
    {
      title: t('transactions.customers.tableHeaders.action'),
      key: 'action',
      render: (_, record) => {
        return (
          <Space>
            {!isUser && <MdOutlineBorderColor onClick={() => handleEditClick(record)} className="c-pointer" />}
            {(isSuperAdmin || isSystemAdmin) && (
              <MdOutlineDelete
                onClick={() => handleDeleteClick(record.id, `${record.firstName} ${record.lastName || ''} - ${record.chitName} - ${record.installmentNumber}`)}
                className="c-pointer"
              />
            )}
          </Space>
        )
      }
    }
  ]

  return (
    <>
      <Table
        size="middle"
        rowKey={(record: { id: number }) => record.id}
        loading={customerTransactionActionLoading || isLoading}
        columns={columns as any}
        dataSource={customerTransactionList}
        pagination={false}
        scroll={{ x: 1300 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewClick(record)
          }
        }}
      />
    </>
  )
}

export default CustomerTransactionTableDesktop
