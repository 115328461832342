import { useEffect } from 'react'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import config from '../../config'
import { setIsMobile } from '../../redux'
import { useAppDispatch } from '../../redux/hooks'
import LandingPageHeader from '../../components/landingPage/Header'
import HeroSection from '../../components/landingPage/HeroSection'
import FeaturesOverview from '../../components/landingPage/FeaturesOverview'
// import Features from '../../components/landingPage/Features'
import Contact from '../../components/landingPage/Contact'

const LandingPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()

  useEffect(() => {
    dispatch(setIsMobile(window.innerWidth <= 798))
    const token: string | null = window.localStorage.getItem('jwt')
    if (token) {
      navigate(config.pages.dashboard)
    }
  }, [])

  return (
    <div>
      <LandingPageHeader />
      <HeroSection />
      <FeaturesOverview />
      {/* <Features /> */}
      <div id='testimonial'>
        <iframe
          height="800px"
          id="testimonialto-landing-page5-tag-all-light-animated"
          src="https://embed-v2.testimonial.to/w/landing-page5?animated=on&theme=light&shadowColor=ffffff&speed=1&tag=all"
          frameBorder="0"
          scrolling="no"
          width="100%"
        />
      </div>
      <Contact />
    </div>
  )
}

export default LandingPage
