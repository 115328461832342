import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getCustomerList, getFilteredCustomerList, getAutocompleteCustomerList, getDefaultCustomer, createCustomer, updateCustomer, deleteCustomer } from '../thunks/customerThunk'
import { userLogout } from '../actions'
import { CustomerState } from '../../types/redux'

const initialState: CustomerState = {
  isLoading: false,
  customerActionLoading: false,
  customerAutoCompleteLoading: false,
  isFilteredLoading: false,
  customerList: [],
  filteredCustomerList: [],
  pageMetaData: {},
  defaultCustomer: {},
  success: {},
  error: {}
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    clearDefaultCustomerData: (state) => {
      state.defaultCustomer = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCustomerList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.customerList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getCustomerList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getFilteredCustomerList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFilteredCustomerList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.filteredCustomerList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getFilteredCustomerList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getAutocompleteCustomerList.pending, (state) => {
      state.customerAutoCompleteLoading = true
    })
    builder.addCase(getAutocompleteCustomerList.fulfilled, (state, { payload }) => {
      state.customerAutoCompleteLoading = false
      state.success = {}
      state.filteredCustomerList = payload.data.data
    })
    builder.addCase(getAutocompleteCustomerList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerAutoCompleteLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDefaultCustomer.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDefaultCustomer.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.defaultCustomer = payload.data.data
      state.success = {}
    })
    builder.addCase(getDefaultCustomer.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createCustomer.pending, (state) => {
      state.customerActionLoading = true
    })
    builder.addCase(createCustomer.fulfilled, (state, { payload }) => {
      state.customerActionLoading = false
      state.success = payload.data
    })
    builder.addCase(createCustomer.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateCustomer.pending, (state) => {
      state.customerActionLoading = true
    })
    builder.addCase(updateCustomer.fulfilled, (state, { payload }) => {
      state.customerActionLoading = false
      state.success = payload.data
    })
    builder.addCase(updateCustomer.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(deleteCustomer.pending, (state) => {
      state.customerActionLoading = true
    })
    builder.addCase(deleteCustomer.fulfilled, (state, { payload }) => {
      state.customerActionLoading = false
      state.success = payload.data
    })
    builder.addCase(deleteCustomer.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        customerAutoCompleteLoading: false,
        customerActionLoading: false,
        isFilteredLoading: false,
        customerList: [],
        filteredCustomerList: [],
        pageMetaData: {},
        defaultCustomer: {},
        success: {},
        error: {}
      }
    })
  }
})

export const { clearDefaultCustomerData } = customerSlice.actions

export const customerReducer = customerSlice.reducer
