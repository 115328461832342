import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'axios'
import config from '../../config'

const headers = {
  Authorization: `Bearer ${config.token.LOG_SNAG}`,
  'Content-Type': 'application/json'
}

const defaultVaule = {
  project: 'chit-fund',
  icon: '🔥',
  notify: true
}

export const createLogSnag = createAsyncThunk('CREATE_LOG_SNAG', async (payload: any, { rejectWithValue }) => {
  try {
    if (process.env.REACT_APP_ENABLE_LOG_SNAG === 'TRUE') {
      const response = await Axios.post('https://api.logsnag.com/v1/log', JSON.stringify({ ...payload, ...defaultVaule }), { headers }).then((res) => res)
      return response
    }
  } catch (err) {
    return rejectWithValue(err)
  }
})
