import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import englishTranslation from './translation/en.json'
import tamilTranslation from './translation/ta.json'
import { getLocale } from '../utils'

export const resources = {
  en: {
    translation: englishTranslation
  },
  ta: {
    translation: tamilTranslation
  }
}

i18next.use(initReactI18next).init({
  lng: getLocale(),
  resources,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
})

export default i18next
