import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Select, DatePicker, Spin, InputNumber, Button } from 'antd'
import { notificationTypeOptions, notificationTemplateOptions, notificationDayOptions } from '../../utils/data'
import { allowOnlyNumbers, getBEFormat, disabledBeforeTodayDate, disabledAfterTodayDate } from '../../utils'
import { getAutocompleteCustomerList, getAutocompleteChitGroupList } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import constants from '../../constants'
import useDebounce from '../../hooks/useDebounce'
import type { RootState } from '../../redux'

const { paymentRequiredTemplates, partialPaymentRequiredTemplates, chitDayRequiredTemplates, length } = constants

const Notification: React.FC = () => {
  const [template, setTemplate] = useState<string>('')
  const [notificationType, setNotificationType] = useState<string>('')
  const [chitGroup, setChitGroup] = useState<string>('')
  const [installment, setInstallment] = useState<string>('')
  const [customer, setCustomer] = useState<string>('')
  const [date, setDate] = useState<any>('')
  const [day, setDay] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const [balanceAmount, setBalanceAmount] = useState<string>('')
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false)
  const [isPaymentRequiredTemplate, setIisPaymentRequiredTemplate] = useState<boolean>(false)
  const [isPartialPaymentRequiredTemplate, setIsPartialPaymentRequiredTemplate] = useState<boolean>(false)
  const [isDayRequiredTemplate, setIisDayRequiredTemplate] = useState<boolean>(false)
  const [customerSearchTerm, setCustomerSearchTerm] = useState<string>('')
  const debouncedCustomerSearchTerm = useDebounce<string>(customerSearchTerm, 500)
  const [chitGroupSearchTerm, setChitGroupSearchTerm] = useState<string>('')
  const debouncedChitGroupSearchTerm = useDebounce<string>(chitGroupSearchTerm, 500)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)
  const { customerAutoCompleteLoading, filteredCustomerList } = useAppSelector((state: RootState) => state.customer)
  const { chitAutoCompleteGroupLoading, filteredChitGroupList } = useAppSelector((state: RootState) => state.chitGroup)

  useEffect(() => {
    setIisPaymentRequiredTemplate(paymentRequiredTemplates.includes(template))
    setIsPartialPaymentRequiredTemplate(partialPaymentRequiredTemplates.includes(template))
    setIisDayRequiredTemplate(chitDayRequiredTemplates.includes(template))
  }, [template])

  useEffect(() => {
    if (debouncedCustomerSearchTerm) {
      dispatch(getAutocompleteCustomerList(debouncedCustomerSearchTerm))
    }
  }, [debouncedCustomerSearchTerm])

  useEffect(() => {
    if (debouncedChitGroupSearchTerm) {
      dispatch(getAutocompleteChitGroupList(`name=${debouncedChitGroupSearchTerm}&filters=status eq 'ACTIVE'`))
    }
  }, [debouncedChitGroupSearchTerm])

  const getDueDropDownValues = () => {
    if (chitGroup) {
      const getMonths: any = filteredChitGroupList.find(({ ID }: any) => ID === chitGroup)
      if (getMonths) {
        const lastCompletedInstallment: number = getMonths.maxInstallmentNumber ? getMonths.maxInstallmentNumber + 1 : 1
        return [{ value: lastCompletedInstallment, label: lastCompletedInstallment }]
      }
    }
  }

  const getCustomerOptions = () => {
    return filteredCustomerList.map((custom: { firstName: string; lastName: string; customerId: string }) => ({
      label: `${custom.firstName} ${custom.lastName || ''}`,
      value: custom.customerId
    }))
  }

  const getChitGroupOptions = () => {
    return filteredChitGroupList.map((custom: { chitName: string; ID: string }) => ({ label: custom.chitName, value: custom.ID }))
  }

  const handleChitAmount = (key: string, value: any) => {
    if (allowOnlyNumbers(value)) {
      key === constants.keys.AMOUNT ? setAmount(value) : setBalanceAmount(value)
    }
  }

  const handleClearAllData = () => {
    setTemplate('')
    setNotificationType('')
    setChitGroup('')
    setInstallment('')
    setCustomer('')
    setDate('')
    setDay('')
    setAmount('')
    setBalanceAmount('')
    setShowErrMsg(false)
  }

  const checkDisableSubmit = () => {
    let defaultRes: boolean = template === '' || notificationType === '' || customer === '' || chitGroup === '' || installment === '' || !date
    if (isDayRequiredTemplate) {
      defaultRes = day === ''
    }
    if (isPaymentRequiredTemplate) {
      defaultRes = amount === ''
    }
    if (isPartialPaymentRequiredTemplate) {
      defaultRes = balanceAmount === ''
    }
    return defaultRes
  }

  const handleCreateTransaction = () => {
    if (checkDisableSubmit()) {
      setShowErrMsg(true)
    } else {
      const payload = {
        template,
        notificationType,
        chitGroup,
        installment,
        customer,
        date: getBEFormat(date),
        day,
        amount,
        balanceAmount
      }
      console.log('Submit', payload)
    }
  }

  return (
    <div style={{ padding: '40px 20px 20px 20px' }}>
      <Space wrap size={isMobile ? 'small' : 'middle'}>
        <div className="input-container">
          <label className="input-label">{t('notification.template')}</label>
          <Select
            showSearch
            value={template || null}
            allowClear={true}
            style={{ width: '325px' }}
            onChange={(option: any) => setTemplate(option)}
            options={notificationTemplateOptions}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            placeholder={t('notification.placeholder.template')}
            status={showErrMsg && !template ? 'error' : ''}
          />
          {showErrMsg && !template && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">{t('notification.type')}</label>
          <Select
            showSearch
            value={notificationType || null}
            allowClear={true}
            style={{ width: '325px' }}
            onChange={(option: any) => setNotificationType(option)}
            options={notificationTypeOptions}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            placeholder={t('notification.placeholder.type')}
            status={showErrMsg && !notificationType ? 'error' : ''}
          />
          {showErrMsg && !notificationType && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">{t('createChitGroupTransaction.chitName')}</label>
          <Select
            allowClear={true}
            style={{ width: '325px' }}
            showSearch
            value={chitGroup || null}
            filterOption={false}
            onSearch={e => setChitGroupSearchTerm(e)}
            placeholder={t('createCustomerTransaction.placeholder.chitName')}
            onChange={(option: any) => setChitGroup(option)}
            notFoundContent={chitAutoCompleteGroupLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
            options={getChitGroupOptions()}
            status={showErrMsg && !chitGroup ? 'error' : ''}
          />
          {showErrMsg && !chitGroup && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">{t('createChitGroupTransaction.installment')}</label>
          <Select
            allowClear={true}
            style={{ width: '325px' }}
            value={installment || null}
            options={getDueDropDownValues()}
            onChange={(option) => setInstallment(option)}
            placeholder={t('createChitGroupTransaction.placeholder.installment')}
            status={showErrMsg && !installment ? 'error' : ''}
          />
          {showErrMsg && !installment && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomerTransaction.customerName')}</label>
          <Select
            allowClear={true}
            style={{ width: '325px' }}
            showSearch
            value={customer || null}
            placeholder={t('createCustomerTransaction.placeholder.customerName')}
            filterOption={false}
            onSearch={e => setCustomerSearchTerm(e)}
            onChange={(option: any) => setCustomer(option)}
            notFoundContent={customerAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
            options={getCustomerOptions()}
            status={showErrMsg && !customer ? 'error' : ''}
          />
          {showErrMsg && !customer && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">{t('notification.date')}</label>
          <DatePicker
            style={{ width: '325px' }}
            disabledDate={isDayRequiredTemplate ? disabledBeforeTodayDate : disabledAfterTodayDate}
            allowClear={true}
            value={date}
            placeholder={t('notification.placeholder.date')}
            onChange={(e) => setDate(e)}
            status={showErrMsg && !date ? 'error' : ''}
          />
          {showErrMsg && !date && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        {isDayRequiredTemplate && (
          <div className="input-container">
            <label className="input-label">{t('notification.day')}</label>
            <Select
              showSearch
              value={day || null}

              allowClear={true}
              style={{ width: '325px' }}
              onChange={(option: any) => setDay(option)}
              options={notificationDayOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              placeholder={t('notification.placeholder.day')}
              status={showErrMsg && !day ? 'error' : ''}
            />
            {showErrMsg && !day && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
          </div>
        )}
        {isPaymentRequiredTemplate && (
          <div className="input-container">
            <label className="input-label">{t('createChitGroup.chitAmount')}</label>
            <InputNumber

              style={{ width: '325px' }}
              controls={false}
              addonBefore="₹"
              value={amount || null}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              maxLength={length.MAX_NOTIFICATION_AMOUNT}
              formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              placeholder={t('createChitGroup.placeholder.chitAmount')}
              onChange={(e) => handleChitAmount(constants.keys.AMOUNT, e)}
              status={showErrMsg && !amount ? 'error' : ''}
            />
            {showErrMsg && !amount && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
          </div>
        )}
        {isPartialPaymentRequiredTemplate && (
          <div className="input-container">
            <label className="input-label">{t('notification.balanceAmount')}</label>
            <InputNumber

              style={{ width: '325px' }}
              controls={false}
              addonBefore="₹"
              value={balanceAmount || null}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              maxLength={length.MAX_NOTIFICATION_AMOUNT}
              formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              placeholder={t('notification.placeholder.balanceAmount')}
              onChange={(e) => handleChitAmount(constants.keys.BALANCE_AMOUNT, e)}
              status={showErrMsg && !balanceAmount ? 'error' : ''}
            />
            {showErrMsg && !balanceAmount && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
          </div>
        )}
      </Space>

      <Space size={'middle'} className="notification-action-container">
        <Button size={elementSize} onClick={handleClearAllData}>
          {t('common.cancel')}
        </Button>
        <Button type="primary" size={elementSize} onClick={handleCreateTransaction}>
          {t('common.send')}
        </Button>
      </Space>
    </div>
  )
}

export default Notification
