import { useTranslation } from 'react-i18next'
import { allowOnlyNumbers } from '../../utils'
import { Input, InputNumber } from 'antd'

interface CustomerInfoProps {
  viewCustomer: boolean
  bankName: string
  accountNumber: any
  ifscCode: string
  accountHolderName: string
  setBankName: any
  setAccountNumber: any
  setIfscCode: any
  setAccountHolderName: any
}

const BankDetails: React.FC<CustomerInfoProps> = ({
  viewCustomer,
  bankName,
  accountNumber,
  ifscCode,
  accountHolderName,
  setBankName,
  setAccountNumber,
  setIfscCode,
  setAccountHolderName
}) => {
  const { t } = useTranslation()

  const handleAccountNumber = (value: any) => {
    if (allowOnlyNumbers(value)) {
      setAccountNumber(value)
    }
  }

  return (
    <div className="create-page-container">
      <div className="create-page-section">
        <div className="input-container">
          <label className="input-label">{t('createCustomer.bankName')}</label>
          <Input
            allowClear={true}
            className={'base-input-style'}
            readOnly={viewCustomer}
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            placeholder={t('createCustomer.placeholder.bankName')}
          />
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomer.accountNumber')}</label>
          <InputNumber
            controls={false}
            readOnly={viewCustomer}
            value={accountNumber}
            onChange={handleAccountNumber}
            style={{ width: '100%' }}
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder={t('createCustomer.placeholder.accountNumber')}
          />
        </div>
      </div>
      <div className="create-page-section">
        <div className="input-container">
          <label className="input-label">{t('createCustomer.ifscCode')}</label>
          <Input
            allowClear={true}
            className={'base-input-style'}
            readOnly={viewCustomer}
            value={ifscCode}
            onChange={(e) => setIfscCode(e.target.value)}
            placeholder={t('createCustomer.placeholder.ifscCode')}
          />
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomer.accountHolderName')}</label>
          <Input
            allowClear={true}
            readOnly={viewCustomer}
            value={accountHolderName}
            onChange={(e) => setAccountHolderName(e.target.value)}
            placeholder={t('createCustomer.placeholder.accountHolderName')}
          />
        </div>
      </div>
    </div>
  )
}

export default BankDetails
