import { useTranslation } from 'react-i18next'
import { Space, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { AiOutlineCopy } from 'react-icons/ai'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'

interface CustomerTableDesktopProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
  handleCopyCustomerToken: any
}

interface DataType {
  ID: number
  firstName: string
  lastName: string
  phoneNumber: string
  pendingTransactionsCount: number
  activeChitsCount: number
  isActive: boolean
  token: string
}

const CustomerTableDesktop: React.FC<CustomerTableDesktopProps> = ({ handleViewClick, handleEditClick, handleDeleteClick, handleCopyCustomerToken }) => {
  const { t } = useTranslation()
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, customerActionLoading, customerList, pageMetaData } = useAppSelector((state: RootState) => state.customer)

  const columns: ColumnsType<DataType> = [
    {
      title: t('customers.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('customers.tableHeaders.name'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_, record) => <>{`${record.firstName} ${record.lastName ? record.lastName : ''}`}</>
    },
    {
      title: t('customers.tableHeaders.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: t('customers.tableHeaders.pendingTransaction'),
      dataIndex: 'pendingTransactionsCount',
      key: 'pendingTransactionsCount',
      render: (value) => <span style={{ fontWeight: value ? 'bolder' : '', color: value > 1 ? 'red' : value === 1 ? '#ff9966' : 'grey' }}>{value || '-'}</span>
    },
    {
      title: t('customers.tableHeaders.activeChits'),
      dataIndex: 'activeChitsCount',
      key: 'activeChitsCount',
      render: (value) => <>{value || 0}</>
    },
    {
      title: t('customers.tableHeaders.active'),
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value) => <Tag color={value ? 'green' : 'red'}>{t(`common.${value ? 'yes' : 'no'}`)}</Tag>
    },
    {
      title: t('customers.tableHeaders.token'),
      dataIndex: 'token',
      key: 'token',
      render: (value) => (
        <span className="c-pointer" onClick={() => handleCopyCustomerToken(value)}>
          <AiOutlineCopy />
        </span>
      )
    },
    {
      title: t('customers.tableHeaders.action'),
      key: 'action',
      render: (_, record) => (
        <Space>
          {!isUser && <MdOutlineBorderColor onClick={() => handleEditClick(record.ID)} className="c-pointer" />}
          {(isSuperAdmin || isSystemAdmin) && <MdOutlineDelete onClick={() => handleDeleteClick(record.ID, record.phoneNumber)} className="c-pointer" />}
        </Space>
      )
    }
  ]

  return (
    <>
      <Table
        size="middle"
        rowKey={(record: { ID: number }) => record.ID}
        loading={isLoading || customerActionLoading}
        columns={columns as any}
        dataSource={customerList}
        pagination={false}
        scroll={{ x: 1300 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewClick(record.ID)
          }
        }}
      />
    </>
  )
}

export default CustomerTableDesktop
