import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Spin, Select, Tag, Card } from 'antd'
import { currencyFormatter, getDate, handleFilterValuesFromData } from '../../utils'
import { getPublicCustomerChitTransactions } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { paymentMethodOptions } from '../../utils/data'
import type { RootState } from '../../redux'

interface PublicCustomerChitTransactionListProps {
  customerId: number
  chitOptions: any
}

const PublicCustomerChitTransactionList: React.FC<PublicCustomerChitTransactionListProps> = ({ customerId, chitOptions }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [selectedChit, setSelectedChit] = useState<any>([])
  const [chitGroupOptions, setChitGroupOptions] = useState<any>([])
  const { publicCustomerActionLoading, publicCustomerChitTransactionsList } = useAppSelector((state: RootState) => state.publicCustomer)

  useEffect(() => {
    handleFormatDropDownOptions()
  }, [])

  const handleFormatDropDownOptions = () => {
    const newOptions = chitOptions?.map((chit: any) => {
      return {
        label: chit.chitName,
        value: chit.id
      }
    })
    if (newOptions?.length) {
      setSelectedChit(newOptions[0])
      const filters = `chitgroupID eq ${newOptions[0].value},customerID eq ${customerId}`
      dispatch(getPublicCustomerChitTransactions(filters))
      setChitGroupOptions(newOptions)
    }
  }

  const handleSelectChit = (option: any) => {
    setSelectedChit(option)
    const filters = `chitgroupID eq ${option},customerID eq ${customerId}`
    dispatch(getPublicCustomerChitTransactions(filters))
  }

  return (
    <Card size="small" title={t('publicCustomer.transactionDetails.title')} className='publicCustomerTransactionList'>
      {!!chitOptions?.length && <Select
        disabled={chitGroupOptions.length === 1}
        value={selectedChit}
        defaultValue={selectedChit}
        options={chitGroupOptions}
        onChange={handleSelectChit}
        className="select-width"
        style={{ marginBottom: '20px' }}
      />}
      {publicCustomerActionLoading ? (
        <Spin className="d-flex justify-center" />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {!publicCustomerChitTransactionsList.length ? (
            <p className="m-0 text-center text-gray" style={{ marginBottom: '5px' }}>{t('common.noTransactions')}</p>
          ) : (
            publicCustomerChitTransactionsList.map((record: any, index: any) => {
              const paymentMethodValue = handleFilterValuesFromData(record.paymentMethod, paymentMethodOptions)
              return (
                <Card size="small" style={{ width: '100%' }} bordered key={record.id}>
                  <div className="d-flex flex-column" style={{ gap: '6px' }}>
                    <div className="d-flex justify-between align-start" style={{ gap: '6px' }}>
                      <div className="white-space-nowrap">
                        <span className="text-gray"> {t('common.tableHeaders.sNo')}: </span> {index + 1}
                      </div>
                      <div className="d-flex flex-wrap justify-end" style={{ gap: '6px' }}>
                        {record.isPartialPayment && (
                          <Tag className="m-0" color="orange">
                            {t('publicCustomer.transactionDetails.tableHeaders.partialPayment')}
                          </Tag>
                        )}
                        <Tag className="m-0" color="grey">
                          {getDate(record.transactionDate)}
                        </Tag>
                        <Tag className="m-0" color={record.balanceAmount === '0' ? 'green' : 'red'}>
                          {record.balanceAmount === '0' ? t('common.paid') : t('common.unPaid')}
                        </Tag>
                        <Tag className="m-0" color={!record.isPaymentToCustomer ? 'blue' : 'green'}>
                          {!record.isPaymentToCustomer ? t('common.debited') : t('common.credited')}
                        </Tag>
                        <Tag className="m-0">{paymentMethodValue?.label || '-'}</Tag>
                      </div>
                    </div>
                    <div className="d-flex flex-column" style={{ gap: '6px' }}>
                      <div className="d-flex justify-between">
                        <div>
                          <span className="text-gray"> {t('chitTakenList.tableHeaders.installment')} </span>
                          <span> | </span>
                          <span className="text-gray"> {t('publicCustomer.transactionDetails.tableHeaders.payableAmount')} </span>
                        </div>
                        <div className="d-flex align-center" style={{ gap: '4px' }}>
                          <span> {record.installmentNumber} </span>
                          <span> | </span>
                          <span> {currencyFormatter(record.payableAmount)} </span>
                        </div>
                      </div>
                      <div className="d-flex justify-between">
                        <span className="text-gray"> {t('publicCustomer.transactionDetails.tableHeaders.receivedAmount')} </span>
                        <span> {currencyFormatter(record.amount)} </span>
                      </div>
                      {record.isPartialPayment && (
                        <div className="d-flex justify-between">
                          <span className="text-gray"> {t('publicCustomer.transactionDetails.tableHeaders.balanceAmount')} </span>
                          <span> {currencyFormatter(record.balanceAmount)} </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              )
            })
          )}
        </div>
      )}
    </Card>
  )
}

export default PublicCustomerChitTransactionList
