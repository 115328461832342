import { useTranslation } from 'react-i18next'
import { Card, Tag, Space } from 'antd'
import { currencyFormatter, handleFilterValuesFromData, getDate } from '../../../utils'
import { paymentMethodOptions } from '../../../utils/data'
import { useAppSelector } from '../../../redux/hooks'
import { PageLoader } from '../../common/PageLoader'
import type { RootState } from '../../../redux'

interface CustomerReportTableMobileProps {
  data: any
}

const CustomerReportTableMobile: React.FC<CustomerReportTableMobileProps> = ({ data }) => {
  const { t } = useTranslation()
  const { isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, customerReportPageMetaData } = useAppSelector((state: RootState) => state.report)

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {data?.map((record: any, index: any) => {
            const paymentMethodValue = handleFilterValuesFromData(record.paymentMethod, paymentMethodOptions)
            return (
              <Card size="small" style={{ width: '100%' }} bordered key={record.id}>
                <div className="d-flex flex-column" style={{ gap: '4px' }}>
                  <div className="d-flex justify-between align-center">
                    <div>
                      <span className="text-gray"> {t('transactions.customers.tableHeaders.sNo')}: </span> {(customerReportPageMetaData.currentPage - 1) * 10 + index + 1}{' '}
                    </div>
                    <div className="d-flex" style={{ gap: '6px' }}>
                      {record.isPartial && (
                        <Tag className="m-0" color="orange">
                          {t('transactions.customers.tableHeaders.partialPayment')}
                        </Tag>
                      )}
                      <Tag className="m-0" color="grey">
                        {getDate(record.transactionDate)}
                      </Tag>
                    </div>
                  </div>
                  <div> {record.chitName} </div>
                  <div className="d-flex flex-column" style={{ gap: '4px' }}>
                    <div className="d-flex justify-between">
                      <div>
                        <span className="text-gray"> {t('chitTakenList.tableHeaders.installment')} </span>
                        <span> | </span>
                        <span className="text-gray"> {t('transactions.customers.tableHeaders.payableAmount')} </span>
                      </div>
                      <div className="d-flex align-center" style={{ gap: '4px' }}>
                        <span> {record.installmentNumber} </span>
                        <span> | </span>
                        <span> {currencyFormatter(record.payableAmount)} </span>
                      </div>
                    </div>
                    {paymentMethodValue?.label && (
                      <div className="d-flex justify-between">
                        <span className="text-gray"> {t('transactions.customers.tableHeaders.amount')} </span>
                        <span> {currencyFormatter(record.amount)} </span>
                      </div>
                    )}
                    {record.isPartial && (
                      <div className="d-flex justify-between">
                        <span className="text-gray"> {t('transactions.customers.tableHeaders.balanceAmount')} </span>
                        <span> {currencyFormatter(record.balanceAmount)} </span>
                      </div>
                    )}
                  </div>
                </div>
                {!isUser && (
                  <Space className="d-flex justify-between" style={{ marginTop: '10px', gap: '10px' }}>
                    <div className="d-flex" style={{ gap: '6px' }}>
                      <Tag className="m-0" color={record.balanceAmount === '0' ? 'green' : 'red'}>
                        {record.balanceAmount === '0' ? t('common.paid') : t('common.unPaid')}
                      </Tag>
                      {paymentMethodValue?.label && <Tag className="m-0">{paymentMethodValue.label}</Tag>}
                    </div>
                  </Space>
                )}
              </Card>
            )
          })}
        </div>
      )}
    </>
  )
}

export default CustomerReportTableMobile
