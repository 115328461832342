import { useTranslation } from 'react-i18next'
import { getDateWithTime } from '../../utils'
import { useAppSelector } from '../../redux/hooks'
import type { RootState } from '../../redux'

import { Card, Tag } from 'antd'

interface ChitGroupInfoProps {
  chitInfo: any
}

const ChitGroupInfo: React.FC<ChitGroupInfoProps> = ({ chitInfo }) => {
  const { t } = useTranslation()
  const { isSystemAdmin } = useAppSelector((state: RootState) => state.auth)

  return (
    <Card size="small" title={t('createChitGroup.info.title')}>
      {isSystemAdmin && (
        <div className="d-flex mb-10">
          <label style={{ marginRight: '10px' }}>{t('createChitGroup.info.chitGroupId')} :</label>
          <p className="m-0">{chitInfo.ID}</p>
        </div>
      )}
      <div className="d-flex mb-10">
        <label style={{ marginRight: '10px' }}>{t('createChitGroup.info.created')} :</label>
        <p className="m-0">{getDateWithTime(chitInfo.createdAt)}</p>
      </div>
      <div className="d-flex mb-10">
        <label style={{ marginRight: '10px' }}>{t('createChitGroup.info.updated')} :</label>
        <p className="m-0">{getDateWithTime(chitInfo.updatedAt)}</p>
      </div>
      <div className="d-flex">
        <label style={{ marginRight: '10px' }}>{t('createChitGroup.info.active')} :</label>
        <Tag color={chitInfo.isActive ? 'green' : ''}>{t(`common.${chitInfo.isActive ? 'yes' : 'no'}`)}</Tag>
      </div>
    </Card>
  )
}

export default ChitGroupInfo
