import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CreateTransactionModal from './create'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { getAllCustomerTransactionList, getDefaultCustomerTransaction, clearSuccessErrorData, clearDefaultCustomerTransactionData, deleteCustomerTransaction, setToast } from '../../redux'
import constants from '../../constants'
import FilterContainer from '../../components/Filter'
import { posthogCaptureEvent } from '../../utils'

import { Pagination } from 'antd'
import CustomerTransactionTableMobile from '../../components/customerTransactions/mobile'
import CustomerTransactionTableDesktop from '../../components/customerTransactions/desktop'
import DeleteModal from '../../components/common/SimpleModal/delete'
import type { RootState } from '../../redux'
import { DeleteModalState } from '../../types/common'

const CustomerTransactions: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isMobile } = useAppSelector((state) => state.common)
  const { customerTransactionActionLoading, pageMetaData, success, error } = useAppSelector((state: RootState) => state.customerTransaction)

  const [visibleTransactionModal, setVisibleTransactionModal] = useState<boolean>(false)
  const [isView, setIsView] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [filterValues, setFilterValues] = useState<string>('')
  const [deleteModal, setDeteteModal] = useState<DeleteModalState>({
    type: '',
    visible: false,
    id: '',
    name: '',
    title: '',
    desc: ''
  })

  useEffect(() => {
    dispatch(getAllCustomerTransactionList({ currentPage: 1, size: constants.PAGE_SIZE, filters: '' }))
  }, [])

  useEffect(() => {
    if (deleteModal.id && success.type === 'Success') {
      dispatch(setToast({
        show: true,
        content: t('common.info.deletedChitTransactionSuccess')
      }))
      dispatch(getAllCustomerTransactionList({ currentPage: 1, size: constants.PAGE_SIZE, filters: filterValues }))
      handleCloseDeleteModal()
    }
  }, [success])

  useEffect(() => {
    if (error?.errorType) {
      dispatch(setToast({
        show: true,
        type: 'error',
        content: error.errorType
      }))
      dispatch(clearSuccessErrorData())
    }
  }, [error])

  const handleCreateCustomerTransaction = () => {
    posthogCaptureEvent('create_screen', { type: 'customer_transaction' })
    dispatch(clearDefaultCustomerTransactionData())
    setVisibleTransactionModal(true)
    setIsView(false)
  }

  const handleViewClick = (transactionId: any) => {
    posthogCaptureEvent('view_screen', { type: 'customer_transaction' })
    setIsView(true)
    dispatch(getDefaultCustomerTransaction(transactionId.id))
    setVisibleTransactionModal(true)
  }

  const handleEditClick = (transactionId: any) => {
    posthogCaptureEvent('edit_screen', { type: 'customer_transaction' })
    setIsView(false)
    dispatch(getDefaultCustomerTransaction(transactionId.id))
    setVisibleTransactionModal(true)
  }

  const handleDeleteClick = (value: any, name: string) => {
    posthogCaptureEvent('delete_screen', { type: 'customer_transaction' })
    setDeteteModal({
      type: constants.DELETE,
      visible: true,
      id: value,
      name,
      title: t(`deleteModal.title.customerTransaction`),
      desc: t('transactions.customers.deleteModalBody')
    })
  }

  const handleDeleteConfirmModal = (id: any) => {
    posthogCaptureEvent('delete_action_confirmation', { type: 'customer_transaction', id })
    dispatch(deleteCustomerTransaction(id))
  }

  const handleCloseDeleteModal = () => {
    posthogCaptureEvent('delete_action_cancel', { type: 'customer_transaction' })
    setDeteteModal({ type: '', visible: false, id: '', name: '', title: '', desc: '' })
  }

  const handleFilterValues = async (filters: string) => {
    posthogCaptureEvent('applied_filters', { type: 'customer_transaction', value: filters || 'RESET' })
    setFilterValues(filters)
    dispatch(getAllCustomerTransactionList({ currentPage: 1, size: constants.PAGE_SIZE, filters }))
  }

  const handleChangePagination = (pageNo: number) => {
    posthogCaptureEvent('change_pagination', { type: 'customer_transaction', pageNo })
    setCurrentPage(pageNo)
    dispatch(getAllCustomerTransactionList({ currentPage: pageNo, size: constants.PAGE_SIZE, filters: filterValues }))
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <FilterContainer
        showFilter={true}
        t={t}
        filterType={constants.filterType.CUSTOMER_TRANSACTION}
        handleCreateAction={handleCreateCustomerTransaction}
        handleFilterValues={handleFilterValues}
      />
      {isMobile ? (
        <CustomerTransactionTableMobile handleViewClick={handleViewClick} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
      ) : (
        <CustomerTransactionTableDesktop handleViewClick={handleViewClick} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
      )}
      {pageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ margin: '20px 0' }}
          onChange={handleChangePagination}
          total={pageMetaData.totalRecords}
          current={pageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
      {visibleTransactionModal && (
        <CreateTransactionModal
          isView={isView}
          visibleModal={visibleTransactionModal}
          closeModal={() => setVisibleTransactionModal(false)}
          currentPage={currentPage}
          filters={filterValues}
        />
      )}
      {deleteModal.visible && (
        <DeleteModal
          visibleModal={deleteModal.visible}
          data={deleteModal}
          loading={customerTransactionActionLoading}
          confirmModal={handleDeleteConfirmModal}
          closeModal={handleCloseDeleteModal}
        />
      )}
    </div>
  )
}

export default CustomerTransactions
