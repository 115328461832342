import React from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import Routes from './routes'
import { Store } from '../redux'

const AppRoute: React.FC = () => {
  return (
    <Provider store={Store}>
      <RouterProvider router={Routes} />
    </Provider>
  )
}

export default AppRoute
