import { useNavigate, NavigateFunction } from 'react-router-dom'
import config from '../../config'
import configurableData from '../../config/configurable.json'
import { Button, Result } from 'antd'

const UnAuthorizedPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()

  const handleNavigateDefaultPage = (): void => {
    navigate(config.pages.dashboard)
  }

  return (
    <div className="broken-page-container">
      <div className="broken-page-content">
        <div className="p-6 text-left font-semibold">
          <span onClick={handleNavigateDefaultPage} className="c-pointer">
            {configurableData.fullName}
          </span>
        </div>
        <div className="broken-image-content">
          <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" size="large" onClick={handleNavigateDefaultPage}>
                Back Home
              </Button>
            }
          />
        </div>
        <div className="p-6 font-light text-left">{configurableData.shortName} © 2024</div>
      </div>
    </div>
  )
}

export default UnAuthorizedPage
