import i18n from '../i18n/config'
import ERR_CODE_CONSTANTS from '../constants/errCode'

export const getErrorMessage = (errorCode: string): string => {
  let msg = ''
  if (errorCode) {
    switch (errorCode) {
      case ERR_CODE_CONSTANTS.INVALID_CREDS:
        msg = i18n.t('errMsg.INVALID_CREDS')
        break
      case ERR_CODE_CONSTANTS.LOGIN_DISABLED:
        msg = i18n.t('errMsg.LOGIN_DISABLED')
        break
      case ERR_CODE_CONSTANTS.UNABLE_TO_DELETE:
        msg = i18n.t('errMsg.UNABLE_TO_DELETE')
        break
      default:
        msg = i18n.t('errMsg.SYSTEM_DOWN')
    }
  }
  return msg
}
