import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserConfig, createUserConfig, updateUserConfig } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { AiOutlineDown } from 'react-icons/ai'
import { langOptions } from '../../utils/data'
import { setLocale, getLocale, handleLogout, handleFilterValuesFromData } from '../../utils'
import { PageLoader } from '../../components/common/PageLoader'
import OnboardingTour from '../../components/userConfig/onboardingTour'

import { Card, Button, Dropdown, Space, Typography } from 'antd'
import type { MenuProps } from 'antd'
import type { RootState } from '../../redux'

const Settings: React.FC = () => {

  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  const { userData, isSystemAdmin, isSuperAdmin, isAdmin } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, userConfigActionLoading, userConfigId, onboardingTourData, } = useAppSelector((state: RootState) => state.userConfig)
  const { isMobile, elementSize } = useAppSelector((state) => state.common)

  const getActiveLang = () => {
    return handleFilterValuesFromData(i18n.language, langOptions)
  }

  const handleChangeLanguage: MenuProps['onClick'] = ({ key }) => {
    setLocale(key)
    i18n.changeLanguage(key)
  }

  const getUserRole = () => {
    return isSystemAdmin ? t('user.systemAdmin') : isSuperAdmin ? t('user.superAdmin') : isAdmin ? t('user.admin') : t('user.user')
  }

  useEffect(() => {
    userData?.userId && dispatch(getUserConfig(userData?.userId))
  }, [userData])

  const handleCreateUserConfig = () => {
    const data = {
      onboardingTour: onboardingTourData,
      userLang: getLocale()
    }
    const payload: any = {
      config: JSON.stringify(data),
      isActive: true,
      userID: userData.userId
    }
    if (userConfigId) {
      payload['id'] = userConfigId
    }
    dispatch(userConfigId ? updateUserConfig(payload) : createUserConfig(payload))
  }

  return (
    <div>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div className='d-flex flex-column' style={{ margin: '20px 0', gap: '20px' }}>
          <div className='d-flex flex-wrap' style={{ gap: '20px' }}>
            <Card size="small" title={t('userConfig.info')} className="text-center userConfig__data-card">
              <div className="d-flex justify-between" style={{ gap: '20px' }}>
                <p className="m-0 text-left">{ t('userConfig.name') }</p>
                <p className="m-0">{ userData.name }</p>
              </div>
              <div className="d-flex justify-between" style={{ gap: '20px' }}>
                <p className="m-0 text-left">{ t('userConfig.emailId') }</p>
                <p className="m-0">{ userData.email }</p>
              </div>
              <div className="d-flex justify-between" style={{ gap: '20px' }}>
                <p className="m-0 text-left">{ t('userConfig.role') }</p>
                <p className="m-0">{ getUserRole() }</p>
              </div>
            </Card>
            <Card size="small" title={t('userConfig.controls')} className="text-center userConfig__data-card">
              <div className="d-flex justify-between" style={{ gap: '20px' }}>
                <p className="m-0 text-left">{ t('userConfig.langugae') }</p>
                <Dropdown menu={{ items: langOptions, selectable: true, defaultSelectedKeys: [getActiveLang()?.key], onClick: handleChangeLanguage }} placement="bottom">
                  <Typography.Link>
                    <Space>
                      {getActiveLang().label}
                      <AiOutlineDown />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              </div>
            </Card>
            <OnboardingTour />
          </div>
          <div className={`d-flex ${isMobile ? 'justify-end' : ''}`} style={{ gap: '10px' }}>
            <Button size={elementSize} className="d-flex align-center" type="primary" disabled={userConfigActionLoading} onClick={handleCreateUserConfig}>
              {t('common.update')}
            </Button>
            <Button size={elementSize} className="d-flex align-center" danger disabled={userConfigActionLoading} onClick={handleLogout}>
              {t('user.logout')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Settings
