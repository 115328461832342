import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CreateUserModal from './create'
import DeleteModal from '../../components/common/SimpleModal/delete'
import FilterContainer from '../../components/Filter'
import { getUserList, getDefaultUser, deleteUser, clearDefaultUserData, setToast } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import constants from '../../constants'

import { Pagination, } from 'antd'
import UserTableMobile from '../../components/users/mobile'
import UserTableDesktop from '../../components/users/desktop'
import type { RootState } from '../../redux'
import { DeleteModalState } from '../../types/common'

const Users: React.FC = () => {
  const [visibleUserModal, setVisibleUserModal] = useState<boolean>(false)
  const [isView, setIsView] = useState<boolean>(false)
  const [deleteModal, setDeteteModal] = useState<DeleteModalState>({
    type: '',
    visible: false,
    id: '',
    name: '',
    title: '',
    desc: ''
  })
  const [currentPage, setCurrentPage] = useState<number>(1)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { userActionLoading, pageMetaData, success } = useAppSelector((state: RootState) => state.user)
  const { isMobile } = useAppSelector((state) => state.common)

  useEffect(() => {
    dispatch(getUserList({ currentPage: 1, size: constants.PAGE_SIZE }))
  }, [dispatch])

  const handleFilterValues = async (filters: string) => {
    // setFilterValues(filters)
    // dispatch(getAllChitGroupTransactionList({ currentPage: 1, size: constants.PAGE_SIZE, filters }))
  }

  useEffect(() => {
    if (deleteModal.id && success.type === 'Success') {
      dispatch(setToast({
        show: true,
        content: t('common.info.deletedUserSuccess')
      }))
      dispatch(getUserList({ currentPage: 1, size: constants.PAGE_SIZE }))
      handleCloseDeleteModal()
    }
  }, [success])

  const handleChangePagination = (pageNo: number) => {
    setCurrentPage(pageNo)
    dispatch(getUserList({ currentPage: pageNo, size: constants.PAGE_SIZE }))
  }

  const handleCreateUser = () => {
    dispatch(clearDefaultUserData())
    setVisibleUserModal(true)
    setIsView(false)
  }

  const handleViewClick = (userId: string) => {
    setIsView(true)
    dispatch(getDefaultUser({ userId }))
    setVisibleUserModal(true)
  }

  const handleEditClick = (userId: string) => {
    setIsView(false)
    dispatch(getDefaultUser({ userId }))
    setVisibleUserModal(true)
  }

  const handleDeleteClick = (value: string, name: string) => {
    setDeteteModal({
      type: constants.DELETE,
      visible: true,
      id: value,
      name,
      title: t(`deleteModal.title.user`),
      desc: t('users.deleteModalBody')
    })
  }

  const handleDeleteConfirmModal = (id: string) => {
    dispatch(deleteUser(id))
  }

  const handleCloseDeleteModal = () => {
    setDeteteModal({ type: '', visible: false, id: '', name: '', title: '', desc: '' })
  }

  return (
    <div>
      <FilterContainer
        showFilter={pageMetaData.totalRecords > 10}
        t={t}
        filterType={constants.filterType.USER}
        showUserOnly={true}
        actionName={t('users.createCTA')}
        handleCreateAction={handleCreateUser}
        handleFilterValues={handleFilterValues}
      />
      {isMobile ? (
        <UserTableMobile handleViewClick={handleViewClick} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
      ) : (
        <UserTableDesktop handleViewClick={handleViewClick} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
      )}
      {pageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ margin: '20px 0' }}
          onChange={handleChangePagination}
          total={pageMetaData.totalRecords}
          current={pageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
      {visibleUserModal && (
        <CreateUserModal
          isView={isView}
          visibleModal={visibleUserModal}
          closeModal={() => {
            setVisibleUserModal(false)
          }}
          currentPage={currentPage}
        />
      )}
      <DeleteModal visibleModal={deleteModal.visible} data={deleteModal} loading={userActionLoading} confirmModal={handleDeleteConfirmModal} closeModal={handleCloseDeleteModal} />
    </div>
  )
}

export default Users
