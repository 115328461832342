import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getUserConfig, createUserConfig, updateUserConfig } from '../thunks/userConfig'
import { userLogout } from '../actions'
import { UserConfigState } from '../../types/redux'
import { setLocale } from '../../utils'

const initialState: UserConfigState = {
  isLoading: false,
  userConfigActionLoading: false,
  userConfigId: '',
  userConfigJson: {},
  onboardingTourData: {
    DASHBOARD: false,
    CHIT_GROUP_LIST: false,
    CUSTOMER_LIST: false,
    CHIT_TRANSACTION_LIST: false,
    CUSTOMER_TRANSACTION_LIST: false,
    CHIT_REPORT: false,
    CUSTOMER_REPORT: false,
    ANALYTICS: false,
    USER_LIST: false,
    SETTINGS: false
  },
  success: {},
  error: {}
}

export const userConfig = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    updateOnboardingTourData: (state, { payload }) => {
      state.onboardingTourData = {
        ...state.onboardingTourData,
        [payload.name]: payload.value
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserConfig.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUserConfig.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.userConfigId = payload?.data?.data?.ID
      if (payload?.data?.data?.config) {
        const parsedData = JSON.parse(payload?.data?.data?.config)
        state.userConfigJson = parsedData
        state.onboardingTourData = parsedData?.onboardingTour
        setLocale(parsedData.userLang)
      }
    })
    builder.addCase(getUserConfig.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createUserConfig.pending, (state) => {
      state.userConfigActionLoading = true
    })
    builder.addCase(createUserConfig.fulfilled, (state, { payload }) => {
      state.userConfigActionLoading = false
      state.success = payload?.data
    })
    builder.addCase(createUserConfig.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userConfigActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateUserConfig.pending, (state) => {
      state.userConfigActionLoading = true
    })
    builder.addCase(updateUserConfig.fulfilled, (state, { payload }) => {
      state.userConfigActionLoading = false
      state.success = payload?.data
    })
    builder.addCase(updateUserConfig.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userConfigActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        userConfigActionLoading: false,
        userConfigId: '',
        userConfigJson: {},
        onboardingTourData: {
          DASHBOARD: false,
          CHIT_GROUP_LIST: false,
          CUSTOMER_LIST: false,
          CHIT_TRANSACTION_LIST: false,
          CUSTOMER_TRANSACTION_LIST: false,
          CHIT_REPORT: false,
          CUSTOMER_REPORT: false,
          ANALYTICS: false,
          USER_LIST: false,
          SETTINGS: false
        },
        success: {},
        error: {}
      }
    })
  }
})

export const { updateOnboardingTourData } = userConfig.actions
export const userConfigReducer = userConfig.reducer
