import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getUserList, getAutocompleteUserList, getDefaultUser, createUser, updateUser, deleteUser, setUserChanneId } from '../thunks/userThunk'
import { userLogout } from '../actions'
import { UserState } from '../../types/redux'

const initialState: UserState = {
  isLoading: false,
  userActionLoading: false,
  userList: [],
  userAutoCompleteLoading: false,
  filteredUserList: [],
  pageMetaData: {},
  defaultUser: {},
  success: {},
  error: {},
  channelSetSuccess: {}
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearDefaultUserData: (state) => {
      state.defaultUser = {}
    },
    clearChannelSetSuccessData: (state) => {
      state.channelSetSuccess = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.userList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getUserList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getAutocompleteUserList.pending, (state) => {
      state.userAutoCompleteLoading = true
    })
    builder.addCase(getAutocompleteUserList.fulfilled, (state, { payload }) => {
      state.userAutoCompleteLoading = false
      state.success = {}
      state.filteredUserList = payload.data.data
    })
    builder.addCase(getAutocompleteUserList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userAutoCompleteLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDefaultUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDefaultUser.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.defaultUser = payload.data.data
      state.success = {}
    })
    builder.addCase(getDefaultUser.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createUser.pending, (state) => {
      state.userActionLoading = true
    })
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.userActionLoading = false
      state.success = payload.data
    })
    builder.addCase(createUser.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateUser.pending, (state) => {
      state.userActionLoading = true
    })
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.userActionLoading = false
      state.success = payload.data
    })
    builder.addCase(updateUser.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(deleteUser.pending, (state) => {
      state.userActionLoading = true
    })
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.userActionLoading = false
      state.success = payload.data
    })
    builder.addCase(deleteUser.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(setUserChanneId.pending, (state) => {
      state.userActionLoading = true
    })
    builder.addCase(setUserChanneId.fulfilled, (state, { payload }) => {
      state.userActionLoading = false
      state.channelSetSuccess = payload.data
    })
    builder.addCase(setUserChanneId.rejected, (state, { payload }: PayloadAction<any>) => {
      state.userActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        userActionLoading: false,
        userList: [],
        userAutoCompleteLoading: false,
        filteredUserList: [],
        pageMetaData: {},
        defaultUser: {},
        success: {},
        error: '',
        channelSetSuccess: {}
      }
    })
  }
})

export const { clearDefaultUserData, clearChannelSetSuccessData } = userSlice.actions

export const userReducer = userSlice.reducer
