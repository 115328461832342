import { useTranslation } from 'react-i18next'
import Secure from '../../../assets/images/featuresOverview/secure.svg'
import Payment from '../../../assets/images/featuresOverview/payment.svg'
import Reminder from '../../../assets/images/featuresOverview/reminder.svg'
import Insights from '../../../assets/images/featuresOverview/insights.svg'
import UserFriendly from '../../../assets/images/featuresOverview/userFriendly.svg'
import Support from '../../../assets/images/featuresOverview/support.svg'

const FeaturesOverview: React.FC = () => {
  const { t } = useTranslation()
  const content = t('landingPage.featuresOverview.content', { returnObjects: true })

  const imgSrc = [Insights, UserFriendly, Support, Secure, Payment, Reminder]

  return (
    <div id="features" className="landingPage-featuresOverview">
      <div className="landingPage-featuresOverview-container">
        <div className="landingPage-featuresOverview-title">{t('landingPage.featuresOverview.title')}</div>
        <div className="landingPage-featuresOverview-features-container">
          {content.map(({ title, description }, id) => (
            <div key={title} className="landingPage-featuresOverview-feature">
              <img src={imgSrc[id]} alt="FeaturesOverview" />
              <div className="landingPage-featuresOverview-feature-title">{title}</div>
              <div className="landingPage-featuresOverview-feature-description">{description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FeaturesOverview
