import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'antd'
import { userResetPassword } from '../../../redux'
import type { RootState } from '../../../redux'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { emailValidation } from '../../../utils'
const { TextArea } = Input

const Contact: React.FC = () => {
  const [name, setName] = useState<string>('')
  const [mailId, setMailId] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [success] = useState<boolean>(false)

  const [showErrMsg, setShowErrMsg] = useState<boolean>(false)
  const [emailErr, setEmailErr] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { isLoading } = useAppSelector((state: RootState) => state.auth)

  const handleConnect = (): void => {
    if (name.length > 3 && message.length > 3 && emailValidation(mailId)) {
      const payload = { name, mailId, message }
      dispatch(userResetPassword(payload))
    } else {
      handleValidateFields()
    }
  }

  const handleValidateFields = () => {
    if (name.length < 3 || message.length < 3 || !mailId.length) {
      setShowErrMsg(true)
    }
    setEmailErr(!emailValidation(mailId))
  }

  return (
    <div id='contact' className="landingPage-contact">
      <div className="landingPage-contact-content">
        <h2 style={{ textAlign: 'center', fontSize: '30px' }}>{t('landingPage.contact.title')}</h2>
        <div className="w-100 landingPage-contact-input-group">
          <div className="input-container w-100">
            <Input
              allowClear={true}
              status={showErrMsg && name.length < 3 ? 'error' : ''}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t('landingPage.contact.placeholder.name')}
              className="landingPage-contact-input"
            />
            {showErrMsg && name.length < 3 && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
          </div>
          <div className="input-container w-100">
            <Input
              allowClear={true}
              status={emailErr ? 'error' : ''}
              value={mailId}
              onChange={(e) => setMailId(e.target.value)}
              onBlur={() => setEmailErr(!emailValidation(mailId))}
              placeholder={t('landingPage.contact.placeholder.email')}
              className="landingPage-contact-input"
            />
            {emailErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
          </div>
        </div>
        <div className="input-container">
          <TextArea
            rows={4}
            allowClear={true}
            status={showErrMsg && message.length < 3 ? 'error' : ''}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={t('landingPage.contact.placeholder.message')}
            className="landingPage-contact-input"
          />
          {showErrMsg && message.length < 3 && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="landingPage-contact-action">
          <Button type="primary" style={{ margin: '20px 0 0 0' }} size="large" loading={isLoading} onClick={handleConnect}>
            {t('landingPage.contact.connect')}
          </Button>
          {success && <p style={{ color: '#666', margin: '15px 0 30px 0' }}>{t('landingPage.contact.successInfo')}</p>}
        </div>
      </div>
      <div className="landingPage-contact-copyRight">{t('landingPage.contact.copyRightInfo')}</div>
    </div>
  )
}

export default Contact
