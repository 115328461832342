import { createAsyncThunk } from '@reduxjs/toolkit'
import Axios from 'axios'
import CONFIG from '../../config'
import { handleLogout } from '../../utils'

export const getPublicCustomerInfo = createAsyncThunk('GET_PUBLIC_CUSTOMER_INFO', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await Axios.get(CONFIG.api.getPublicCustomerInfo(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getPublicCustomerChitTransactions = createAsyncThunk('GET_PUBLIC_CUSTOMER_CHIT_TRANSACTIONS', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await Axios.get(CONFIG.api.getPublicCustomerChitTransactions(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
