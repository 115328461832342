import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Upload, Button } from 'antd'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import type { RootState } from '../../../redux'
import { setToast } from '../../../redux'

import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload/interface'

interface ModalProps {
  visibleModal: boolean
  loading?: boolean
  confirmModal?: any
  closeModal?: any
}

const BulkUploadModal: React.FC<ModalProps> = ({ visibleModal, loading, confirmModal, closeModal }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { elementSize } = useAppSelector((state: RootState) => state.common)
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const modalStyle = {
    body: {
      padding: '10px 0 20px 0'
    }
  }

  const getModalTitle = () => {
    return t('common.massUploadModal.customerTitle')
  }

  const handleBeforeUpload = (file: RcFile): boolean => {
    const isValidFileType = (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || (file.type === 'text/csv')
    if (!isValidFileType) {
      dispatch(setToast({
        show: true,
        type: 'error',
        content: t('errMsg.fileType')
      }))
    }
    return isValidFileType
  }

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    let newFileList = [...info.fileList]
    newFileList = newFileList.slice(-1)
    setFileList(newFileList)
  }

  const handleSubmit = () => {
    if (fileList.length) {
      console.log('Uploading file:', fileList.length)
    } else {
      dispatch(setToast({
        show: true,
        type: 'error',
        content: t('errMsg.fileToUpload')
      }))
    }
  }

  return (
    <Modal
      open={visibleModal}
      title={getModalTitle()}
      onOk={confirmModal}
      onCancel={closeModal}
      maskClosable={false}
      styles={modalStyle}
      width={750}
      footer={[
        <Button size={elementSize} key='submit' type='primary' onClick={confirmModal} disabled={!fileList.length} loading={loading}> {t('common.massUpload')} </Button>
      ]}
    >
      <div className='d-flex flex-column gap-2'>
        <div className='massDownloadInfo'>
          <Button type='link' className='p-0 h-0'> {t('common.downloadTemplateAction')} </Button> {t('common.downloadTemplateInfo')}
        </div>
        <div>
          <div className='d-flex gap-1'>
            <Upload
              beforeUpload={handleBeforeUpload}
              onChange={handleChange}
              fileList={fileList}
              accept='.xlsx,.csv'
              className='w-100'
            >
              <Button className='w-100 text-left'> {t('common.massUploadModal.uploadFile')} </Button>
            </Upload>
            <Button size={elementSize} type='primary' onClick={handleSubmit} loading={loading}> {t('common.select')} </Button>
          </div>
          <p className='text-gray m-0'>{t('common.massUploadModal.fileInfo')}</p>
        </div>
      </div>
    </Modal>
  )
}

export default BulkUploadModal
