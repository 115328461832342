import { useTranslation } from 'react-i18next'
import { Card, Switch } from 'antd'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import userConfigConstants from '../../constants/userConfig'
import { updateOnboardingTourData } from '../../redux'
import type { RootState } from '../../redux'

const OnboardingTour:React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { onboardingTourData } = useAppSelector((state: RootState) => state.userConfig)

  const handleOnboardingTourChange = (value: boolean, name: string) => {
    dispatch(updateOnboardingTourData({ value, name }))
  }

  const { DASHBOARD, CHIT_GROUP_LIST, CUSTOMER_LIST, CHIT_TRANSACTION_LIST, CUSTOMER_TRANSACTION_LIST, ANALYTICS } = userConfigConstants.onboarding
  return (
    <Card size="small" title={t('userConfig.onboardingTour.title')} className="text-center userConfig__data-card">
      <div className="d-flex justify-between" style={{ gap: '20px' }}>
        <p className="m-0 text-left">{ t('userConfig.onboardingTour.dashboard') }</p>
        <Switch checked={onboardingTourData[DASHBOARD]} onChange={e => handleOnboardingTourChange(e, DASHBOARD)} />
      </div>
      <div className="d-flex justify-between" style={{ gap: '20px' }}>
        <p className="m-0 text-left">{ t('userConfig.onboardingTour.chitGroup') }</p>
        <Switch checked={onboardingTourData[CHIT_GROUP_LIST]} onChange={e => handleOnboardingTourChange(e, CHIT_GROUP_LIST)} />
      </div>
      <div className="d-flex justify-between" style={{ gap: '20px' }}>
        <p className="m-0 text-left">{ t('userConfig.onboardingTour.customer') }</p>
        <Switch checked={onboardingTourData[CUSTOMER_LIST]} onChange={e => handleOnboardingTourChange(e, CUSTOMER_LIST)} />
      </div>
      <div className="d-flex justify-between" style={{ gap: '20px' }}>
        <p className="m-0 text-left">{ t('userConfig.onboardingTour.chitGroupTransaction') } { t('userConfig.onboardingTour.transaction') }</p>
        <Switch checked={onboardingTourData[CHIT_TRANSACTION_LIST]} onChange={e => handleOnboardingTourChange(e, CHIT_TRANSACTION_LIST)} />
      </div>
      <div className="d-flex justify-between" style={{ gap: '20px' }}>
        <p className="m-0 text-left">{ t('userConfig.onboardingTour.chitGroupTransaction') } { t('userConfig.onboardingTour.customerTransaction') }</p>
        <Switch checked={onboardingTourData[CUSTOMER_TRANSACTION_LIST]} onChange={e => handleOnboardingTourChange(e, CUSTOMER_TRANSACTION_LIST)} />
      </div>
      <div className="d-flex justify-between" style={{ gap: '20px' }}>
        <p className="m-0 text-left">{ t('userConfig.onboardingTour.analytics') }</p>
        <Switch checked={onboardingTourData[ANALYTICS]} onChange={e => handleOnboardingTourChange(e, ANALYTICS)} />
      </div>
    </Card>
  )
}

export default OnboardingTour
