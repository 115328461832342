import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'antd'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'

const DELETE = 'DELETE'

interface ModalProps {
  visibleModal: boolean
  data: any
  loading?: boolean
  confirmModal: any
  closeModal: any
}

const DeleteModal: React.FC<ModalProps> = ({ visibleModal, data, loading, confirmModal, closeModal }) => {
  const { t } = useTranslation()
  const { elementSize } = useAppSelector((state: RootState) => state.common)

  const modalStyle = {
    body: {
      padding: '10px 0 20px 0'
    }
  }

  return (
    <Modal
      open={visibleModal}
      title={`${t('deleteModal.heading')} ${data.name} ${data.title} ?`}
      onOk={() => confirmModal(data.id)}
      onCancel={closeModal}
      styles={modalStyle}
      footer={[
        <Button size={elementSize} key="back" onClick={closeModal} disabled={loading}>
          {t('deleteModal.cancel')}
        </Button>,
        <Button size={elementSize} key="submit" type="primary" onClick={() => confirmModal(data.id)} loading={loading}>
          {t('deleteModal.delete')}
        </Button>
      ]}
    >
      <div className={`${data.type === DELETE ? 'block' : 'd-none'}`}>
        <p className="text-gray m-0">{data.desc}</p>
      </div>
    </Modal>
  )
}

export default DeleteModal
