import { useTranslation } from 'react-i18next'
import { Card, Tag, Button, Space } from 'antd'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { useAppSelector } from '../../../redux/hooks'
import { PageLoader } from '../../common/PageLoader'
import { roleOptions } from '../../../utils/data'
import { handleFilterValuesFromData } from '../../../utils'
import constants from '../../../constants'
import type { RootState } from '../../../redux'

interface UserTableMobileProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

const UserTableMobile: React.FC<UserTableMobileProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { isSystemAdmin } = useAppSelector((state: RootState) => state.auth)
  const { elementSize } = useAppSelector((state: RootState) => state.common)
  const { isLoading, userActionLoading, userList, pageMetaData } = useAppSelector((state: RootState) => state.user)

  return (
    <>
      {isLoading || userActionLoading ? (
        <PageLoader />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {userList.map((record: any, index: any) => {
            const roleValue = handleFilterValuesFromData(record.role, roleOptions)
            return (
              <Card size="small" style={{ width: '100%' }} bordered key={record.ID}>
                <div className="d-flex flex-column" style={{ gap: '6px' }} onClick={() => handleViewClick(record.ID)}>
                  <div className="d-flex justify-between align-center">
                    <div>
                      <span className="text-gray"> {t('users.tableHeaders.sNo')}: </span> {(pageMetaData.currentPage - 1) * 10 + index + 1}
                    </div>
                    <div className="d-flex" style={{ gap: '6px' }}>
                      <Tag className="m-0" color="grey">
                        {roleValue ? roleValue.label : isSystemAdmin ? t('user.systemAdmin') : '-'}
                      </Tag>
                      <Tag className="m-0" color={record.isLoggedIn ? 'green' : ''}>
                        {record.isLoggedIn ? t('common.active') : t('common.inActive')}
                      </Tag>
                    </div>
                  </div>
                  <div> {`${record.firstName} ${record.lastName || ''}`} </div>
                  <div className="d-flex flex-column" style={{ gap: '6px' }}>
                    <div className="d-flex justify-between">
                      <span className="text-gray"> {t('users.tableHeaders.phoneNumber')} </span> {record.phoneNumber}
                    </div>
                    <div className="d-flex justify-between">
                      <span className="text-gray"> {t('users.tableHeaders.emailId')} </span> <span> {record.email} </span>
                    </div>
                    <div className="d-flex justify-between">
                      <span className="text-gray"> {t('users.tableHeaders.loginDisabled')} </span>
                      <Tag className="m-0" color={record.isLoginDisabled ? 'red' : 'green'}>
                        {record.isLoginDisabled ? t('common.yes') : t('common.no')}
                      </Tag>
                    </div>
                    <div className="d-flex justify-between">
                      <span className="text-gray"> {t('users.tableHeaders.channelName')} </span>
                      <span> {record.ChannelName || '-'} </span>
                    </div>
                  </div>
                </div>
                <Space className="d-flex justify-between" style={{ marginTop: '10px', gap: '10px' }}>
                  {record.role !== constants.role.SYSTEM_ADMIN && (
                    <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} danger
                      onClick={() => handleDeleteClick(record.ID, `${record.firstName} ${record.lastName || ''}`)}>
                      <span> {t('common.delete')} </span>
                      <MdOutlineDelete />
                    </Button>
                  )}
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost onClick={() => handleEditClick(record.ID)}>
                    <span> {t('common.edit')} </span>
                    <MdOutlineBorderColor />
                  </Button>
                </Space>
              </Card>
            )
          })}
        </div>
      )}
    </>
  )
}

export default UserTableMobile
