import { createSlice } from '@reduxjs/toolkit'
import { userLogout } from '../actions'
import { createLogSnag } from '../thunks/commonThunk'
import { CommonState } from '../../types/redux'

const initialState: CommonState = {
  isMobile: false,
  viewFullMenu: false,
  isFilterEnabled: false,
  toast: {
    show: false,
    content: '',
    type: '',
  },
  elementSize: 'middle',
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    setViewFullMenu: (state, action) => {
      state.viewFullMenu = action.payload
    },
    setEnableFilter: (state, action) => {
      state.isFilterEnabled = action.payload
    },
    setElementSize: (state, action) => {
      state.elementSize = action.payload
    },
    setToast: (state, action) => {
      state.toast = {
        show: action.payload?.show || false,
        content: action.payload?.content || '',
        type: action.payload?.type || 'success',
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(userLogout, () => {
      return {
        isMobile: false,
        viewFullMenu: false,
        isFilterEnabled: false,
        visibleModal: false,
        toast: {
          show: false,
          content: '',
          type: '',
        },
        elementSize: 'middle',
      }
    })
    builder.addCase(createLogSnag.fulfilled, () => {
      // console.log(payload, 'createLogSnag');
    })
  }
})

export const { setIsMobile, setViewFullMenu, setEnableFilter, setToast, setElementSize } = commonSlice.actions
export const commonReducer = commonSlice.reducer
