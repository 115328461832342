import { createAsyncThunk } from '@reduxjs/toolkit'
import CONFIG from '../../config'
import customAxios from '../../utils/axios'
import { handleLogout } from '../../utils'

export const getChitGroupList = createAsyncThunk('GET_CHIT_GROUP_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitGroupList(payload.currentPage, payload.size, payload.name)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getAutocompleteChitGroupList = createAsyncThunk('GET_AUTOCOMPLETE_CHIT_GROUP_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAutocompleteChitGroupList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getAutocompleteChitGroupCustomerList = createAsyncThunk('GET_AUTOCOMPLETE_CHIT_GROUP_CUSTOMER_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAutocompleteChitGroupCustomerList(payload.chitGroupId, payload.keyword)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getChitTakenList = createAsyncThunk('GET_CHIT_TAKEN_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitTakenList(payload.chitGroupId, payload.currentPage, payload.size)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getChitGroupOverview = createAsyncThunk('GET_CHIT_OVERVIEW', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitGroupOverview(payload.chitGroupId)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getChitGroupTimeline = createAsyncThunk('GET_CHIT_TIMELINE', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitGroupTimeline(payload.chitGroupId)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getDefaultChitGroup = createAsyncThunk('GET_DEFAULT_CHIT_GROUP', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitGroup(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createChitGroup = createAsyncThunk('CREATE_CHIT_GROUP', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createChitGroup, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateChitGroup = createAsyncThunk('UPDATE_CHIT_GROUP', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateChitGroup(payload.ID), payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const deleteChitGroup = createAsyncThunk('DELETE_CHIT_GROUP', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.delete(CONFIG.api.deleteChitGroup(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
