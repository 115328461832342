import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { MdOutlineBorderColor } from 'react-icons/md'
import { Breadcrumb, Space, Button, Collapse } from 'antd'
import type { CollapseProps } from 'antd'

import ChitGroupDetails from '../../components/chitGroup/chitDetails'
import ChitCustomer from '../../components/chitGroup/chitCustomer'
import ChitGroupOverview from '../../components/chitGroup/chitGroupOverview'
import ChitTakenListMobile from '../../components/chitGroup/mobile/chitTakenListMobile'
import ChitTakenListDesktop from '../../components/chitGroup/desktop/chitTakenListDesktop'
import ChitGroupTimeLine from '../../components/chitGroup/chitGroupTimeline'
import ChitGroupInfo from '../../components/chitGroup/chitGroupInfo'
import CollectionDetailsByAmount from '../../components/dashboard/CollectionDetails/amount'
import CollectionDetailsByCount from '../../components/dashboard/CollectionDetails/count'
import SimpleModal from '../../components/common/SimpleModal'
import { PageLoader } from '../../components/common/PageLoader'

import { getDefaultChitGroup, createChitGroup, updateChitGroup, getDashboardPaymentGraph, clearDefaultChitGroupData, setToast, createLogSnag } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import config from '../../config'
import { chitPriorityOptions, penaltyOptions, dateOptions, chitStatusOptions } from '../../utils/data'
import { handleFilterValuesFromData, getMonthYearBEFormat, posthogCaptureEvent } from '../../utils'
import constants from '../../constants'
import type { RootState } from '../../redux'


interface SelectedCustomersProps {
  ID?: any
  chitgroupID?: any
  value: any
  label: string
  isActive?: boolean
}

const CreateChitGroup: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { chitGroupId } = useParams()
  const location = useLocation()

  const { isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, chitActionLoading, defaultChitGroup, removedCustomers, success, } = useAppSelector((state: RootState) => state.chitGroup)
  const { paymentTransactionGraphData } = useAppSelector((state: RootState) => state.analytics)
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)
  const { format } = constants

  const [viewChitGroup, setViewChitGroup] = useState<boolean>(false)
  const [chitName, setChitName] = useState<string>('')
  const [chitGroupName, setChitGroupName] = useState<string>('')
  const [status, setStatus] = useState<any>(chitStatusOptions[0])
  const [description, setDescription] = useState<string>('')
  const [tnc, setTnC] = useState<string>('')
  const [auctionDay, setAuctionDay] = useState<any>(dateOptions[0])
  const [isPenaltyApplicable, setIsPenaltyApplicable] = useState<any>(penaltyOptions[0])
  const [chitPriority, setChitPriority] = useState<any>(chitPriorityOptions[0])
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')
  const [amount, setAmount] = useState<string>('')
  const [totalMonths, setTotalMonths] = useState<number>()
  const [frequencyMonths, setFrequencyMonths] = useState<number>(1)
  const [selectedCustomers, setSelectedCustomers] = useState<SelectedCustomersProps[]>([])
  const [isChitActive, setIsChitActive] = useState<boolean>(false)

  const [nameErr, setNameErr] = useState<string>('')
  const [groupNameErr, setGroupNameErr] = useState<string>('')
  const [startDateErr, setStartDateErr] = useState<string>('')
  const [amountErr, setAmountErr] = useState<string>('')
  const [totalMonthsErr, setTotalMonthsErr] = useState<string>('')
  const [selectedCustomersErr, setSelectedCustomersErr] = useState<string>('')
  const [confirmStartChitModal, setConfirmStartChitModal] = useState<boolean>(false)
  const [confirmChitEndedModal, setConfirmChitEndedModal] = useState<boolean>(false)
  const [confirmStartChitModalContent] = useState<any>({
    title: t('confirmStartChitModal.title'),
    desc: t('confirmStartChitModal.description')
  })
  const [confirmChitEndedModalContent] = useState<any>({
    title: t('confirmChitEndedModal.title'),
    desc: t('confirmChitEndedModal.description')
  })

  useEffect(() => {
    if (chitGroupId) {
      dispatch(getDefaultChitGroup(chitGroupId))
      handleViewChitGroup()
    }
  }, [dispatch, chitGroupId])

  useEffect(() => {
    handleSetChitGroupValues()
  }, [defaultChitGroup])

  useEffect(() => {
    success.type === 'Success' && handleNavigateToGroup()
  }, [success])

  useEffect(() => {
    if (startDate && totalMonths) {
      const startDateObj = dayjs(startDate)
      const endDate = startDateObj.add((totalMonths * frequencyMonths) - 1, 'month')
      setEndDate(dayjs(endDate))
    }
  }, [totalMonths, startDate, frequencyMonths])

  const handleNavigateToGroup = () => {
    dispatch(clearDefaultChitGroupData())
    navigate(config.pages.chitGroup)
  }

  const handleSetChitGroupValues = () => {
    const data = defaultChitGroup
    if (Object.keys(data)?.length) {
      setChitName(data.chitName)
      setChitGroupName(data.chitGroupName)
      setDescription(data.description)
      setTnC(data.terms)
      setAuctionDay(handleFilterValuesFromData(data.auctionDay, dateOptions))
      setChitPriority(handleFilterValuesFromData(data.chitPriority, chitPriorityOptions))
      setIsPenaltyApplicable(handleFilterValuesFromData(data.isPenaltyApplicable, penaltyOptions))
      setStartDate(dayjs(data.beginDate, format.DATE_FORMAT))
      setEndDate(dayjs(data.endDate, format.DATE_FORMAT))
      setAmount(data.amount)
      setTotalMonths(data.totalMonths)
      setFrequencyMonths(data.frequency)
      setStatus(handleFilterValuesFromData(data.status, chitStatusOptions))
      setIsChitActive(constants.chitStatus.ACTIVE === data.status)
      setSelectedCustomers(
        data.customers
          ? data.customers.map(({ customerID, ID, chitgroupID, isActive, firstName, lastName }: any) => {
              return {
                value: customerID,
                ID,
                isActive,
                chitgroupID,
                label: firstName
                  ? firstName && lastName
                    ? `${firstName} ${lastName}`
                    : firstName
                  : `<span class="text-red-500">${t('createChitGroup.info.deletedCustomer')}</span>`
              }
            })
          : []
      )
    }
  }

  const handleViewChitGroup = () => {
    setViewChitGroup(location.pathname.includes('view'))
  }

  const getLatestCustomersList = () => {
    const newArr = selectedCustomers.map(({ value, ID, chitgroupID }) => {
      return {
        customerID: value,
        ID,
        chitgroupID: chitgroupID || parseInt(defaultChitGroup.ID, 10),
        isActive: true
      }
    })
    return newArr.concat(removedCustomers)
  }

  const handleStartChit = () => {
    setStatus(chitStatusOptions[1])
    setConfirmStartChitModal(true)
  }

  const handleUpdateChitAsEnded = () => {
    setStatus(chitStatusOptions[2])
    setConfirmChitEndedModal(true)
  }

  const handleCloseConfirmationModal = () => {
    setConfirmStartChitModal(false)
    setConfirmChitEndedModal(false)
  }

  const handleCreateChitGroupSave = async (endedStatus?: string) => {
    setNameErr(chitName.length < 3 ? 'ERROR' : '')
    setGroupNameErr(chitGroupName.length < 3 ? 'ERROR' : '')
    setAmountErr(!amount ? 'ERROR' : '')

    setTotalMonthsErr(!totalMonths ? 'ERROR' : '')
    !startDate && setStartDateErr('ERROR')
    setSelectedCustomersErr(!selectedCustomers.length ? 'ERROR' : '')
    if (chitName.length > 3 && chitGroupName.length > 3 && amount && startDate && selectedCustomers?.length) {
      const payload: any = {
        chitName,
        chitGroupName,
        description,
        totalMonths,
        frequency: frequencyMonths,
        auctionDay: auctionDay.value ? auctionDay.value : auctionDay,
        amount: amount.toString(),
        beginDate: getMonthYearBEFormat(startDate),
        endDate: endDate ? getMonthYearBEFormat(endDate) : '',
        status: endedStatus ? endedStatus : status.value,
        terms: tnc,
        isActive: defaultChitGroup.isActive
      }
      if (chitGroupId) {
        payload['ID'] = parseInt(chitGroupId, 10)
        payload['customers'] = getLatestCustomersList()
        const res: any = await dispatch(updateChitGroup(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.updatedChitSuccess')
          }))
          posthogCaptureEvent('update_action', { type: 'chit_group' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      } else {
        payload['customers'] = selectedCustomers.map(({ value }) => {
          return { customerID: parseInt(value, 10), isActive: true }
        })
        const logSnagPayload = {
          channel: 'chit-fund',
          event: 'New Chit Created',
          description: JSON.stringify(payload)
        }
        const res: any = await dispatch(createChitGroup(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.createdChitSuccess')
          }))
          dispatch(createLogSnag(logSnagPayload))
          posthogCaptureEvent('create_action', { type: 'chit_group' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      }
    }
  }

  const handleEditClick = () => {
    setViewChitGroup(false)
    posthogCaptureEvent('edit_screen', { type: 'chit_group' })
    navigate(`${config.pages.edit_chitGroup}/${chitGroupId}`)
  }

  const handleViewClick = () => {
    setViewChitGroup(true)
    posthogCaptureEvent('view_screen', { type: 'chit_group' })
    navigate(`${config.pages.view_chitGroup}/${chitGroupId}`)
  }

  const handleChangeCollapse = (e: any) => {
    e[0] === '4' && dispatch(getDashboardPaymentGraph(chitGroupId))
  }

  const formatGraphAmountData = () => {
    if (paymentTransactionGraphData.amount?.length) {
      const arr: any = []
      paymentTransactionGraphData.amount.forEach(({ period, amount, unpaidAmount }: any) => {
        const splittedPeriod = period.split('-')
        const shortHandPeriod = `${splittedPeriod[0].slice(0, 3)} - ${splittedPeriod[1].slice(2)}`
        arr.push({
          label: shortHandPeriod,
          value: parseInt(amount),
          type: 'Received'
        })
        arr.push({
          label: shortHandPeriod,
          value: parseInt(unpaidAmount),
          type: 'Not Received'
        })
      })
      return arr
    }

    return paymentTransactionGraphData.amount
  }

  const formatGraphCountData = () => {
    if (paymentTransactionGraphData.count?.length) {
      const arr: any = []
      paymentTransactionGraphData.count.forEach(({ period, count, unpaidCount }: any) => {
        const splittedPeriod = period.split('-')
        const shortHandPeriod = `${splittedPeriod[0].slice(0, 3)} - ${splittedPeriod[1].slice(2)}`
        arr.push({
          label: shortHandPeriod,
          value: count || 0,
          type: 'Paid'
        })
        arr.push({
          label: shortHandPeriod,
          value: unpaidCount || 0,
          type: 'Unpaid'
        })
      })
      return arr
    }

    return paymentTransactionGraphData.count
  }

  const isChitPending = () => {
    return defaultChitGroup?.status === chitStatusOptions[0].value
  }

  const isChitEnded = () => {
    return defaultChitGroup?.status === chitStatusOptions[2].value
  }

  const defaultItems: CollapseProps['items'] = [
    {
      key: '1',
      label: t('createChitGroup.basicDetails'),
      children: (
        <ChitGroupDetails
          isMobile={isMobile}
          viewChitGroup={viewChitGroup}
          isChitActive={isChitActive}
          nameErr={nameErr}
          groupNameErr={groupNameErr}
          startDateErr={startDateErr}
          amountErr={amountErr}
          totalMonthsErr={totalMonthsErr}
          chitName={chitName}
          setChitName={setChitName}
          chitGroupName={chitGroupName}
          setChitGroupName={setChitGroupName}
          description={description}
          setDescription={setDescription}
          tnc={tnc}
          setTnC={setTnC}
          auctionDay={auctionDay}
          setAuctionDay={setAuctionDay}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          amount={amount}
          setAmount={setAmount}
          totalMonths={totalMonths}
          setTotalMonths={setTotalMonths}
          frequencyMonths={frequencyMonths}
          setFrequencyMonths={setFrequencyMonths}
          setNameErr={setNameErr}
          setGroupNameErr={setGroupNameErr}
          setStartDateErr={setStartDateErr}
          setAmountErr={setAmountErr}
          setTotalMonthsErr={setTotalMonthsErr}
        />
      )
    },
    {
      key: '2',
      label: t('createChitGroup.customers'),
      children: (
        <ChitCustomer
          viewChitGroup={viewChitGroup}
          isChitActive={isChitActive}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          selectedCustomersErr={selectedCustomersErr}
          setSelectedCustomersErr={setSelectedCustomersErr}
        />
      )
    }
  ]

  const items: CollapseProps['items'] = [
    ...defaultItems,
    {
      key: '3',
      label: t('chitGroupOverview.title'),
      children: <ChitGroupOverview chitGroupId={chitGroupId} />
    },
    {
      key: '4',
      label: t('createChitGroup.transactionStats'),
      children: (
        formatGraphAmountData()?.length || formatGraphCountData()?.length ?
          <div className="dashboard-report__container p-0">
            {formatGraphAmountData()?.length && <CollectionDetailsByAmount t={t} data={formatGraphAmountData()} />}
            {formatGraphCountData()?.length && <CollectionDetailsByCount t={t} data={formatGraphCountData()} />}
          </div>
        : <p className="m-0 text-center text-gray">{t('common.noTransactions')}</p>
      )
    },
    {
      key: '5',
      label: t('createChitGroup.chitTakenList'),
      children: isMobile ? <ChitTakenListMobile chitGroupId={chitGroupId} /> : <ChitTakenListDesktop chitGroupId={chitGroupId} />
    },
    {
      key: '6',
      label: t('common.timeLine'),
      children: <ChitGroupTimeLine chitGroupId={chitGroupId} />
    },
    {
      key: '7',
      label: t('createChitGroup.info.title'),
      children: <ChitGroupInfo chitInfo={defaultChitGroup} />
    }
  ]

  return (
    <div>
      {isLoading || chitActionLoading ? (
        <PageLoader />
      ) : (
        <div style={{ padding: '10px 0' }}>
          <div className="d-flex justify-between align-center" style={{ margin: '10px 0 30px 0' }}>
            <Breadcrumb
              items={[
                {
                  title: (
                    <div className="c-pointer" onClick={handleNavigateToGroup}>
                      {t('createChitGroup.chitGroups')}
                    </div>
                  )
                },
                { title: <div>{chitName || t('common.createChitGroup')}</div> }
              ]}
            />
            {!isUser && <Space className="d-flex justify-between" style={{ gap: '10px' }} direction={isMobile ? 'vertical' : 'horizontal'}>
              {!isChitEnded() && defaultChitGroup?.ID &&
                (viewChitGroup ? (
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost onClick={handleEditClick}>
                    <span> {t('common.edit')} </span>
                    <MdOutlineBorderColor />
                  </Button>
                ) : (
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} onClick={handleViewClick}>
                    <span> {t('common.view')} </span>
                  </Button>
                )
              )}
              {isChitPending() && <Button size={elementSize} type="primary" onClick={handleStartChit}> {t('common.markAsStart')} </Button>}
              {!isChitEnded() && defaultChitGroup?.ID && !viewChitGroup && (totalMonths === defaultChitGroup?.completedMonths) &&
                <Button size={elementSize} type="primary" onClick={handleUpdateChitAsEnded}> {t('common.markAsEnded')} </Button>
              }
            </Space>}
          </div>
          <Collapse accordion={viewChitGroup} items={viewChitGroup ? items : defaultItems} defaultActiveKey={viewChitGroup ? ['1'] : ['1', '2']} onChange={handleChangeCollapse} />
          {!viewChitGroup && (
            <div className="create-footer">
              <Button size={elementSize} onClick={handleNavigateToGroup} style={{ marginRight: '10px' }}>
                {t('common.cancel')}
              </Button>
              <Button type="primary" size={elementSize} onClick={() => handleCreateChitGroupSave()}>
                {chitGroupId ? t('common.update') : t('common.create')}
              </Button>
            </div>
          )}
          {(confirmChitEndedModal || confirmStartChitModal) && (
            <SimpleModal
              visibleModal={confirmChitEndedModal || confirmStartChitModal}
              data={confirmStartChitModal ? confirmStartChitModalContent : confirmChitEndedModalContent}
              confirmModal={() => handleCreateChitGroupSave(confirmStartChitModal ? chitStatusOptions[1].value : chitStatusOptions[2].value)}
              closeModal={handleCloseConfirmationModal}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default CreateChitGroup
