import { createAsyncThunk } from '@reduxjs/toolkit'
import CONFIG from '../../config'
import customAxios from '../../utils/axios'
import { handleLogout } from '../../utils'

export const getChannelList = createAsyncThunk('GET_CHANNEL_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChannelList(payload.currentPage, payload.size)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getAutocompleteChannelList = createAsyncThunk('GET_AUTOCOMPLETE_CHANNEL_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAutocompleteChannelList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getDefaultChannel = createAsyncThunk('GET_DEFAULT_CHANNEL', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getDefaultChannel(payload.channelId)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createChannel = createAsyncThunk('CREATE_CHANNEL', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createChannel, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateChannel = createAsyncThunk('UPDATE_CHANNEL', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateChannel(payload.ID), payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
