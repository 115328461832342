import { useRouteError, useNavigate, NavigateFunction } from 'react-router-dom'
import BrokenImage from '../../assets/images/404.png'
import config from '../../config'
import configurableData from '../../config/configurable.json'
import { Button } from 'antd'

const BrokenPage: React.FC = () => {
  const error: any = useRouteError()
  const navigate: NavigateFunction = useNavigate()
  console.error(error, 'error')

  const handleNavigateLogin = (): void => {
    navigate(config.pages.login)
  }

  return (
    <div className="broken-page-container" id="error-page">
      <div className="broken-page-content">
        <div className="p-6 text-left font-semibold">
          <span onClick={handleNavigateLogin} className="c-pointer">
            {configurableData.fullName}
          </span>
        </div>
        <div className="broken-image-content">
          <h2 style={{ fontSize: '50px', margin: '10px 0' }}>Page not found</h2>
          <p style={{ color: '#666' }}>{`Sorry, we couldn't find the page you're looking for.`}</p>
          <Button size="large" type="link" style={{ padding: 0 }} onClick={handleNavigateLogin}>
            Go home
          </Button>
        </div>
        <div className="p-6 font-light text-left">{configurableData.shortName} © 2024</div>
      </div>
      <div className="broken-page-img-container">
        <img src={BrokenImage} alt="Broken Image" style={{ height: 'auto', width: '100%' }} />
      </div>
    </div>
  )
}

export default BrokenPage
