import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FilterContainer from '../../components/Filter'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { getChitReport, downloadReport, clearReportData, clearDownloadReportData, updateChitReportPageMetaData } from '../../redux'
import constants from '../../constants'
import { posthogCaptureEvent } from '../../utils'

import { Pagination } from 'antd'
import ChitReportTableMobile from '../../components/chitReports/mobile'
import ChitReportTableDesktop from '../../components/chitReports/desktop'
import type { RootState } from '../../redux'

const ChitReports: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { chitReportList, chitReportPageMetaData, downloadReportData } = useAppSelector((state: RootState) => state.report)
  const { isMobile } = useAppSelector((state) => state.common)
  const [filterValues, setFilterValues] = useState<any>({})

  useEffect(() => {
    dispatch(clearReportData())
  }, [dispatch])

  useEffect(() => {
    downloadReportData?.length && downloadExcel(downloadReportData)
  }, [downloadReportData])

  const downloadExcel = (excelData: string) => {
    try {
      const byteCharacters = atob(excelData)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const excelBlob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(excelBlob)
      const a = document.createElement('a')
      a.href = url
      const currentDate = `${new Date().getDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}`
      a.download = `Chitgroup_Report_${filterValues.chitgroupID}_${currentDate}.xlsx`
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
      document.body.removeChild(a)
      dispatch(clearDownloadReportData())
    } catch (error) {
      console.error('Error decoding base64 data:', error)
    }
  }

  const handleFilterValues = async (filters: any) => {
    setFilterValues(filters)
    posthogCaptureEvent('applied_filters', { type: 'chit_report', value: filters || 'RESET' })
    filters.chitgroupID
      ? dispatch(getChitReport({ chitgroupID: filters.chitgroupID, filters: filters.filters, currentPage: 1, size: constants.PAGE_SIZE }))
      : dispatch(clearReportData())
  }

  const handleChangePagination = (pageNo: number) => {
    posthogCaptureEvent('change_pagination', { type: 'chit_report', pageNo })
    const response = {
      currentPage: pageNo,
      size: chitReportPageMetaData.size,
      totalPages: chitReportPageMetaData.totalPages,
      totalRecords: chitReportPageMetaData.totalRecords
    }
    dispatch(updateChitReportPageMetaData(response))
  }

  const handleTableData = () => {
    const start = (chitReportPageMetaData.currentPage - 1) * 10
    const end = chitReportPageMetaData.currentPage * constants.PAGE_SIZE
    return chitReportList.slice(start, end)
  }

  const handleDownloadReport = () => {
    posthogCaptureEvent('download_report', { type: 'chit_report' })
    const payload = {
      reportName: constants.reportType.CHIT_REPORT,
      filters: `chitgroupid=${filterValues.chitgroupID}&${filterValues.filters}`
    }
    dispatch(downloadReport(payload))
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <FilterContainer
        showFilter={true}
        t={t}
        filterType={constants.filterType.CHIT_REPORT}
        chitGroupPriority={true}
        dataLength={handleTableData()?.length}
        handleDownloadAction={handleDownloadReport}
        handleFilterValues={handleFilterValues}
      />
      {isMobile ? <ChitReportTableMobile data={handleTableData()} /> : <ChitReportTableDesktop data={handleTableData()} />}
      {chitReportPageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ margin: '20px 0' }}
          onChange={handleChangePagination}
          total={chitReportPageMetaData.totalRecords}
          current={chitReportPageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
    </div>
  )
}

export default ChitReports
