import { createAsyncThunk } from '@reduxjs/toolkit'
import CONFIG from '../../config'
import customAxios from '../../utils/axios'
import { handleLogout } from '../../utils'

export const getCustomerList = createAsyncThunk('GET_CUSTOMER_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getCustomerList(payload.currentPage, payload.size, payload.name)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getAutocompleteCustomerList = createAsyncThunk('GET_AUTOCOMPLETE_CUSTOMER_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAutocompleteCustomerList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getFilteredCustomerList = createAsyncThunk('GET_FILTERED_CUSTOMER_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getFilteredCustomerList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getDefaultCustomer = createAsyncThunk('GET_DEFAULT_CUSTOMER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getCustomer(payload.customerId)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createCustomer = createAsyncThunk('CREATE_CUSTOMER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createCustomer, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateCustomer = createAsyncThunk('UPDATE_CUSTOMER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateCustomer(payload.ID), payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const deleteCustomer = createAsyncThunk('DELETE_CUSTOMER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.delete(CONFIG.api.deleteCustomer(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
