import React from 'react'
import '../../../index.css'
import { Spin } from 'antd'

export const PageLoader: React.FC<any> = () => {
  return (
    <div className="page-loading">
      <Spin />
    </div>
  )
}

export const ModalLoader: React.FC<any> = () => {
  return (
    <div className="modal-loading">
      <Spin />
    </div>
  )
}
