import { createAsyncThunk } from '@reduxjs/toolkit'
import customAxios from '../../utils/axios'
import CONFIG from '../../config'
import { handleLogout } from '../../utils'

export const getUserList = createAsyncThunk('GET_USER_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getUserList(payload.currentPage, payload.size)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getAutocompleteUserList = createAsyncThunk('GET_AUTOCOMPLETE_USER_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAutocompleteUserList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getDefaultUser = createAsyncThunk('GET_DEFAULT_USER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getDefaultUser(payload.userId)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createUser = createAsyncThunk('CREATE_USER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createUser, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateUser = createAsyncThunk('UPDATE_USER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateUser(payload.ID), payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const deleteUser = createAsyncThunk('DELETE_USER', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.delete(CONFIG.api.deleteUser(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const setUserChanneId = createAsyncThunk('SET_USER_CHANNEL_ID', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.setUserChanneId(payload.userId, payload.channelId)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
