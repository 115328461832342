import { useTranslation } from 'react-i18next'
import { Card, Tag, Button, Space } from 'antd'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { currencyFormatter, getDate } from '../../../utils'
import { useAppSelector } from '../../../redux/hooks'
import { PageLoader } from '../../common/PageLoader'
import type { RootState } from '../../../redux'

interface ChitGroupTransactionTableMobileProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

const ChitGroupTransactionTableMobile: React.FC<ChitGroupTransactionTableMobileProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { elementSize } = useAppSelector((state: RootState) => state.common)
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, chitTransactionActionLoading, pageMetaData, chitGroupTransactionList } = useAppSelector((state: RootState) => state.chitGroupTransaction)

  return (
    <>
      {isLoading || chitTransactionActionLoading ? (
        <PageLoader />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {chitGroupTransactionList.map((record: any, index: any) => (
            <Card size="small" style={{ width: '100%' }} bordered key={record.collectionTransactionId}>
              <div className="d-flex flex-column" style={{ gap: '8px' }} onClick={() => handleViewClick(record)}>
                <div className="d-flex justify-between align-center">
                  <div>
                    <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.sNo')}: </span> {(pageMetaData.currentPage - 1) * 10 + index + 1}
                  </div>
                  <Tag className="m-0" color="grey">
                    {getDate(record.transactionDate)}
                  </Tag>
                </div>
                <div>
                  <span className="text-gray"> {record.chitGroupName} | </span> {record.chitName}
                </div>
                <div className="d-flex flex-column" style={{ gap: '4px' }}>
                  <div className="d-flex justify-between">
                    <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.monthlyInstallment')} </span>
                    <div className="d-flex align-center" style={{ gap: '4px' }}>
                      <span> {record.installmentNumber} </span>
                    </div>
                  </div>
                  <div className="d-flex justify-between">
                    <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.customerPayableAmount')} </span>
                    <div className="d-flex align-center" style={{ gap: '4px' }}>
                      <span> {currencyFormatter(record.customerPayableAmount)} </span>
                      <span className='gray-divider'> | </span>
                      <span className="white-space-nowrap"> {getDate(record.customerDueDate)} </span>
                    </div>
                  </div>
                  <div className="d-flex justify-between">
                    <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.settlementAmount')} </span>
                    <div className="d-flex align-center" style={{ gap: '4px' }}>
                      <span> {currencyFormatter(record.settlementAmount)} </span>
                      <span className='gray-divider'> | </span>
                      <span> {getDate(record.settlementDueDate)} </span>
                    </div>
                  </div>
                  <div className="d-flex justify-between">
                    <div>
                      <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.dividend')} </span>
                      <span className='gray-divider'> | </span>
                      <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.discount')} </span>
                    </div>
                    <div className="d-flex align-center" style={{ gap: '4px' }}>
                      <span> {currencyFormatter(record.dividendAmount)} </span>
                      <span className='gray-divider'> | </span>
                      <span> {currencyFormatter(record.discountAmount)} </span>
                    </div>
                  </div>
                  <div className="d-flex justify-between">
                    <span className="text-gray"> {t('transactions.chitGroup.tableHeaders.triedUserCount')} </span>
                    <span> {record.triedUserCount || '-'} </span>
                  </div>
                </div>
              </div>
              <Space className={`d-flex ${isSuperAdmin || isSystemAdmin ? 'justify-between' : 'justify-end'}`} style={{ marginTop: '10px', gap: '10px' }}>
                {(isSuperAdmin || isSystemAdmin) && (
                  <Button
                    className="d-flex align-center"
                    style={{ gap: '4px' }}
                    danger
                    size={elementSize}
                    onClick={() => handleDeleteClick(`${record.collectionTransactionId},${record.settlementTransactionId}`, `${record.chitName} - ${record.installmentNumber}`)}
                  >
                    <span> {t('common.delete')} </span> <MdOutlineDelete />
                  </Button>
                )}
                {!isUser && (
                  <Button className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost size={elementSize} onClick={() => handleEditClick(record)}>
                    <span> {t('common.edit')} </span> <MdOutlineBorderColor />
                  </Button>
                )}
              </Space>
            </Card>
          ))}
        </div>
      )}
    </>
  )
}

export default ChitGroupTransactionTableMobile
