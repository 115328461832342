import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  MdOutlineHome,
  MdAccountBalance,
  MdOutlineGroups,
  MdOutlineManageAccounts,
  MdAccountBalanceWallet,
  MdOutlineBarChart,
  MdOutlineNotificationsNone,
  MdOutlineSupportAgent,
  MdOutlineSettings
} from 'react-icons/md'
import { HiUserGroup } from 'react-icons/hi'
import { BiTransferAlt } from 'react-icons/bi'
import { setViewFullMenu, setIsMobile, setToast } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { posthogCaptureEvent } from '../../utils'
import config from '../../config'

import { Layout, Menu, message } from 'antd'
import type { MenuProps } from 'antd'
import type { RootState } from '../../redux'

const { Sider } = Layout
type MenuItem = Required<MenuProps>['items'][number]

const SideBarMenu: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { viewFullMenu, isMobile, toast } = useAppSelector((state) => state.common)
  const { userData, isSystemAdmin, isSuperAdmin, isAdmin } = useAppSelector((state: RootState) => state.auth)
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (toast.show) {
      const toastType: any = toast?.type
      messageApi.open({
        type: toastType,
        content: toast.content
      })
      dispatch(setToast({}))
    }
  }, [toast.show])

  const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
    return { key, icon, children, label } as MenuItem
  }

  const systemAdminMenuOptions: MenuItem[] = [
    getItem(t('menuOptions.dashboard'), config.pages.dashboard, <MdOutlineHome />),
    getItem(t('menuOptions.chitGroup'), config.pages.chitGroup, <MdAccountBalance />),
    getItem(t('menuOptions.customer'), config.pages.customer, <MdOutlineGroups />),
    getItem(t('menuOptions.transaction'), 'sub1', <BiTransferAlt />, [
      getItem(t('menuOptions.chitGroupTransaction'), config.pages.chitGroupTransaction),
      getItem(t('menuOptions.customerTransaction'), config.pages.customerTransaction)
    ]),
    getItem(t('menuOptions.reports'), 'sub2', <MdAccountBalanceWallet />, [
      getItem(t('menuOptions.chitReports'), config.pages.chitReports),
      getItem(t('menuOptions.customerReports'), config.pages.customerReports)
    ]),
    getItem(t('menuOptions.analytics'), config.pages.analytics, <MdOutlineBarChart />),
    getItem(t('menuOptions.notification'), config.pages.notification, <MdOutlineNotificationsNone />),
    getItem(t('menuOptions.user'), config.pages.user, <MdOutlineManageAccounts />),
    getItem(t('menuOptions.channels'), config.pages.channels, <HiUserGroup />),
    getItem(t('menuOptions.support'), config.pages.support, <MdOutlineSupportAgent />),
    getItem(t('menuOptions.settings'), config.pages.settings, <MdOutlineSettings />)
  ]

  const superAdminMenuOptions: MenuItem[] = [
    getItem(t('menuOptions.dashboard'), config.pages.dashboard, <MdOutlineHome />),
    getItem(t('menuOptions.chitGroup'), config.pages.chitGroup, <MdAccountBalance />),
    getItem(t('menuOptions.customer'), config.pages.customer, <MdOutlineGroups />),
    getItem(t('menuOptions.transaction'), 'sub1', <BiTransferAlt />, [
      getItem(t('menuOptions.chitGroupTransaction'), config.pages.chitGroupTransaction),
      getItem(t('menuOptions.customerTransaction'), config.pages.customerTransaction)
    ]),
    getItem(t('menuOptions.reports'), 'sub2', <MdAccountBalanceWallet />, [
      getItem(t('menuOptions.chitReports'), config.pages.chitReports),
      getItem(t('menuOptions.customerReports'), config.pages.customerReports)
    ]),
    getItem(t('menuOptions.analytics'), config.pages.analytics, <MdOutlineBarChart />),
    getItem(t('menuOptions.user'), config.pages.user, <MdOutlineManageAccounts />),
    getItem(t('menuOptions.settings'), config.pages.settings, <MdOutlineSettings />)
  ]

  const adminMenuOptions: MenuItem[] = [
    getItem(t('menuOptions.dashboard'), config.pages.dashboard, <MdOutlineHome />),
    getItem(t('menuOptions.chitGroup'), config.pages.chitGroup, <MdAccountBalance />),
    getItem(t('menuOptions.customer'), config.pages.customer, <MdOutlineGroups />),
    getItem(t('menuOptions.transaction'), 'sub1', <BiTransferAlt />, [
      getItem(t('menuOptions.chitGroupTransaction'), config.pages.chitGroupTransaction),
      getItem(t('menuOptions.customerTransaction'), config.pages.customerTransaction)
    ]),
    getItem(t('menuOptions.reports'), 'sub2', <MdAccountBalanceWallet />, [
      getItem(t('menuOptions.chitReports'), config.pages.chitReports),
      getItem(t('menuOptions.customerReports'), config.pages.customerReports)
    ]),
    getItem(t('menuOptions.analytics'), config.pages.analytics, <MdOutlineBarChart />),
    getItem(t('menuOptions.settings'), config.pages.settings, <MdOutlineSettings />)
  ]

  const userMenuOptions: MenuItem[] = [
    getItem(t('menuOptions.dashboard'), config.pages.dashboard, <MdOutlineHome />),
    getItem(t('menuOptions.chitGroup'), config.pages.chitGroup, <MdAccountBalance />),
    getItem(t('menuOptions.customer'), config.pages.customer, <MdOutlineGroups />),
    getItem(t('menuOptions.transaction'), 'sub1', <BiTransferAlt />, [
      getItem(t('menuOptions.chitGroupTransaction'), config.pages.chitGroupTransaction),
      getItem(t('menuOptions.customerTransaction'), config.pages.customerTransaction)
    ]),
    getItem(t('menuOptions.reports'), 'sub2', <MdAccountBalanceWallet />, [
      getItem(t('menuOptions.chitReports'), config.pages.chitReports),
      getItem(t('menuOptions.customerReports'), config.pages.customerReports)
    ]),
    getItem(t('menuOptions.settings'), config.pages.settings, <MdOutlineSettings />)
  ]

  useEffect(() => {
    dispatch(setIsMobile(window.innerWidth <= 1024))
  }, [])

  const handleRoute: MenuProps['onClick'] = (e) => {
    posthogCaptureEvent('navigatation', { url: e.key })
    navigate(e.key)
    isMobile && dispatch(setViewFullMenu(true))
  }

  const checkPresentRoute = () => {
    return [location.pathname]
  }

  const getRoleBasedMenuOptions = () => {
    return isSystemAdmin ? systemAdminMenuOptions : isSuperAdmin ? superAdminMenuOptions : isAdmin ? adminMenuOptions : userMenuOptions
  }

  return (
    <Sider breakpoint="lg" collapsedWidth="0" collapsible collapsed={viewFullMenu} onCollapse={(value) => dispatch(setViewFullMenu(value))}>
      {contextHolder}
      <h4 style={{ color: '#fff', padding: '0 10px', lineHeight: '25px', textAlign: 'center' }}>{userData?.channelName}</h4>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[config.pages.dashboard]} selectedKeys={checkPresentRoute()} onClick={handleRoute} items={getRoleBasedMenuOptions()} />
    </Sider>
  )
}

export default SideBarMenu
