import { Timeline } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getChitGroupTimeline } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { ModalLoader } from '../../components/common/PageLoader'
import type { RootState } from '../../redux'

interface TimeLineProps {
  chitName?: string
  chitGroupId: any
}

const ChitGroupTimeLine: React.FC<TimeLineProps> = ({ chitGroupId }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { chitTakenListLoading, chitGroupTimeline } = useAppSelector((state: RootState) => state.chitGroup)

  useEffect(() => {
    dispatch(getChitGroupTimeline({ chitGroupId }))
  }, [dispatch])

  const getFormattedTimeLineItems = () => {
    let headerItems: { color: string; children: string }[] = [],
      installmentItems = [],
      finalItem: { color: string; children: string }[] = []
    if (chitGroupTimeline?.transactionDates) {
      headerItems = [
        {
          color: 'blue',
          children: t('chitGroupTimeline.created', { created: chitGroupTimeline.createdDate })
        },
        {
          color: 'blue',
          children: t('chitGroupTimeline.begin', { begin: chitGroupTimeline.beginDate })
        }
      ]
      installmentItems = chitGroupTimeline?.transactionDates?.map(({ transactionDate, installmentNumber }: any) => {
        return {
          color: 'green',
          children: t('chitGroupTimeline.completedInstallment', { installmentNumber, transactionDate })
        }
      })

      if (chitGroupTimeline?.endDate) {
        finalItem = [
          {
            color: 'blue',
            children: t('chitGroupTimeline.ended', { ended: chitGroupTimeline.endDate })
          }
        ]
      } else {
        finalItem = [
          {
            color: 'gray',
            children: t('chitGroupTimeline.upcomingInstallment', {
              installmentNumber: chitGroupTimeline?.transactionDates[chitGroupTimeline?.transactionDates.length - 1]?.installmentNumber + 1
            })
          }
        ]
      }
    }
    return [...headerItems, ...installmentItems, ...finalItem]
  }

  return (
    <div>
      {chitTakenListLoading ? (
        <ModalLoader />
      ) : (
        <>
          {getFormattedTimeLineItems()?.length ? (
            <>
              <Timeline items={getFormattedTimeLineItems()} />

              <ul style={{ padding: '0 0 0 15px', margin: 0 }}>
                <li style={{ color: 'green' }}>{t('chitGroupTimeline.info.completedInstallment')}</li>
                <li style={{ color: 'gray' }}>{t('chitGroupTimeline.info.upcomingInstallment')}</li>
                <li style={{ color: 'blue' }}>{t('chitGroupTimeline.info.otherInfo')}</li>
              </ul>
            </>
          ) : (
            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{t('chitGroupTimeline.chitNotStarted')}</span>
          )}
        </>
      )}
    </div>
  )
}

export default ChitGroupTimeLine
