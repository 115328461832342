import posthog from 'posthog-js'
import AppRoute from './router'

if (!window.location.host.includes('localhost')) {
  posthog.init('phc_JMSJYxDfIKiO4Duw503H3oJ0dDir3e8HgXg3PTUe2qE', { api_host: 'https://us.i.posthog.com' })
}

const App: React.FC = () => {
  return <AppRoute />
}

export default App
