import { useState, useEffect, useRef } from 'react'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import DeleteModal from '../../components/common/SimpleModal/delete'
import FilterContainer from '../../components/Filter'
import { getChitGroupList, deleteChitGroup, clearDefaultChitGroupData, setToast, updateOnboardingTourData } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import constants from '../../constants'
import { posthogCaptureEvent } from '../../utils'

import { Pagination, Tour } from 'antd'
import ChitGroupTableMobile from '../../components/chitGroup/mobile'
import ChitGroupTableDesktop from '../../components/chitGroup/desktop'
import type { RootState } from '../../redux'
import { DeleteModalState } from '../../types/common'
import userConfigConstants, { OnboardingTourKeys } from '../../constants/userConfig'
import type { TourProps } from 'antd'

const ChitGroup: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { t } = useTranslation()
  const [deleteModal, setDeteteModal] = useState<DeleteModalState>({
    type: '',
    visible: false,
    id: '',
    name: '',
    title: '',
    desc: ''
  })
  const [filterValues, setFilterValues] = useState<string>('')
  const { isMobile } = useAppSelector((state) => state.common)
  const { onboardingTourData } = useAppSelector((state: RootState) => state.userConfig)
  const { chitActionLoading, pageMetaData, success } = useAppSelector((state: RootState) => state.chitGroup)

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const [tourStatus, setTourStatus] = useState(false)

  useEffect(() => {
    dispatch(getChitGroupList({ currentPage: 1, size: constants.PAGE_SIZE, name: '' }))
  }, [dispatch])

  useEffect(() => {
    if (deleteModal.id && success.type === 'Success') {
      dispatch(setToast({
        show: true,
        content: t('common.info.deletedChitSuccess')
      }))
      dispatch(getChitGroupList({ currentPage: 1, size: constants.PAGE_SIZE, name: filterValues }))
      handleCloseDeleteModal()
    }
  }, [success])

  useEffect(() => {
    const key: OnboardingTourKeys = userConfigConstants.onboarding.CHIT_GROUP_LIST
    setTourStatus(onboardingTourData[key])
  }, [onboardingTourData])

  const steps: TourProps['steps'] = [
    {
      title: t('tour.chitGroup.createTitle'),
      description: t('tour.chitGroup.createDesc'),
      target: () => ref1.current
    },
    {
      title: t('tour.chitGroup.listTitle'),
      description: t('tour.chitGroup.listDesc'),
      target: () => ref2.current
    },
    {
      title: t('tour.chitGroup.paginationTitle'),
      description: t('tour.chitGroup.paginationDesc'),
      target: () => ref3.current
    }
  ]

  const handleNavigateToNewGroup = () => {
    dispatch(clearDefaultChitGroupData())
    navigate(config.pages.create_chitGroup)
  }

  const handleViewClick = (value: any) => {
    posthogCaptureEvent('view_screen', { type: 'chit_group' })
    navigate(`${config.pages.view_chitGroup}/${value}`)
  }

  const handleEditClick = (value: any) => {
    posthogCaptureEvent('edit_screen', { type: 'chit_group' })
    navigate(`${config.pages.edit_chitGroup}/${value}`)
  }

  const handleDeleteClick = (value: any, name: string) => {
    posthogCaptureEvent('delete_screen', { type: 'chit_group' })
    setDeteteModal({
      type: constants.DELETE,
      visible: true,
      id: value,
      name,
      title: t(`deleteModal.title.chitGroup`),
      desc: t('chitGroup.deleteModalBody')
    })
  }

  const handleDeleteConfirmModal = (id: any) => {
    posthogCaptureEvent('delete_action_confirmation', { type: 'chit_group', id })
    dispatch(deleteChitGroup(id))
  }

  const handleCloseDeleteModal = () => {
    posthogCaptureEvent('delete_action_cancel', { type: 'chit_group' })
    setDeteteModal({ type: '', visible: false, id: '', name: '', title: '', desc: '' })
  }

  const handleFilterValues = async (name: string) => {
    posthogCaptureEvent('applied_filters', { type: 'chit_group', value: name || 'RESET' })
    setFilterValues(name)
    dispatch(getChitGroupList({ currentPage: 1, size: constants.PAGE_SIZE, name }))
  }

  const handleChangePagination = (pageNo: number) => {
    posthogCaptureEvent('change_pagination', { type: 'chit_group', pageNo })
    dispatch(getChitGroupList({ currentPage: pageNo, size: constants.PAGE_SIZE, name: filterValues }))
  }

  const handleCloseOnboardingTour = () => {
    setTourStatus(false)
    const payload = {
      value: false,
      name: userConfigConstants.onboarding.CHIT_GROUP_LIST
    }
    dispatch(updateOnboardingTourData(payload))
  }

  return (
    <div>
      <FilterContainer
        showFilter={!!filterValues.length || pageMetaData.totalRecords > 10}
        t={t}
        actionRef={ref1}
        filterType={constants.filterType.CHIT}
        showChitGroupOnly={true}
        showSearch={true}
        actionName={t('chitGroup.createCTA')}
        handleCreateAction={handleNavigateToNewGroup}
        handleFilterValues={handleFilterValues}
      />
      <div ref={ref2}>
        {isMobile ? <ChitGroupTableMobile handleViewClick={handleViewClick} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />
          : <ChitGroupTableDesktop handleViewClick={handleViewClick} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />}
      </div>
      <div className="d-flex justify-end align-center" style={{ margin: '20px 0' }}>
        <div ref={ref3}>
          <Pagination
            onChange={handleChangePagination}
            total={pageMetaData.totalRecords}
            current={pageMetaData.currentPage}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            showSizeChanger={false}
            showQuickJumper={false}
            simple={isMobile}
          />
        </div> 
      </div>
      <DeleteModal visibleModal={deleteModal.visible} data={deleteModal} loading={chitActionLoading} confirmModal={handleDeleteConfirmModal} closeModal={handleCloseDeleteModal} />
      <Tour open={tourStatus} onClose={handleCloseOnboardingTour} steps={steps} />
    </div>
  )
}

export default ChitGroup
