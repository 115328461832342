import { useTranslation } from 'react-i18next'
import { getDateWithTime } from '../../utils'
import { useAppSelector } from '../../redux/hooks'
import type { RootState } from '../../redux'

import { Card, Tag } from 'antd'

interface CustomerInfoProps {
  customerInfo: any
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({ customerInfo }) => {
  const { t } = useTranslation()
  const { isSystemAdmin } = useAppSelector((state: RootState) => state.auth)

  return (
    <Card size="small" title={t('createCustomer.info.title')}>
      <div className="flex flex-col p-3 mb-0">
        {isSystemAdmin && (
          <div className="d-flex mb-10">
            <label style={{ marginRight: '10px' }}>{t('createCustomer.info.customerId')} :</label>
            <p className="m-0">{customerInfo.ID}</p>
          </div>
        )}
        <div className="d-flex mb-10">
          <label style={{ marginRight: '10px' }}>{t('createCustomer.info.created')} :</label>
          <p className="m-0">{getDateWithTime(customerInfo.createdAt)}</p>
        </div>
        <div className="d-flex mb-10">
          <label style={{ marginRight: '10px' }}>{t('createCustomer.info.updated')} :</label>
          <p className="m-0">{getDateWithTime(customerInfo.updatedAt)}</p>
        </div>
        <div className="d-flex">
          <label style={{ marginRight: '10px' }}>{t('createCustomer.info.active')} :</label>
          <Tag color={customerInfo.isActive ? 'green' : ''}>{t(`common.${customerInfo.isActive ? 'yes' : 'no'}`)}</Tag>
        </div>
      </div>
    </Card>
  )
}

export default CustomerInfo
