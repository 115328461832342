import { useTranslation } from 'react-i18next'
import { Card, Tag, Button, Space } from 'antd'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { currencyFormatter, handleFilterValuesFromData, getDate } from '../../../utils'
import { paymentMethodOptions } from '../../../utils/data'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'
import { PageLoader } from '../../common/PageLoader'

interface CustomerTransactionTableMobileProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

const CustomerTransactionTableMobile: React.FC<CustomerTransactionTableMobileProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { elementSize } = useAppSelector((state: RootState) => state.common)
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, customerTransactionActionLoading, customerTransactionList, pageMetaData } = useAppSelector((state: RootState) => state.customerTransaction)

  return (
    <>
      {isLoading || customerTransactionActionLoading ? (
        <PageLoader />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {customerTransactionList.map((record: any, index: any) => {
            const paymentMethodValue = handleFilterValuesFromData(record.paymentMethod, paymentMethodOptions)
            return (
              <Card size="small" style={{ width: '100%' }} bordered key={record.id}>
                <div className="d-flex flex-column" style={{ gap: '8px' }} onClick={() => handleViewClick(record)}>
                  <div className="d-flex justify-between align-center">
                    <div>
                      <span className="text-gray"> {t('transactions.customers.tableHeaders.sNo')}: </span> {(pageMetaData.currentPage - 1) * 10 + index + 1}
                    </div>
                    <div className="d-flex" style={{ gap: '6px' }}>
                      {record.isPartialPayment && (
                        <Tag className="m-0" color="orange">
                          {t('transactions.customers.tableHeaders.partialPayment')}
                        </Tag>
                      )}
                      <Tag className="m-0" color="grey">
                        {getDate(record.transactionDate)}
                      </Tag>
                    </div>
                  </div>
                  <div>
                    {' '}
                    <span className="text-gray"> {record.chitName} | </span> {record.firstName} {record.lastName || ''}{' '}
                  </div>
                  <div className="d-flex flex-column" style={{ gap: '4px' }}>
                    <div className="d-flex justify-between">
                      <div>
                        <span className="text-gray"> {t('chitTakenList.tableHeaders.installment')} </span>
                        <span> | </span>
                        <span className="text-gray"> {t('transactions.customers.tableHeaders.payableAmount')} </span>
                      </div>
                      <div className="d-flex align-center" style={{ gap: '4px' }}>
                        <span> {record.installmentNumber} </span>
                        <span> | </span>
                        <span> {currencyFormatter(record.payableAmount)} </span>
                      </div>
                    </div>
                    <div className="d-flex justify-between">
                      <span className="text-gray"> {t('transactions.customers.tableHeaders.amount')} </span>
                      <span> {currencyFormatter(record.amount)} </span>
                    </div>
                    {record.isPartialPayment && (
                      <div className="d-flex justify-between">
                        <span className="text-gray"> {t('transactions.customers.tableHeaders.balanceAmount')} </span>
                        <span> {currencyFormatter(record.balanceAmount)} </span>
                      </div>
                    )}
                  </div>
                </div>
                {!isUser && (
                  <Space className="d-flex justify-between" style={{ marginTop: '10px', gap: '10px' }}>
                    <div className="d-flex" style={{ gap: '6px' }}>
                      <Tag className="m-0" color={record.isPaymentToCustomer ? 'blue' : 'green'}>
                        {record.isPaymentToCustomer ? t('common.debited') : t('common.credited')}
                      </Tag>
                      <Tag className="m-0">{paymentMethodValue?.label || '-'}</Tag>
                    </div>
                    <Space>
                      {(isSuperAdmin || isSystemAdmin) && (
                        <Button
                          className="d-flex align-center"
                          style={{ gap: '4px' }}
                          danger
                          size={elementSize}
                          onClick={() => handleDeleteClick(record.id, `${record.firstName} ${record.lastName || ''} - ${record.chitName} - ${record.installmentNumber}`)}
                        >
                          <span> {t('common.delete')} </span> <MdOutlineDelete />
                        </Button>
                      )}
                      {!isUser && (
                        <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost onClick={() => handleEditClick(record)}>
                          <span> {t('common.edit')} </span> <MdOutlineBorderColor />
                        </Button>
                      )}
                    </Space>
                  </Space>
                )}
              </Card>
            )
          })}
        </div>
      )}
    </>
  )
}

export default CustomerTransactionTableMobile
