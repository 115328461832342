import { useTranslation } from 'react-i18next'
import { Card, Tag } from 'antd'
import { getDateWithTime } from '../../utils'
import { useAppSelector } from '../../redux/hooks'
import type { RootState } from '../../redux'

interface UserInfoProps {
  userInfo: any
}

const UserInfo: React.FC<UserInfoProps> = ({ userInfo }) => {
  const { t } = useTranslation()
  const { isSystemAdmin } = useAppSelector((state: RootState) => state.auth)

  return (
    <Card size="small" title={t('createCustomer.info.title')}>
      <div className="input-container mb-0">
        {isSystemAdmin && (
          <div className="d-flex" style={{ marginBottom: '5px' }}>
            <label style={{ marginRight: '10px' }}>{t('createUser.userId')}:</label>
            <p className="m-0">{userInfo.ID}</p>
          </div>
        )}
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createUser.created')}:</label>
          <p className="m-0">{getDateWithTime(userInfo.createdAt)}</p>
        </div>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createUser.updated')}:</label>
          <p className="m-0">{getDateWithTime(userInfo.updatedAt)}</p>
        </div>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createUser.lastLoginTime')}:</label>
          <p className="m-0">{getDateWithTime(userInfo.lastLoginTime)}</p>
        </div>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createUser.active')}:</label>
          <Tag color={userInfo.isActive ? 'green' : ''}>{t(`common.${userInfo.isActive ? 'yes' : 'no'}`)}</Tag>
        </div>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createUser.loggedIn')}:</label>
          <Tag color={userInfo.isLoggedIn ? 'green' : ''}>{t(`common.${userInfo.isLoggedIn ? 'yes' : 'no'}`)}</Tag>
        </div>
      </div>
    </Card>
  )
}

export default UserInfo
