import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Input, Button, Modal, Select, Checkbox, DatePicker, InputNumber, Spin, } from 'antd'
import {
  getChitGroupTransactionList,
  getAutocompleteChitGroupList,
  getAutocompleteChitGroupCustomerList,
  createCustomerTransaction,
  updateCustomerTransaction,
  getAllCustomerTransactionList,
  clearSuccessErrorData,
  clearDefaultCustomerTransactionData,
  createLogSnag,
  setToast,
} from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { paymentMethodOptions } from '../../utils/data'
import { handleFilterValuesFromData, getBEFormat, getTodayDate, disabledAfterTodayDate, posthogCaptureEvent } from '../../utils'
import constants from '../../constants'
import { ModalLoader } from '../../components/common/PageLoader'
import useDebounce from '../../hooks/useDebounce'
import type { RootState } from '../../redux'

const { TextArea } = Input

interface ModalProps {
  isView: boolean
  visibleModal: boolean
  closeModal: any
  currentPage: number
  filters: string
}

const CreateTransactionModal: React.FC<ModalProps> = ({ isView, visibleModal, closeModal, currentPage, filters }) => {
  const [customer, setCustomer] = useState<any>()
  const [chitGroup, setChitGroup] = useState<any>()
  const [installment, setInstallment] = useState<any>()
  const [filteredInstallment, setFilteredInstallment] = useState<any>()
  const [isPaymentToCustomer, setIsPaymentToCustomer] = useState<boolean>(false)
  const [isPartialPayment, setIsPartialPayment] = useState<boolean>(false)
  const [amount, setAmount] = useState<any>('')
  const [balanceAmount, setBalanceAmount] = useState<number>(0)
  const [paymentMethod, setPaymentMethod] = useState<any>(paymentMethodOptions[0])
  const [remarks, setRemarks] = useState<string>('')
  const [transactionDate, setTransactionDate] = useState<any>('')
  const [createAnotherCustomerTransaction, setCreateAnotherCustomerTransaction] = useState<boolean>(false)
  const [chitGroupCustomerSearchTerm, setChitGroupCustomerSearchTerm] = useState<string>('')
  const debouncedChitGroupCustomerSearchTerm = useDebounce<string>(chitGroupCustomerSearchTerm, 500)
  const [chitGroupSearchTerm, setChitGroupSearchTerm] = useState<string>('')
  const debouncedChitGroupSearchTerm = useDebounce<string>(chitGroupSearchTerm, 500)

  const [showErrMsg, setShowErrMsg] = useState<boolean>(false)
  const { PAGE_SIZE, format } = constants

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { chitAutoCompleteGroupLoading, filteredChitGroupList, chitAutoCompleteGroupCustomerLoading, filteredChitGroupCustomerList } = useAppSelector(
    (state: RootState) => state.chitGroup
  )
  const { isLoading: chitGroupTransactionLoading, chitGroupTransactionList } = useAppSelector((state: RootState) => state.chitGroupTransaction)
  const { isLoading, customerTransactionActionLoading, defaultCustomerTransaction, success, error } = useAppSelector((state: RootState) => state.customerTransaction)
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)

  useEffect(() => {
    setShowErrMsg(false)
  }, [visibleModal])

  useEffect(() => {
    handleSetChitGroupTransactionValues()
  }, [defaultCustomerTransaction])

  useEffect(() => {
    if (defaultCustomerTransaction?.id) {
      setInstallment(getCurrentInstallment())
      getFilterInstallmentTransaction(defaultCustomerTransaction.installmentNumber)
    } else {
      const options = getDueDropDownValues()
      setInstallment(options[0])
      getFilterInstallmentTransaction(options[0]?.value)
    }
  }, [chitGroupTransactionList])

  useEffect(() => {
    if (success.type === 'Success') {
      dispatch(getAllCustomerTransactionList({ currentPage, size: PAGE_SIZE, filters }))
      dispatch(clearDefaultCustomerTransactionData())
      dispatch(clearSuccessErrorData())
      createAnotherCustomerTransaction ? handleResetValues() : closeModal()
    }
  }, [success])

  useEffect(() => {
    if (error?.errorType) {
      dispatch(setToast({
        show: true,
        type: 'error',
        content: constructErrorMessage()
      }))
      dispatch(clearSuccessErrorData())
    }
  }, [error])

  useEffect(() => {
    if (debouncedChitGroupSearchTerm) {
      dispatch(getAutocompleteChitGroupList(`name=${debouncedChitGroupSearchTerm}&filters=status eq 'ACTIVE'`))
    }
  }, [debouncedChitGroupSearchTerm])

  useEffect(() => {
    if (debouncedChitGroupCustomerSearchTerm) {
      const payload = {
        chitGroupId: chitGroup?.value || chitGroup,
        keyword: debouncedChitGroupCustomerSearchTerm
      }
      dispatch(getAutocompleteChitGroupCustomerList(payload))
    }
  }, [debouncedChitGroupCustomerSearchTerm])

  const constructErrorMessage = () => {
    return (parseInt(error?.params[1]) > 0) ? t(`errMsg.AMOUNT_{0}_IS_GREATER_THAN_BALANCE_AMOUNT_{1}`, { 1: error.params[0], 2: error.params[1] })
      : t(`errMsg.CUSTOMER_DUPLICATE_TRANSACTION`)
  }

  const handleFilterChitTransaction = (value: string) => {
    dispatch(getChitGroupTransactionList(value))
  }

  const handleSetChitGroupTransactionValues = async () => {
    const data = defaultCustomerTransaction
    if (Object.keys(data).length) {
      handleFilterChitTransaction(data.chitgroupID)
      setCustomer({
        label: `${data.firstName} ${data.lastName || ''}`,
        value: data.customerID
      })
      setChitGroup({
        label: data.chitName,
        value: data.chitgroupID
      })
      setAmount(data.amount)
      setIsPartialPayment(data.isPartialPayment)
      setBalanceAmount(data.balanceAmount)
      setPaymentMethod(handleFilterValuesFromData(data.paymentMethod, paymentMethodOptions))
      setRemarks(data.remarks)
      setTransactionDate(dayjs(data.transactionDate, format.DATE_FORMAT))
      setIsPaymentToCustomer(data.isPaymentToCustomer)
    } else {
      handleResetValues()
    }
  }

  const handleDisableUpdateButton = () => {
    const data = defaultCustomerTransaction
    return (
      (customer?.value || customer) !== data.customerID ||
      parseInt(amount) !== parseInt(data.amount) ||
      isPaymentToCustomer !== data.isPaymentToCustomer ||
      isPartialPayment !== data.isPartialPayment ||
      remarks !== data.remarks ||
      (paymentMethod?.value || paymentMethod) !== data.paymentMethod ||
      getBEFormat(transactionDate) !== data.transactionDate
    )
  }

  const handleResetValues = () => {
    setCustomer('')
    setChitGroup('')
    setInstallment(0)
    setAmount('')
    setIsPartialPayment(false)
    setBalanceAmount(0)
    setPaymentMethod(paymentMethodOptions[0])
    setRemarks('')
    setFilteredInstallment({})
    setTransactionDate(getTodayDate())
    setIsPaymentToCustomer(false)
    setShowErrMsg(false)
    !createAnotherCustomerTransaction && setCreateAnotherCustomerTransaction(false)
  }

  const getDueDropDownValues = () => {
    if (chitGroup && chitGroupTransactionList?.length) {
      return chitGroupTransactionList.map(({ installmentNumber }: any) => {
        return {
          label: installmentNumber,
          value: installmentNumber
        }
      })
    }
    return []
  }

  const getCurrentInstallment = () => {
    const options = getDueDropDownValues()
    if (options.length) {
      const currentInstallment: any = options.filter(({ value }: any) => value === defaultCustomerTransaction?.installmentNumber)
      return currentInstallment[0]
    }
  }

  const checkDisableSubmit = () => {
    return (
      customer === '' ||
      chitGroup === '' ||
      installment === '' ||
      !amount ||
      balanceAmount < 0 ||
      transactionDate === '' ||
      (filteredInstallment && amount > (isPaymentToCustomer ? filteredInstallment.settlementAmount : filteredInstallment.customerPayableAmount))
    )
  }

  const getFilterInstallmentTransaction = (option: any) => {
    const filtered = chitGroupTransactionList?.filter(({ installmentNumber }: any) => installmentNumber === option)[0]
    setFilteredInstallment(filtered)
    !defaultCustomerTransaction?.id && setAmount(filtered?.customerPayableAmount)
  }

  const handleCreateTransaction = async () => {
    if (checkDisableSubmit()) {
      setShowErrMsg(true)
    } else {
      const payload: any = {
        id: parseInt(defaultCustomerTransaction.id, 10),
        customerID: customer.value ? customer.value : customer,
        amount: amount?.toString(),
        balanceAmount: balanceAmount ? balanceAmount?.toString() : '0',
        installmentNumber: installment.value ? installment.value : installment,
        isPartialPayment,
        isPaymentToCustomer,
        remarks: remarks ? remarks.trim() : '',
        isActive: true,
        paymentMethod: paymentMethod.value ? paymentMethod.value : paymentMethod,
        transactionDate: getBEFormat(transactionDate)
      }
      if (defaultCustomerTransaction.id) {
        payload['chitgroupTransactionID'] = isPaymentToCustomer ? filteredInstallment.settlementTransactionId : filteredInstallment.collectionTransactionId
        const res: any = await dispatch(updateCustomerTransaction(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.updatedCustomerTransactionSuccess')
          }))
          posthogCaptureEvent('update_action', { type: 'customer_transaction' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      } else {
        payload['chitgroupTransactionID'] = isPaymentToCustomer ? filteredInstallment.settlementTransactionId : filteredInstallment.collectionTransactionId
        const logSnagPayload = {
          channel: 'transactions',
          event: 'New Customer Tansaction Created',
          description: JSON.stringify(payload)
        }
        const res: any = await dispatch(createCustomerTransaction(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(createLogSnag(logSnagPayload))
          dispatch(setToast({
            show: true,
            content: t('common.info.createdCustomerTransactionSuccess'),
          }))
          posthogCaptureEvent('create_action', { type: 'customer_transaction' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      }
    }
  }

  const handleNavigateToCustomerTransactions = () => {
    dispatch(clearDefaultCustomerTransactionData())
    handleResetValues()
    closeModal()
  }

  const getCustomerOptions = () => {
    return filteredChitGroupCustomerList.map((custom: { firstName: string; lastName: string; customerId: string }) => ({
      label: `${custom.firstName} ${custom.lastName || ''}`,
      value: custom.customerId
    }))
  }

  const getChitGroupOptions = () => {
    return filteredChitGroupList.map((custom: { chitName: string; ID: string }) => ({ label: custom.chitName, value: custom.ID }))
  }

  const modalStyle = {
    body: {
      padding: '10px 0 20px 0'
    }
  }

  return (
    <Modal
      open={visibleModal}
      title={`${isView ? '' : defaultCustomerTransaction.id ? t('common.edit') : t('common.create')} ${t('createCustomerTransaction.title')}`}
      onOk={handleCreateTransaction}
      onCancel={handleNavigateToCustomerTransactions}
      maskClosable={false}
      style={{ top: isMobile ? 20 : undefined }}
      styles={modalStyle}
      width={1000}
      footer={
        isView
          ? [
              <Button size={elementSize} key="back" onClick={handleNavigateToCustomerTransactions}>
                {isView ? t('common.close') : t('common.cancel')}
              </Button>
            ]
          : [
              <div className="modal-footer" key="createNewOne">
                <div className="input-container m-0 align-items-start">
                  <div>
                    <Checkbox checked={createAnotherCustomerTransaction} onChange={(e) => setCreateAnotherCustomerTransaction(e.target.checked)}>
                      {t('common.createOneMoreTransaction')}
                    </Checkbox>
                  </div>
                </div>
                <div key="action-btn's" className="modal-footer-action">
                  <Button size={elementSize} key="back" disabled={customerTransactionActionLoading} onClick={handleNavigateToCustomerTransactions}>
                    {isView ? t('common.close') : t('common.cancel')}
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    size={elementSize}
                    loading={customerTransactionActionLoading}
                    onClick={handleCreateTransaction}
                    disabled={defaultCustomerTransaction.id ? !handleDisableUpdateButton() : false}
                  >
                    {defaultCustomerTransaction.id ? t('common.update') : t('common.create')}
                  </Button>
                </div>
              </div>
            ]
      }
    >
      {chitGroupTransactionLoading || isLoading || customerTransactionActionLoading ? (
        <ModalLoader />
      ) : (
        <div className="modal-split">
          <div className="w-100">
            <div className="input-container">
              <label className="input-label">
                {t('createCustomerTransaction.chitName')}
                <span className="input-mandatory">*</span>
              </label>
              <Select
                disabled={isView || defaultCustomerTransaction.id}
                value={chitGroup || null}
                showSearch
                filterOption={false}
                onSearch={e => setChitGroupSearchTerm(e)}
                placeholder={t('createCustomerTransaction.placeholder.chitName')}
                onChange={(option: any) => {
                  setChitGroup(option)
                  handleFilterChitTransaction(option)
                }}
                notFoundContent={chitAutoCompleteGroupLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getChitGroupOptions()}
                status={showErrMsg && !(chitGroup || chitGroup?.value) ? 'error' : ''}
                className="select-width"
              />
              {showErrMsg && !(chitGroup || chitGroup?.value) && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createCustomerTransaction.customerName')}
                <span className="input-mandatory">*</span>
              </label>
              <Select
                disabled={!chitGroup || !installment || isView}
                showSearch
                value={customer || null}
                placeholder={t('createCustomerTransaction.placeholder.customerName')}
                filterOption={false}
                onSearch={e => setChitGroupCustomerSearchTerm(e)}
                onChange={(option: any) => setCustomer(option)}
                notFoundContent={
                  chitAutoCompleteGroupCustomerLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>
                }
                options={getCustomerOptions()}
                status={showErrMsg && !(customer || customer?.value) ? 'error' : ''}
                className="select-width"
              />
              {showErrMsg && !(customer || customer?.value) && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <div className="d-flex" style={{ gap: '10px' }}>
                <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
                  <label className="input-label">
                    {t('createCustomerTransaction.installment')}
                    <span className="input-mandatory">*</span>
                  </label>
                  <Select
                    disabled={!chitGroup || isView || defaultCustomerTransaction.id}
                    value={installment || null}
                    placeholder={t('createCustomerTransaction.placeholder.installment')}
                    options={getDueDropDownValues()}
                    onChange={(option) => {
                      setInstallment(option)
                      getFilterInstallmentTransaction(option)
                    }}
                    status={showErrMsg && !(installment || installment?.value) ? 'error' : ''}
                  />
                </div>
                <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
                  <label className="input-label">{t('createCustomerTransaction.dividend')}</label>
                  <InputNumber
                    addonBefore="₹"
                    value={filteredInstallment?.discountAmount || '-'}
                    disabled={true}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                  />
                </div>
              </div>
              {showErrMsg && !(installment || installment?.value) && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <div className="d-flex" style={{ gap: '10px' }}>
                <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
                  <label className="input-label">{t('createCustomerTransaction.paybaleAmount')}</label>
                  <InputNumber
                    addonBefore="₹"
                    value={filteredInstallment?.customerPayableAmount || '-'}
                    disabled={true}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                  />
                </div>
                <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
                  <label className="input-label">{t('createCustomerTransaction.settlementAmount')}</label>
                  <InputNumber
                    addonBefore="₹"
                    value={filteredInstallment?.settlementAmount || '-'}
                    disabled={true}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                  />
                </div>
              </div>
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createCustomerTransaction.paymentMethod')}
                <span className="input-mandatory">*</span>
              </label>
              <Select value={paymentMethod} options={paymentMethodOptions} disabled={isView} onChange={(option) => setPaymentMethod(option)} />
            </div>
          </div>
          <div className="w-100">
            <div className="input-container">
              <div>
                <Checkbox
                  checked={isPaymentToCustomer}
                  disabled={isView}
                  onChange={(e) => {
                    setIsPaymentToCustomer(e.target.checked)
                    setBalanceAmount(0)
                    setAmount(e.target.checked ? filteredInstallment.settlementAmount : filteredInstallment.customerPayableAmount)
                  }}
                >
                  {t('createCustomerTransaction.creditToCustomer')}
                </Checkbox>
              </div>
            </div>
            <div className="input-container">
              <div>
                <Checkbox
                  checked={isPartialPayment}
                  disabled={isView}
                  onChange={(e) => {
                    setIsPartialPayment(e.target.checked)
                    setBalanceAmount(
                      e.target.checked ? (isPaymentToCustomer ? filteredInstallment.settlementAmount - amount : filteredInstallment.customerPayableAmount - amount) : 0
                    )
                  }}
                >
                  {t('createCustomerTransaction.partialPayment')}
                </Checkbox>
              </div>
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createCustomerTransaction.amount')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                addonBefore="₹"
                status={showErrMsg && !amount ? 'error' : ''}
                value={amount}
                onChange={(value) => {
                  setAmount(value)
                  setBalanceAmount(isPartialPayment ? (isPaymentToCustomer ? filteredInstallment.settlementAmount - value : filteredInstallment.customerPayableAmount - value) : 0)
                }}
                readOnly={isView}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={constants.length.CURRENCY}
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('createCustomerTransaction.placeholder.amount')}
              />
              {showErrMsg && !amount && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
              {filteredInstallment && amount > (isPaymentToCustomer ? filteredInstallment.settlementAmount : filteredInstallment.customerPayableAmount) && (
                <p className="input-err-msg">
                  {t('errMsg.chitAmount', {
                    amount: `${isPaymentToCustomer ? filteredInstallment.settlementAmount : filteredInstallment.customerPayableAmount}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')
                  })}
                </p>
              )}
            </div>
            {isPartialPayment && (
              <div className="input-container">
                <label className="input-label">
                  {t('createCustomerTransaction.balanceAmount')}
                  <span className="input-mandatory">*</span>
                </label>
                <InputNumber
                  addonBefore="₹"
                  value={balanceAmount}
                  readOnly={true}
                  style={{ width: '100%' }}
                  formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                />
              </div>
            )}
            <div className="input-container">
              <label className="input-label">
                {t('createCustomerTransaction.transactionDate')}
                <span className="input-mandatory">*</span>
              </label>
              <DatePicker
                allowClear={false}
                status={showErrMsg && transactionDate === '' ? 'error' : ''}
                value={transactionDate}
                disabledDate={disabledAfterTodayDate}
                onChange={(date) => setTransactionDate(date)}
                placeholder={t('createCustomerTransaction.placeholder.transactionDate')}
                disabled={isView}
              />
              {showErrMsg && transactionDate === '' && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="flex flex-col mb-8">
              <label className="input-label">{t('createCustomerTransaction.remarks')}</label>
              <TextArea
                rows={4}
                placeholder={t('createCustomerTransaction.placeholder.remarks')}
                style={{ resize: 'none' }}
                allowClear={!isView}
                readOnly={isView}
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CreateTransactionModal
