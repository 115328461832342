import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getChannelList, getAutocompleteChannelList, getDefaultChannel, createChannel, updateChannel } from '../thunks/channel'
import { userLogout } from '../actions'
import { ChannelState } from '../../types/redux'

const initialState: ChannelState = {
  isLoading: false,
  channelAutoCompleteLoading: false,
  filteredChannelList: [],
  channelActionLoading: false,
  channelList: [],
  pageMetaData: {},
  defaultChannel: {},
  success: {},
  error: {}
}

export const channelSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearDefaultChannelData: (state) => {
      state.defaultChannel = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChannelList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChannelList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.channelList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getChannelList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getAutocompleteChannelList.pending, (state) => {
      state.channelAutoCompleteLoading = true
    })
    builder.addCase(getAutocompleteChannelList.fulfilled, (state, { payload }) => {
      state.channelAutoCompleteLoading = false
      state.success = {}
      state.filteredChannelList = payload.data.data
    })
    builder.addCase(getAutocompleteChannelList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.channelAutoCompleteLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDefaultChannel.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDefaultChannel.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.defaultChannel = payload.data.data
      state.success = {}
    })
    builder.addCase(getDefaultChannel.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createChannel.pending, (state) => {
      state.channelActionLoading = true
    })
    builder.addCase(createChannel.fulfilled, (state, { payload }) => {
      state.channelActionLoading = false
      state.success = payload.data
    })
    builder.addCase(createChannel.rejected, (state, { payload }: PayloadAction<any>) => {
      state.channelActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateChannel.pending, (state) => {
      state.channelActionLoading = true
    })
    builder.addCase(updateChannel.fulfilled, (state, { payload }) => {
      state.channelActionLoading = false
      state.success = payload.data
    })
    builder.addCase(updateChannel.rejected, (state, { payload }: PayloadAction<any>) => {
      state.channelActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        channelAutoCompleteLoading: false,
        filteredChannelList: [],
        channelActionLoading: false,
        channelList: [],
        pageMetaData: {},
        defaultChannel: {},
        success: {},
        error: ''
      }
    })
  }
})

export const { clearDefaultChannelData } = channelSlice.actions

export const channelReducer = channelSlice.reducer
