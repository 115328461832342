import { useTranslation } from 'react-i18next'
import { Space, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { currencyFormatter, getDate } from '../../../utils'
import { useAppSelector } from '../../../redux/hooks'
import { chitStatusOptions } from '../../../utils/data'
import constants from '../../../constants'
import type { RootState } from '../../../redux'

interface ChitGroupTableDesktopProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

interface DataType {
  ID: number
  chitGroupName: string
  chitName: string
  amount: number
  totalMonths: number
  beginDate: Date
  pendingMonths: number
  endDate: Date
  status: string
}

const ChitGroupTableDesktop: React.FC<ChitGroupTableDesktopProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, chitActionLoading, chitGroupList, pageMetaData } = useAppSelector((state: RootState) => state.chitGroup)

  const columns: ColumnsType<DataType> = [
    {
      title: t('chitGroup.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('chitGroup.tableHeaders.group'),
      dataIndex: 'chitGroupName',
      key: 'chitGroupName'
    },
    {
      title: t('chitGroup.tableHeaders.name'),
      dataIndex: 'chitName',
      key: 'chitName'
    },
    {
      title: t('chitGroup.tableHeaders.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <div>{currencyFormatter(amount)}</div>
    },
    {
      title: t('chitGroup.tableHeaders.totalMonths'),
      dataIndex: 'totalMonths',
      key: 'totalMonths'
    },
    {
      title: t('chitGroup.tableHeaders.startingDate'),
      dataIndex: 'beginDate',
      key: 'beginDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('chitGroup.tableHeaders.pendingMonths'),
      dataIndex: 'pendingMonths',
      key: 'pendingMonths',
      render: (value) => <div>{value || '-'}</div>
    },
    {
      title: t('chitGroup.tableHeaders.endingDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('chitGroup.tableHeaders.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => <Tag color={status === constants.chitStatus.ACTIVE ? 'green' : status === constants.chitStatus.ENDED ? 'grey' : 'orange'}>{status}</Tag>
    },
    {
      title: t('chitGroup.tableHeaders.action'),
      key: 'action',
      render: (_, record) => (
        <Space>
          {!isUser && (record?.status !== chitStatusOptions[2].value) && <MdOutlineBorderColor onClick={() => handleEditClick(record.ID)} className="c-pointer" />}
          {(isSuperAdmin || isSystemAdmin) && <MdOutlineDelete onClick={() => handleDeleteClick(record.ID, record.chitName)} className="c-pointer" />}
        </Space>
      )
    }
  ]

  return (
    <>
      <Table
        size="middle"
        rowKey={(record: { ID: number }) => record.ID}
        loading={isLoading || chitActionLoading}
        columns={columns as any}
        dataSource={chitGroupList}
        pagination={false}
        scroll={{ x: 1300 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewClick(record.ID)
          }
        }}
      />
    </>
  )
}

export default ChitGroupTableDesktop
