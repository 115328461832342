import { useTranslation } from 'react-i18next'
import { allowOnlyNumbers, emailValidation, phoneNoValidation } from '../../utils'
import constants from '../../constants'

import { Input, InputNumber, Checkbox, Radio, Space } from 'antd'
const { TextArea } = Input

interface CustomerInfoProps {
  viewCustomer: boolean
  firstName: string
  lastName: string
  phoneNumber: string
  whatsappNumber: string
  isWhatsappNumber: boolean
  email: string
  address: string
  gender: string
  homeBranch: string
  description: string

  setFirstName: any
  setLastName: any
  setPhoneNumber: any
  setIsWhatsappNumber: any
  setWhatsappNumber: any
  setEmail: any
  setAddress: any
  setGender: any
  setHomeBranch: any
  setDescription: any

  firstNameErr: string
  phoneNumberErr: string
  whatsappNoErr: string
  emailErr: string

  setFirstNameErr: any
  setPhoneNumberErr: any
  setWhatsappNoErr: any
  setEmailErr: any
}

const CustomerDetails: React.FC<CustomerInfoProps> = ({
  viewCustomer,
  firstName,
  lastName,
  phoneNumber,
  isWhatsappNumber,
  whatsappNumber,
  email,
  address,
  gender,
  homeBranch,
  description,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setIsWhatsappNumber,
  setWhatsappNumber,
  setEmail,
  setAddress,
  setGender,
  setHomeBranch,
  setDescription,
  firstNameErr,
  phoneNumberErr,
  whatsappNoErr,
  emailErr,
  setFirstNameErr,
  setPhoneNumberErr,
  setWhatsappNoErr,
  setEmailErr
}) => {
  const { t } = useTranslation()

  const handlePhoneNumber = (value: any) => {
    if (allowOnlyNumbers(value)) {
      setPhoneNumber(value)
      setPhoneNumberErr('')
    }
  }

  const handleWhatsappNumber = (value: any) => {
    if (allowOnlyNumbers(value)) {
      setWhatsappNumber(value)
      setWhatsappNoErr('')
    }
  }

  const handlePhoneNoValidation = () => {
    setPhoneNumberErr(!phoneNoValidation(phoneNumber))
  }

  const handleWhatsappNoValidation = () => {
    setWhatsappNoErr(!phoneNoValidation(whatsappNumber))
  }

  const handleEmailValidation = () => {
    email !== '' && setEmailErr(!emailValidation(email))
  }

  return (
    <div className="create-page-container">
      <div className="create-page-section">
        <div className="input-container">
          <label className="input-label">
            {t('createCustomer.firstName')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={firstNameErr ? 'error' : ''}
            readOnly={viewCustomer}
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value)
              setFirstNameErr('')
            }}
            placeholder={t('createCustomer.placeholder.firstName')}
          />
          {firstNameErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomer.lastName')}</label>
          <Input
            allowClear={true}
            className={'base-input-style'}
            readOnly={viewCustomer}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t('createCustomer.placeholder.lastName')}
          />
        </div>
        <div className="input-container">
          <label className="input-label">
            {t('createCustomer.phoneNumber')}
            <span className="input-mandatory">*</span>
          </label>
          <InputNumber
            controls={false}
            status={phoneNumberErr ? 'error' : ''}
            readOnly={viewCustomer}
            value={phoneNumber}
            onBlur={handlePhoneNoValidation}
            onChange={handlePhoneNumber}
            maxLength={constants.length.PHONE_NUMBER}
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            style={{ width: '100%' }}
            placeholder={t('createCustomer.placeholder.phoneNumber')}
          />
          {phoneNumberErr && <p className="input-err-msg">{t('errMsg.phoneNo')}</p>}
        </div>
        <div className="input-container">
          <div>
            <Checkbox checked={isWhatsappNumber} disabled={viewCustomer} onChange={(e) => setIsWhatsappNumber(e.target.checked)}>
              {t('createCustomer.whatsAppEnabled')}
            </Checkbox>
          </div>
        </div>
        {!isWhatsappNumber && (
          <div className="input-container">
            <label className="input-label">
              {t('createCustomer.whatsAppNumber')}
              <span className="input-mandatory">*</span>
            </label>
            <InputNumber
              controls={false}

              status={whatsappNoErr ? 'error' : ''}
              readOnly={viewCustomer}
              value={whatsappNumber}
              onBlur={handleWhatsappNoValidation}
              onChange={handleWhatsappNumber}
              maxLength={constants.length.PHONE_NUMBER}
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              style={{ width: '100%' }}
              placeholder={t('createCustomer.placeholder.whatsAppNumber')}
            />
            {whatsappNoErr && <p className="input-err-msg">{t('errMsg.phoneNo')}</p>}
          </div>
        )}
        <div className="input-container">
          <label className="input-label">{t('createCustomer.email')}</label>
          <Input
            allowClear={true}
            className={'base-input-style'}
            readOnly={viewCustomer}
            value={email}
            onBlur={handleEmailValidation}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            placeholder={t('createCustomer.placeholder.email')}
          />
          {emailErr && <p className="input-err-msg">{t('errMsg.email')}</p>}
        </div>
      </div>
      <div className="create-page-section">
        <div className="input-container">
          <label className="input-label">{t('createCustomer.gender')}</label>
          <Radio.Group onChange={(e) => setGender(e.target.value)} value={gender} disabled={viewCustomer}>
            <Space>
              <Radio value="M">{t('createCustomer.male')}</Radio>
              <Radio value="F">{t('createCustomer.female')}</Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomer.homeBranch')}</label>
          <Input
            className={'base-input-style'}
            readOnly={viewCustomer}
            value={homeBranch}
            onChange={(e) => {
              setHomeBranch(e.target.value)
            }}
            placeholder={t('createCustomer.placeholder.homeBranch')}
          />
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomer.address')}</label>
          <TextArea
            rows={4}
            style={{ resize: 'none' }}
            allowClear={!viewCustomer}
            readOnly={viewCustomer}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder={t('createCustomer.placeholder.address')}
          />
        </div>
        <div className="input-container">
          <label className="input-label">{t('createCustomer.description')}</label>
          <TextArea
            rows={4}
            style={{ resize: 'none' }}
            allowClear={!viewCustomer}
            readOnly={viewCustomer}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('createCustomer.placeholder.description')}
          />
        </div>
      </div>
    </div>
  )
}

export default CustomerDetails
