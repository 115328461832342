import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { userLogin, getRefreshToken, userForgotPassword, userResetPassword } from '../thunks/authThunk'
import { userLogout } from '../actions'
import constants from '../../constants'
import { AuthState } from '../../types/redux'

const initialState: AuthState = {
  isLoading: false,
  token: '',
  userData: {
    name: '',
    email: '',
    role: '',
    userId: '',
    channelName: ''
  },
  expiresAt: 0,
  isSystemAdmin: false,
  isSuperAdmin: false,
  isAdmin: false,
  isUser: false,
  isForgotPasswordMailSent: false,
  isResetPassword: false,
  success: {},
  error: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUserData: (state, action) => {
      const data = action.payload
      state.userData = {
        name: `${data.FirstName} ${data.LastName || ''}`,
        email: data.Email,
        role: data.Role,
        userId: data.UserId,
        channelName: data.ChannelName
      }
      state.expiresAt = data.exp
      const role = data.Role
      state.isSystemAdmin = role === constants.role.SYSTEM_ADMIN
      state.isSuperAdmin = role === constants.role.SUPERADMIN
      state.isAdmin = role === constants.role.ADMIN
      state.isUser = role === constants.role.USER
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false
    })
    builder.addCase(userLogin.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false
      state.error = action.payload.response.data.error
    })
    builder.addCase(getRefreshToken.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getRefreshToken.fulfilled, (state, { payload }) => {
      window.localStorage.removeItem('jwt')
      window.localStorage.setItem('jwt', payload?.data?.data || '')
      state.token = payload?.data?.data
      state.isLoading = false
    })
    builder.addCase(getRefreshToken.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false
      state.error = action.payload.response.data.error
    })
    builder.addCase(userForgotPassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(userForgotPassword.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.isForgotPasswordMailSent = payload.data.type === 'Success'
      state.success = payload.data
    })
    builder.addCase(userForgotPassword.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userResetPassword.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(userResetPassword.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.isResetPassword = payload.data.type === 'Success'
      state.success = payload.data
    })
    builder.addCase(userResetPassword.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload.response.data.error.message
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        token: '',
        userData: {
          name: '',
          email: '',
          role: '',
          userId: '',
          channelName: ''
        },
        expiresAt: 0,
        isSystemAdmin: false,
        isSuperAdmin: false,
        isAdmin: false,
        isUser: false,
        isForgotPasswordMailSent: false,
        isResetPassword: false,
        success: {},
        error: ''
      }
    })
  }
})

export const { setToken, setUserData } = authSlice.actions
export const authReducer = authSlice.reducer
