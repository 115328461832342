import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  getAllChitGroupTransactionList,
  getChitGroupTransactionList,
  getDefaultChitGroupTransaction,
  createChitGroupTransaction,
  updateChitGroupTransaction,
  deleteChitGroupTransaction
} from '../thunks/chitGroupTransaction'
import { userLogout } from '../actions'
import { ChitGroupTransactionState } from '../../types/redux'

const initialState: ChitGroupTransactionState = {
  isLoading: false,
  chitTransactionActionLoading: false,
  chitGroupTransactionList: [],
  pageMetaData: {},
  defaultChitGroupTransaction: {},
  success: {},
  error: {}
}

export const chitGroupTransactionSlice = createSlice({
  name: 'chitGroupTransaction',
  initialState,
  reducers: {
    clearDefaultChitGroupTransactionData: (state) => {
      state.defaultChitGroupTransaction = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllChitGroupTransactionList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllChitGroupTransactionList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.chitGroupTransactionList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getAllChitGroupTransactionList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getChitGroupTransactionList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChitGroupTransactionList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.chitGroupTransactionList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getChitGroupTransactionList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDefaultChitGroupTransaction.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDefaultChitGroupTransaction.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.defaultChitGroupTransaction = payload.data.data
    })
    builder.addCase(getDefaultChitGroupTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createChitGroupTransaction.pending, (state) => {
      state.chitTransactionActionLoading = true
    })
    builder.addCase(createChitGroupTransaction.fulfilled, (state, { payload }) => {
      state.chitTransactionActionLoading = false
      state.success = payload.data
    })
    builder.addCase(createChitGroupTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitTransactionActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateChitGroupTransaction.pending, (state) => {
      state.chitTransactionActionLoading = true
    })
    builder.addCase(updateChitGroupTransaction.fulfilled, (state, { payload }) => {
      state.chitTransactionActionLoading = false
      state.success = payload.data
    })
    builder.addCase(updateChitGroupTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitTransactionActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(deleteChitGroupTransaction.pending, (state) => {
      state.chitTransactionActionLoading = true
    })
    builder.addCase(deleteChitGroupTransaction.fulfilled, (state, { payload }) => {
      state.chitTransactionActionLoading = false
      state.success = payload.data
    })
    builder.addCase(deleteChitGroupTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitTransactionActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        chitTransactionActionLoading: false,
        chitGroupTransactionList: [],
        pageMetaData: {},
        defaultChitGroupTransaction: {},
        success: {},
        error: {}
      }
    })
  }
})

export const { clearDefaultChitGroupTransactionData } = chitGroupTransactionSlice.actions

export const chitGroupTransactionReducer = chitGroupTransactionSlice.reducer
