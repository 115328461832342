import { useTranslation } from 'react-i18next'
import { getDateWithTime } from '../../utils'

import { Card } from 'antd'

interface ChannelInfoProps {
  channelInfo: any
}

const ChannelInfo: React.FC<ChannelInfoProps> = ({ channelInfo }) => {
  const { t } = useTranslation()
  return (
    <Card size="small" title={t('createCustomer.info.title')}>
      <div className="input-container mb-0">
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createChannel.channelId')}:</label>
          <p className="m-0">{channelInfo.ID}</p>
        </div>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createChannel.created')}:</label>
          <p className="m-0">{getDateWithTime(channelInfo.createdAt)}</p>
        </div>
        <div className="d-flex" style={{ marginBottom: '5px' }}>
          <label style={{ marginRight: '10px' }}>{t('createChannel.updated')}:</label>
          <p className="m-0">{getDateWithTime(channelInfo.updatedAt)}</p>
        </div>
      </div>
    </Card>
  )
}

export default ChannelInfo
