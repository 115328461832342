import { createAsyncThunk } from '@reduxjs/toolkit'
import CONFIG from '../../config'
import customAxios from '../../utils/axios'
import { handleLogout } from '../../utils'

export const getAllCustomerTransactionList = createAsyncThunk('GET_ALL_CUSTOMER_TRANSACTION_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAllCustomerTransactionList(payload.currentPage, payload.size, payload.filters)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getCustomerTransactionList = createAsyncThunk('GET_CUSTOMER_TRANSACTION_LIST', async (payload: BigInteger, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getCustomerTransactionList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getDefaultCustomerTransaction = createAsyncThunk('GET_DEFAULT_CUSTOMER_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getDefaulCustomerTransaction(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createCustomerTransaction = createAsyncThunk('CREATE_CUSTOMER_TRANSACTION', async (payload: object, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createCustomerTransaction, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateCustomerTransaction = createAsyncThunk('UPDATE_CUSTOMER_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateCustomerTransaction(payload.id), payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const deleteCustomerTransaction = createAsyncThunk('DELETE_CUSTOMER_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.delete(CONFIG.api.deleteCustomerTransaction(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
