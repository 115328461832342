import { useTranslation } from 'react-i18next'
import { Card, Tag, Button, Space } from 'antd'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { currencyFormatter, getDate } from '../../../utils'
import { useAppSelector } from '../../../redux/hooks'
import constants from '../../../constants'
import { chitStatusOptions } from '../../../utils/data'
import { PageLoader } from '../../common/PageLoader'
import type { RootState } from '../../../redux'

interface ChitGroupTableMobileProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

const ChitGroupTableMobile: React.FC<ChitGroupTableMobileProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { elementSize } = useAppSelector((state: RootState) => state.common)
  const { isLoading, chitActionLoading, chitGroupList, pageMetaData } = useAppSelector((state: RootState) => state.chitGroup)

  return (
    <>
      {isLoading || chitActionLoading ? (
        <PageLoader />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {chitGroupList.map(({ ID, chitGroupName, chitName, amount, beginDate, totalMonths, pendingMonths, endDate, status }: any, index: any) => (
            <Card size="small" style={{ width: '100%' }} bordered key={ID}>
              <div className="d-flex flex-column" style={{ gap: '6px' }} onClick={() => handleViewClick(ID)}>
                <div className="d-flex justify-between align-center">
                  <div>
                    {' '}
                    <span className="text-gray"> {t('chitGroup.tableHeaders.sNo')}: </span> {(pageMetaData.currentPage - 1) * 10 + index + 1}{' '}
                  </div>
                  <Tag className="m-0" color={status === constants.chitStatus.ACTIVE ? 'green' : status === constants.chitStatus.ENDED ? 'grey' : 'orange'}>
                    {status}
                  </Tag>
                </div>
                <div>
                  {' '}
                  <span className="text-gray"> {chitGroupName} | </span> {chitName}{' '}
                </div>
                <div className="d-flex flex-column" style={{ gap: '6px' }}>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('chitGroup.tableHeaders.amount')} </span> {currencyFormatter(amount)}{' '}
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('chitGroup.tableHeaders.totalMonths')} </span> <span> {totalMonths} </span>{' '}
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('chitGroup.tableHeaders.pendingMonths')} </span> <span> {pendingMonths || '-'} </span>{' '}
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('chitGroup.tableHeaders.startingDate')} </span> <span> {getDate(beginDate)} </span>{' '}
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('chitGroup.tableHeaders.endingDate')} </span> <span> {getDate(endDate)} </span>{' '}
                  </div>
                </div>
              </div>
              <Space className="d-flex justify-between" style={{ marginTop: '10px', gap: '10px' }}>
                {(isSuperAdmin || isSystemAdmin) && (
                  <Button className="d-flex align-center" style={{ gap: '4px' }} danger size={elementSize} onClick={() => handleDeleteClick(ID, chitName)}>
                    <span> {t('common.delete')} </span>
                    <MdOutlineDelete />
                  </Button>
                )}
                {!isUser && (status !== chitStatusOptions[2].value) && (
                  <Button className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost size={elementSize} onClick={() => handleEditClick(ID)}>
                    <span> {t('common.edit')} </span>
                    <MdOutlineBorderColor />
                  </Button>
                )}
              </Space>
            </Card>
          ))}
        </div>
      )}
    </>
  )
}

export default ChitGroupTableMobile
