import { useTranslation } from 'react-i18next'
import { Space, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { useAppSelector } from '../../../redux/hooks'
import { roleOptions } from '../../../utils/data'
import { handleFilterValuesFromData } from '../../../utils'
import constants from '../../../constants'
import type { RootState } from '../../../redux'

interface UserTableDesktopProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
}

interface DataType {
  ID: number
  firstName: string
  lastName: string
  ChannelName: string
  email: string
  phoneNumber: number
  role: string
  isLoggedIn: boolean
  isLoginDisabled: boolean
}

const UserTableDesktop: React.FC<UserTableDesktopProps> = ({ handleViewClick, handleEditClick, handleDeleteClick }) => {
  const { t } = useTranslation()
  const { isSystemAdmin } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, userActionLoading, userList, pageMetaData } = useAppSelector((state: RootState) => state.user)

  const columns: ColumnsType<DataType> = [
    {
      title: t('users.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('users.tableHeaders.name'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (value, item) => <> {value} {item?.lastName || ''} </>
    },
    {
      title: t('users.tableHeaders.emailId'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('users.tableHeaders.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: t('users.tableHeaders.role'),
      dataIndex: 'role',
      key: 'role',
      render: (value) => {
        const roleValue = handleFilterValuesFromData(value, roleOptions)
        return <div>{roleValue ? roleValue.label : isSystemAdmin ? t('user.systemAdmin') : '-'}</div>
      }
    },
    {
      title: t('users.tableHeaders.loggedIn'),
      dataIndex: 'isLoggedIn',
      key: 'isLoggedIn',
      render: (isLoggedIn) => <Tag color={isLoggedIn ? 'green' : ''}>{isLoggedIn ? t('common.yes') : t('common.no')}</Tag>
    },
    {
      title: t('users.tableHeaders.loginDisabled'),
      dataIndex: 'isLoginDisabled',
      key: 'isLoginDisabled',
      render: (isLoginDisabled) => <Tag color={isLoginDisabled ? 'red' : 'green'}>{isLoginDisabled ? t('common.yes') : t('common.no')}</Tag>
    },
    {
      title: t('users.tableHeaders.action'),
      key: 'action',
      render: (_, record) => (
        <Space>
          <MdOutlineBorderColor onClick={() => handleEditClick(record.ID)} className="c-pointer" />
          {record.role !== constants.role.SYSTEM_ADMIN && 
            <MdOutlineDelete onClick={() => handleDeleteClick(record.ID, `${record.firstName} ${record?.lastName || ''}`)} className="c-pointer" />}
        </Space>
      )
    }
  ]

  const channelNameColumns: ColumnsType<DataType> = [
    ...columns,
    {
      title: t('users.tableHeaders.channelName'),
      dataIndex: 'ChannelName',
      key: 'ChannelName',
      render: (value) => <>{value || '-'}</>
    },
  ]

  return (
    <>
      <Table
        size="middle"
        rowKey={(record: { ID: number }) => record.ID}
        loading={isLoading || userActionLoading}
        columns={isSystemAdmin ? channelNameColumns : columns as any}
        dataSource={userList}
        pagination={false}
        scroll={{ x: 1300 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewClick(record.ID)
          }
        }}
      />
    </>
  )
}

export default UserTableDesktop
