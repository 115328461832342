import React from 'react'
import { Bar } from '@ant-design/plots'

interface PaymentMethodChartDataProps {
  t: any
  data: any
}

const PaymentMethodChart: React.FC<PaymentMethodChartDataProps> = ({ t, data }) => {
  const formattedData = [
    {
      type: 'Cash',
      value: data?.CASH
    },
    {
      type: 'G Pay',
      value: data?.GPAY
    },
    {
      type: 'Internet Banking',
      value: data?.INTERNET_BANKING
    },
    {
      type: 'Phonepe',
      value: data?.PHONEPE
    },
    {
      type: 'Less',
      value: data?.LESS
    }
  ]

  const config: any = {
    data: formattedData,
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    legend: {
      position: 'top-left'
    }
  }

  return (
    <div>
      <p className="text-center">{t('analytics.paymentMethodChartTitle')}</p>
      <Bar {...config} />
    </div>
  )
}

export default PaymentMethodChart
