import { createAsyncThunk } from '@reduxjs/toolkit'
import CONFIG from '../../config'
import customAxios from '../../utils/axios'
import { handleLogout } from '../../utils'

export const getChitReport = createAsyncThunk('GET_CHIT_REPORT', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitReport(payload.chitgroupID, payload.filters, payload.currentPage, payload.size)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getCustomerReport = createAsyncThunk('GET_CUSTOMER_REPORT', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getCustomerReport(payload.customerID, payload.filters, payload.currentPage, payload.size)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const downloadReport = createAsyncThunk('DOWNLOAD_REPORT', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.downloadReport(payload.reportName, payload.filters)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
