import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card } from 'antd'
import { PageLoader } from '../../components/common/PageLoader'
import { getPublicCustomerInfo } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import PublicCustomerChitTransactionList from '../../components/publicCustomer/publicCustomerChitTransactionList'
import { posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

const PublicCustomer: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { customerId }: any = useParams()
  const { isLoading, publicCustomerInfo } = useAppSelector((state: RootState) => state.publicCustomer)

  useEffect(() => {
    dispatch(getPublicCustomerInfo(customerId))
    posthogCaptureEvent('load_public_customer', { type: 'public_customer', customerId })
  }, [dispatch])

  return (
    <div style={{ maxWidth: '420px', margin: '0 auto', padding: '20px' }}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <div>
          <h4 style={{ textAlign: 'center' }}>{publicCustomerInfo.channelName}</h4>
          <div className='d-flex flex-column gap-2'>
            <Card size="small" title={t('publicCustomer.info.title')}>
              <div className="flex flex-col p-3 mb-0">
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.customerName')}</label>
                  <p className="m-0">
                    {publicCustomerInfo.firstName} {publicCustomerInfo.lastName || ''}
                  </p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.activeChitsCount')}</label>
                  <p className="m-0">{publicCustomerInfo.activeChitsCount || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.totalParticipatedCount')}</label>
                  <p className="m-0">{publicCustomerInfo.participatedChits || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.pendingTransactionsCount')}</label>
                  <p className="m-0" style={{
                    fontWeight: publicCustomerInfo.pendingTransactionsCount ? 'bolder' : '', 
                    color: (publicCustomerInfo.pendingTransactionsCount > 1) ? 'red' : (publicCustomerInfo.pendingTransactionsCount === 1) ? '#ff9966' : 'grey'
                  }}>
                    {publicCustomerInfo.pendingTransactionsCount || '0'}
                  </p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.phoneNo')}</label>
                  <p className="m-0">{publicCustomerInfo.phoneNumber || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.emailId')}</label>
                  <p className="m-0">{publicCustomerInfo.email || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.info.accountCreatedDate')}</label>
                  <p className="m-0">{publicCustomerInfo.accountCreatedDate || '-'}</p>
                </div>
              </div>
            </Card>
            <Card size="small" title={t('publicCustomer.bankDetails.title')}>
              <div className="flex flex-col p-3 mb-0">
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.bankDetails.bankName')}</label>
                  <p className="m-0">{publicCustomerInfo.bankName || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.bankDetails.accountNumber')}</label>
                  <p className="m-0">{publicCustomerInfo.accountNumber || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.bankDetails.ifscCode')}</label>
                  <p className="m-0">{publicCustomerInfo.ifscCode || '-'}</p>
                </div>
                <div className="d-flex justify-between mb-10">
                  <label>{t('publicCustomer.bankDetails.accountHolderName')}</label>
                  <p className="m-0">{publicCustomerInfo.accountHolderName || '-'}</p>
                </div>
              </div>
            </Card>
            <PublicCustomerChitTransactionList customerId={publicCustomerInfo.ID} chitOptions={publicCustomerInfo.chitgroupInfo} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PublicCustomer
