import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormatter, getDate, handleFilterValuesFromData } from '../../../utils'
import { getChitTakenList } from '../../../redux'
import { paymentMethodOptions } from '../../../utils/data'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import constants from '../../../constants'

import { Table, Pagination } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { RootState } from '../../../redux'

interface DataType {
  ID: number
  firstName: string
  lastName: string
  installment: number
  amount: number
  chitDate: Date
  disbursementDate: Date
  paymentMethod: string
  triedUserCount: number
}

interface ChitTakenListProps {
  chitName?: string
  chitGroupId: any
}

const ChitTakenListDesktop: React.FC<ChitTakenListProps> = ({ chitGroupId }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isMobile } = useAppSelector((state) => state.common)
  const { chitTakenListLoading, chitGroupTakenList, pageMetaData } = useAppSelector((state: RootState) => state.chitGroup)

  useEffect(() => {
    dispatch(getChitTakenList({ chitGroupId, currentPage: 1, size: constants.PAGE_SIZE }))
  }, [dispatch])

  const columns: ColumnsType<DataType> = [
    {
      title: t('common.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('chitTakenList.tableHeaders.name'),
      dataIndex: 'firstName',
      key: 'firstName',
      render: (value, item) => (
        <>
          {value} {item.lastName || ''}
        </>
      )
    },
    {
      title: t('chitTakenList.tableHeaders.installment'),
      dataIndex: 'installmentNumber',
      key: 'installmentNumber'
    },
    {
      title: t('chitTakenList.tableHeaders.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <div>{currencyFormatter(amount)}</div>
    },
    {
      title: t('chitTakenList.tableHeaders.chitDate'),
      dataIndex: 'chitDate',
      key: 'chitDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('chitTakenList.tableHeaders.disbursementDate'),
      dataIndex: 'disbursementDate',
      key: 'disbursementDate',
      render: (value) => <div>{getDate(value)}</div>
    },
    {
      title: t('chitTakenList.tableHeaders.disbursementPaymentMethod'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (value) => {
        const paymentMethodValue = handleFilterValuesFromData(value, paymentMethodOptions)
        return <div>{paymentMethodValue?.label || '-'}</div>
      }
    },
    {
      title: t('chitTakenList.tableHeaders.triedUserCount'),
      dataIndex: 'triedUserCount',
      key: 'triedUserCount',
      render: (value) => <div>{value || '-'}</div>
    }
  ]

  const handleChangePagination = (pageNo: number) => {
    dispatch(getChitTakenList({ chitGroupId, currentPage: pageNo, size: constants.PAGE_SIZE }))
  }

  return (
    <div>
      <Table
        size="middle"
        rowKey={(record: { ID: number }) => record.ID}
        loading={chitTakenListLoading}
        columns={columns as any}
        dataSource={chitGroupTakenList}
        pagination={false}
        scroll={{ x: 1100 }}
      />
      {pageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ marginTop: '20px' }}
          onChange={handleChangePagination}
          total={pageMetaData.totalRecords}
          current={pageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
    </div>
  )
}

export default ChitTakenListDesktop
