import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getChitGroupOverview } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { ModalLoader } from '../../components/common/PageLoader'
import { currencyFormatter, getBEFormat } from '../../utils'
import type { RootState } from '../../redux'

const NOT_RECEIVED = 'Not Received'

interface ChitGroupOverviewProps {
  chitName?: string
  chitGroupId: any
}

const ChitGroupOverview: React.FC<ChitGroupOverviewProps> = ({ chitGroupId }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { chitTakenListLoading, chitGroupOverview } = useAppSelector((state: RootState) => state.chitGroup)

  useEffect(() => {
    dispatch(getChitGroupOverview({ chitGroupId }))
  }, [dispatch])

  const handleChitDetailColumnNames = () => {
    const defaultColumns = [
      { name: t('chitGroupOverview.installmentNo'), defaultKey: true },
      { name: t('chitGroupOverview.monthName'), defaultKey: true },
      { name: t('chitGroupOverview.dateOfAuction'), defaultKey: true },
      { name: t('chitGroupOverview.customerDueDate'), defaultKey: true },
      { name: t('chitGroupOverview.discount'), defaultKey: true },
      { name: t('chitGroupOverview.installmentAmount'), defaultKey: true },
      { name: t('chitGroupOverview.dividend'), defaultKey: true },
    ]
    let customerColumns: { name: string }[] = []
    if (chitGroupOverview?.customerDetails) {
      customerColumns = chitGroupOverview?.customerDetails.map(({ firstName, lastName }: any) => {
        const name = `${firstName} ${lastName || ''}`
        return { name: name?.length > 30 ? `${name.slice(0, 30)}...` : name }
      })
    }
    return [...defaultColumns, ...customerColumns]
  }

  const handleChitDefaultColumnValues = () => {
    if (chitGroupOverview?.transactionsDetails) {
      return [
        chitGroupOverview?.transactionsDetails.map(({ installmentNumber }: any) => {
          return { value: installmentNumber }
        }),
        chitGroupOverview?.transactionsDetails.map(({ monthString }: any) => {
          return { value: monthString }
        }),
        chitGroupOverview?.transactionsDetails.map(({ auctionDate }: any) => {
          return { value: auctionDate }
        }),
        chitGroupOverview?.transactionsDetails.map(({ dueDate }: any) => {
          return { value: dueDate }
        }),
        chitGroupOverview?.transactionsDetails.map(({ discountAmount }: any) => {
          return { value: currencyFormatter(discountAmount) }
        }),
        chitGroupOverview?.transactionsDetails.map(({ payableAmount }: any) => {
          return { value: currencyFormatter(payableAmount) }
        }),
        chitGroupOverview?.transactionsDetails.map(({ dividend }: any) => {
          return { value: currencyFormatter(dividend) }
        }),
      ]
    }
    return []
  }

  const checkDelayedTransaction = (installmentNumber: any, transactionDate: any) => {
    const installmentDueDate = new Date(getBEFormat(chitGroupOverview?.transactionsDetails[installmentNumber].dueDate))
    const customerTransactionDate = new Date(transactionDate)
    return installmentDueDate < customerTransactionDate
  }

  const handleChitCustomerColumnValues = () => {
    const data = []
    for (let i = 0; i < chitGroupOverview?.numberOfCustomers; i++) {
      if (chitGroupOverview?.customerDetails[i]?.transactions?.length === chitGroupOverview?.transactionsDetails?.length) {
        data.push(
          chitGroupOverview?.customerDetails[i]?.transactions?.map(({ transactionDate, isDelayedPayment }: any, index: number) => {
            return {
              value: isDelayedPayment ? NOT_RECEIVED : getBEFormat(transactionDate),
              isDelayed: checkDelayedTransaction(index, transactionDate)
            }
          })
        )
      } else {
        const customeraData = []
        for (let j = 0; j < chitGroupOverview?.transactionsDetails?.length; j++) {
          const transactionDate = chitGroupOverview?.customerDetails?.[i]?.transactions?.[j]?.transactionDate
          customeraData.push({
            value: transactionDate ? getBEFormat(transactionDate) : NOT_RECEIVED,
            isDelayed: checkDelayedTransaction(j, transactionDate)
          })
        }
        data.push(customeraData)
      }
    }
    return data
  }

  return (
    <div>
      {chitTakenListLoading ? (
        <ModalLoader />
      ) : (
        <>
          <div className="chitgroup-overview_data">
            <div>
              {t('chitGroupOverview.groupNo')}: <span className="chitgroup-overview_data-value">{chitGroupOverview.chitGroupName}</span>
            </div>
            <div>
              {t('chitGroupOverview.chitAmount')}: <span className="chitgroup-overview_data-value">{currencyFormatter(chitGroupOverview.amount)}</span>
            </div>
            <div>
              {t('chitGroupOverview.customerCount')}: <span className="chitgroup-overview_data-value">{chitGroupOverview.numberOfCustomers}</span>
            </div>
            <div>
              {t('chitGroupOverview.time')}: <span className="chitgroup-overview_data-value">11.00 AM</span>
            </div>
          </div>
          <div className="d-flex chitgroup-overview_table">
            {/* Column Names */}
            <div style={{ minWidth: '250px' }}>
              {handleChitDetailColumnNames().map(({ name, defaultKey }: any, id) => (
                <div key={id} className="d-flex">
                  <p
                    className={`chitgroup-overview_key ${!defaultKey ? 'chitgroup-overview_key-customer' : ''} ${
                      handleChitDetailColumnNames()?.length === id + 1 ? ' bb-0' : ''
                    } justify-center`}
                    style={{ width: '15%' }}
                  >
                    {!defaultKey ? id - 6 : ''}
                  </p>
                  <p
                    style={{ width: '85%' }}
                    className={`chitgroup-overview_key ${!defaultKey ? 'chitgroup-overview_key-customer' : ''}  ${
                      handleChitDetailColumnNames()?.length === id + 1 ? ' bb-0' : ''
                    } justify-center`}
                  >
                    {name}
                  </p>
                </div>
              ))}
            </div>
            {/* Column Values */}
            <div style={{ flex: 1, overflow: 'scroll' }}>
              {handleChitDefaultColumnValues().map((data: any, id) => {
                return (
                  <div key={id} className="d-flex">
                    {data?.map(({ value }: any, index: number) => (
                      <p key={index} className="chitgroup-overview_value">
                        {value}
                      </p>
                    ))}
                  </div>
                )
              })}
              {handleChitCustomerColumnValues().map((data: any, id) => {
                return (
                  <div key={`customer-value-${id}`} className="d-flex">
                    {data?.map(({ value, isDelayed }: any, index: number) => (
                      <p
                        key={index}
                        className={`chitgroup-overview_value ${handleChitCustomerColumnValues()?.length === id + 1 ? ' bb-0' : ''}
                        ${value === NOT_RECEIVED ? ' chitgroup-overview_value_not-received' : ''} ${isDelayed ? ' chitgroup-overview_value_delayed' : ''}
                      `}
                      >
                        {value}
                      </p>
                    ))}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ChitGroupOverview
