import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getChitReport, getCustomerReport, downloadReport } from '../thunks/report'
import { userLogout } from '../actions'
import constants from '../../constants'
import { ReportState } from '../../types/redux'

const initialState: ReportState = {
  isLoading: false,
  chitReportList: [],
  chitReportPageMetaData: {},
  customerReportList: [],
  customerReportPageMetaData: {},
  downloadReportData: {},
  success: {},
  error: {}
}

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    clearReportData: (state) => {
      state.chitReportList = []
      state.customerReportList = []
      state.chitReportPageMetaData = {}
      state.chitReportPageMetaData = {}
      state.downloadReportData = {}
    },
    clearDownloadReportData: (state) => {
      state.downloadReportData = {}
    },
    updateChitReportPageMetaData: (state, { payload }) => {
      state.chitReportPageMetaData = payload
    },
    updateCustomerReportPageMetaData: (state, { payload }) => {
      state.customerReportPageMetaData = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChitReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChitReport.fulfilled, (state, { payload }) => {
      const data = payload.data.data
      state.isLoading = false
      state.success = {}
      state.chitReportList = data
      state.chitReportPageMetaData = {
        currentPage: 1,
        size: constants.PAGE_SIZE,
        totalPages: Math.ceil(data?.length / constants.PAGE_SIZE),
        totalRecords: data.length
      }
    })
    builder.addCase(getChitReport.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getCustomerReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCustomerReport.fulfilled, (state, { payload }) => {
      const data = payload.data.data
      state.isLoading = false
      state.success = {}
      state.customerReportList = data
      state.customerReportPageMetaData = {
        currentPage: 1,
        size: constants.PAGE_SIZE,
        totalPages: Math.ceil(data?.length / constants.PAGE_SIZE),
        totalRecords: data.length
      }
    })
    builder.addCase(getCustomerReport.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(downloadReport.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(downloadReport.fulfilled, (state, { payload }) => {
      const data = payload.data
      state.isLoading = false
      state.success = {}
      state.downloadReportData = data?.content
    })
    builder.addCase(downloadReport.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        chitReportList: [],
        chitReportPageMetaData: {},
        customerReportList: [],
        customerReportPageMetaData: {},
        downloadReportData: {},
        success: {},
        error: {}
      }
    })
  }
})

export const { clearReportData, clearDownloadReportData, updateChitReportPageMetaData, updateCustomerReportPageMetaData } = reportSlice.actions
export const reportReducer = reportSlice.reducer
