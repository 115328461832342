import { useNavigate, NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { HashLink as Link } from 'react-router-hash-link'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'
import config from '../../../config'
import Logo from '../../../assets/images/logo/black_small.png'

const LandingPageHeader: React.FC = () => {
  const { t } = useTranslation()
  const navigate: NavigateFunction = useNavigate()
  const { isMobile } = useAppSelector((state: RootState) => state.common)

  const handleNavigateToLogin = () => {
    navigate(config.pages.login)
  }

  const menuOptions = [
    { name: t('landingPage.navBar.home'), id: 'hero', },
    { name: t('landingPage.navBar.whyUs'), id: 'features', },
    { name: t('landingPage.navBar.testimonial'), id: 'testimonial', },
    { name: t('landingPage.navBar.contactUs'), id: 'contact', },
    { name: t('landingPage.navBar.login'), button: true }
  ]

  const scrollWithOffset = (el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -80
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }

  return (
    <div className="landingPage-header">
      <div className="landingPage-header-container">
        <div>
          <img src={Logo} className="landingPage-header-logo" />
        </div>
        <div className="landingPage-header-menuContainer">
          {isMobile ? (
            <Button className="landingPage-header-menuOption" type="primary" onClick={handleNavigateToLogin}>
              Login
            </Button>
          ) : (
            menuOptions.map(({ name, id, button }) =>
              button ? (
                <Button className="landingPage-header-menuOption" key={name} type="primary" onClick={handleNavigateToLogin}>
                  {name}
                </Button>
              ) : (
                <Link smooth to={`/#${id}`} scroll={el => scrollWithOffset(el)} key={name} className="landingPage-header-menuOption" style={{ color: '#333' }}> {name} </Link>  
              )
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default LandingPageHeader
