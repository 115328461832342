import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FilterContainer from '../../components/Filter'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { getCustomerReport, downloadReport, clearReportData, clearDownloadReportData, updateCustomerReportPageMetaData } from '../../redux'
import constants from '../../constants'
import { posthogCaptureEvent } from '../../utils'

import { Pagination } from 'antd'
import CustomerReportTableMobile from '../../components/customerReports/mobile'
import CustomerReportTableDesktop from '../../components/customerReports/desktop'
import type { RootState } from '../../redux'

const CustomerReports: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { customerReportList, customerReportPageMetaData, downloadReportData } = useAppSelector((state: RootState) => state.report)
  const { isMobile } = useAppSelector((state) => state.common)
  const [filterValues, setFilterValues] = useState<any>({})

  useEffect(() => {
    dispatch(clearReportData())
  }, [dispatch])

  useEffect(() => {
    downloadReportData?.length && downloadExcel(downloadReportData)
  }, [downloadReportData])

  const downloadExcel = (excelData: string) => {
    try {
      const byteCharacters = atob(excelData)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const excelBlob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(excelBlob)
      const a = document.createElement('a')
      a.href = url
      const currentDate = `${new Date().getDate()}_${new Date().getMonth() + 1}_${new Date().getFullYear()}`
      a.download = `Customer_Report_${filterValues.customerID}_${currentDate}.xlsx`
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
      document.body.removeChild(a)
      dispatch(clearDownloadReportData())
    } catch (error) {
      console.error('Error decoding base64 data:', error)
    }
  }

  const handleFilterValues = async (filters: any) => {
    setFilterValues(filters)
    posthogCaptureEvent('applied_filters', { type: 'customer_report', value: filters || 'RESET' })
    filters.customerID
      ? dispatch(getCustomerReport({ customerID: filters.customerID, filters: filters.filters, currentPage: 1, size: constants.PAGE_SIZE }))
      : dispatch(clearReportData())
  }

  const handleChangePagination = (pageNo: number) => {
    posthogCaptureEvent('change_pagination', { type: 'customer_report', pageNo })
    const response = {
      currentPage: pageNo,
      size: customerReportPageMetaData.size,
      totalPages: customerReportPageMetaData.totalPages,
      totalRecords: customerReportPageMetaData.totalRecords
    }
    dispatch(updateCustomerReportPageMetaData(response))
  }

  const handleTableData = () => {
    const start = (customerReportPageMetaData.currentPage - 1) * 10
    const end = customerReportPageMetaData.currentPage * constants.PAGE_SIZE
    return customerReportList.slice(start, end)
  }

  const handleDownloadReport = () => {
    posthogCaptureEvent('download_report', { type: 'customer_report' })
    const payload = {
      reportName: constants.reportType.CUSTOMER_REPORT,
      filters: `customerid=${filterValues.customerID}&${filterValues.filters}`
    }
    dispatch(downloadReport(payload))
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <FilterContainer
        showFilter={true}
        t={t}
        dataLength={handleTableData()?.length}
        filterType={constants.filterType.CUSTOMER_REPORT}
        handleDownloadAction={handleDownloadReport}
        handleFilterValues={handleFilterValues}
      />
      {isMobile ? <CustomerReportTableMobile data={handleTableData()} /> : <CustomerReportTableDesktop data={handleTableData()} />}
      {customerReportPageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ margin: '20px 0' }}
          onChange={handleChangePagination}
          total={customerReportPageMetaData.totalRecords}
          current={customerReportPageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
    </div>
  )
}

export default CustomerReports
