import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import config from '../config'
import ProtectedRoute from './protectedRoute'
import * as ACL from '../constants/acl-constants'

import LandingPage from '../pages/homePage'
import Enquiry from '../pages/enquiry'
import Login from '../pages/login'
import ForgotPassword from '../pages/forgotPassword'
import ResetPassword from '../pages/resetPassword'
import Dashboard from '../pages/dashboard'
import ChitGroup from '../pages/chitGroup'
import CreateChitGroup from '../pages/chitGroup/create'
import Customers from '../pages/customers'
import CreateCustomer from '../pages/customers/create'
import ChitGroupTransactions from '../pages/chitGroupTransactions'
import CustomerTransactions from '../pages/customerTransactions'
import Analytics from '../pages/analytics'
import ChitReports from '../pages/chitReports'
import CustomerReports from '../pages/customerReports'
import Notification from '../pages/notification'
import Users from '../pages/users'
import Channels from '../pages/channels'
import Support from '../pages/support'
import Settings from '../pages/settings'
import PublicCustomer from '../pages/publicCustomer'
import BrokenPage from '../pages/brokenPage'
import UnAuthorizedPage from '../pages/unAuthorized'

const Routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={config.pages.landingPage} element={<LandingPage />} errorElement={<BrokenPage />} />
      <Route path={config.pages.enquiry} element={<Enquiry />} errorElement={<BrokenPage />} />
      <Route path={config.pages.landingPage} element={<Login />} errorElement={<BrokenPage />} />
      <Route path={config.pages.login} element={<Login />} errorElement={<BrokenPage />} />
      <Route path={config.pages.dashboard} element={<ProtectedRoute component={Dashboard} allowedRoles={ACL.CHIT_DASHBOARD_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.chitGroup} element={<ProtectedRoute component={ChitGroup} allowedRoles={ACL.CHIT_CHIT_GROUP_LIST_VIEW} />} errorElement={<BrokenPage />} />
      <Route
        path={config.pages.create_chitGroup}
        element={<ProtectedRoute component={CreateChitGroup} allowedRoles={ACL.CHIT_CHIT_GROUP_CREATE} />}
        errorElement={<BrokenPage />}
      />
      <Route
        path={`${config.pages.view_chitGroup}/:chitGroupId`}
        element={<ProtectedRoute component={CreateChitGroup} allowedRoles={ACL.CHIT_CHIT_GROUP_DETAIL_VIEW} />}
        errorElement={<BrokenPage />}
      />
      <Route
        path={`${config.pages.edit_chitGroup}/:chitGroupId`}
        element={<ProtectedRoute component={CreateChitGroup} allowedRoles={ACL.CHIT_CHIT_GROUP_UPDATE} />}
        errorElement={<BrokenPage />}
      />
      <Route path={config.pages.customer} element={<ProtectedRoute component={Customers} allowedRoles={ACL.CHIT_CUSTOMER_LIST_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.create_customer} element={<ProtectedRoute component={CreateCustomer} allowedRoles={ACL.CHIT_CUSTOMER_CRETAE} />} errorElement={<BrokenPage />} />
      <Route
        path={`${config.pages.view_customer}/:customerId`}
        element={<ProtectedRoute component={CreateCustomer} allowedRoles={ACL.CHIT_CUSTOMER_DETAIL_VIEW} />}
        errorElement={<BrokenPage />}
      />
      <Route
        path={`${config.pages.edit_customer}/:customerId`}
        element={<ProtectedRoute component={CreateCustomer} allowedRoles={ACL.CHIT_CUSTOMER_UPDATE} />}
        errorElement={<BrokenPage />}
      />
      <Route
        path={config.pages.chitGroupTransaction}
        element={<ProtectedRoute component={ChitGroupTransactions} allowedRoles={ACL.CHIT_CHIT_TRANSACTION_LIST_VIEW} />}
        errorElement={<BrokenPage />}
      />
      <Route
        path={config.pages.customerTransaction}
        element={<ProtectedRoute component={CustomerTransactions} allowedRoles={ACL.CHIT_CUSTOMER_TRANSACTION_LIST_VIEW} />}
        errorElement={<BrokenPage />}
      />
      <Route path={config.pages.analytics} element={<ProtectedRoute component={Analytics} allowedRoles={ACL.CHIT_ANALYTICS_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.chitReports} element={<ProtectedRoute component={ChitReports} allowedRoles={ACL.CHIT_CHIT_REPORT_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.settings} element={<ProtectedRoute component={Settings} allowedRoles={ACL.CHIT_SETTINGS_VIEW} />} errorElement={<BrokenPage />} />
      <Route
        path={config.pages.customerReports}
        element={<ProtectedRoute component={CustomerReports} allowedRoles={ACL.CHIT_CUSTOMER_REPORT_VIEW} />}
        errorElement={<BrokenPage />}
      />
      <Route path={config.pages.notification} element={<ProtectedRoute component={Notification} allowedRoles={ACL.CHIT_NOTIFICATION_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.user} element={<ProtectedRoute component={Users} allowedRoles={ACL.CHIT_USER_LIST_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.channels} element={<ProtectedRoute component={Channels} allowedRoles={ACL.CHIT_CHANNEL_LIST_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.support} element={<ProtectedRoute component={Support} allowedRoles={ACL.CHIT_SUPPORT_VIEW} />} errorElement={<BrokenPage />} />
      <Route path={config.pages.publicCustomer} element={<PublicCustomer />} errorElement={<BrokenPage />} />
      <Route path={config.pages.forgotPassword} element={<ForgotPassword />} errorElement={<BrokenPage />} />
      <Route path={config.pages.resetPassword} element={<ResetPassword />} errorElement={<BrokenPage />} />
      <Route path={config.pages.unAuthorized} element={<UnAuthorizedPage />} errorElement={<BrokenPage />} />
    </>
  )
)

export default Routes
