import Axios from 'axios'

const customAxios = Axios.create({
  headers: { Token: window.localStorage.jwt }
})

const reqHandler = (req) => {
  req.headers.Token = window.localStorage.jwt
  return req
}

const resHandler = (res) => {
  return res
}

const errorHandler = (error) => {
  return Promise.reject(error)
}

customAxios.interceptors.request.use(
  (request) => reqHandler(request),
  (error) => errorHandler(error)
)

customAxios.interceptors.response.use(
  (response) => resHandler(response),
  (error) => errorHandler(error)
)

export default customAxios
