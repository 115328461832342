export enum OnboardingTourKeys {
  DASHBOARD = "DASHBOARD",
  CHIT_GROUP_LIST = "CHIT_GROUP_LIST",
  CUSTOMER_LIST = "CUSTOMER_LIST",
  CHIT_TRANSACTION_LIST = "CHIT_TRANSACTION_LIST",
  CUSTOMER_TRANSACTION_LIST = "CUSTOMER_TRANSACTION_LIST",
  CHIT_REPORT = "CHIT_REPORT",
  CUSTOMER_REPORT = "CUSTOMER_REPORT",
  ANALYTICS = "ANALYTICS",
  USER_LIST = "USER_LIST",
  SETTINGS = "SETTINGS"
}

export default {
  onboarding: {
    DASHBOARD: OnboardingTourKeys.DASHBOARD,
    CHIT_GROUP_LIST: OnboardingTourKeys.CHIT_GROUP_LIST,
    CUSTOMER_LIST: OnboardingTourKeys.CUSTOMER_LIST,
    CHIT_TRANSACTION_LIST: OnboardingTourKeys.CHIT_TRANSACTION_LIST,
    CUSTOMER_TRANSACTION_LIST: OnboardingTourKeys.CUSTOMER_TRANSACTION_LIST,
    CHIT_REPORT: OnboardingTourKeys.CHIT_REPORT,
    CUSTOMER_REPORT: OnboardingTourKeys.CUSTOMER_REPORT,
    ANALYTICS: OnboardingTourKeys.ANALYTICS,
    USER_LIST: OnboardingTourKeys.USER_LIST,
    SETTINGS: OnboardingTourKeys.SETTINGS
  },
  preference: {
    LANGUAGE: 'LANGUAGE',
  }
}
