import { createAsyncThunk } from '@reduxjs/toolkit'
import customAxios from '../../utils/axios'
import CONFIG from '../../config'
import { handleLogout } from '../../utils'

export const getUserConfig = createAsyncThunk('GET_USER_CONFIG', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getUserConfig(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createUserConfig = createAsyncThunk('CREATE_USER_CONFIG', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createUserConfig, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateUserConfig = createAsyncThunk('UPDATE_USER_CONFIG', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateUserConfig(payload.id), payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
