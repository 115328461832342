import { createAsyncThunk } from '@reduxjs/toolkit'
import CONFIG from '../../config'
import customAxios from '../../utils/axios'
import { handleLogout } from '../../utils'

export const getAllChitGroupTransactionList = createAsyncThunk('GET_ALL_CHIT_GROUP_TRANSACTION_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getAllChitGroupTransactionList(payload.currentPage, payload.size, payload.filters)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getChitGroupTransactionList = createAsyncThunk('GET_CHIT_GROUP_TRANSACTION_LIST', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getChitGroupTransactionList(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const getDefaultChitGroupTransaction = createAsyncThunk('GET_DEFAULT_CHIT_GROUP_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.get(CONFIG.api.getDefaulChitGroupTransaction(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const createChitGroupTransaction = createAsyncThunk('CREATE_CHIT_GROUP_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.post(CONFIG.api.createChitGroupTransaction, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const updateChitGroupTransaction = createAsyncThunk('UPDATE_CHIT_GROUP_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.put(CONFIG.api.updateChitGroupTransaction, payload).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})

export const deleteChitGroupTransaction = createAsyncThunk('DELETE_CHIT_GROUP_TRANSACTION', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await customAxios.delete(CONFIG.api.deleteChitGroupTransaction(payload)).then((res) => res)
    return response
  } catch (err: any) {
    err.response.status === 401 && handleLogout()
    return rejectWithValue(err)
  }
})
