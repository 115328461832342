import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getPublicCustomerInfo, getPublicCustomerChitTransactions } from '../thunks/publicCustomer'
import { PublicCustomerState } from '../../types/redux'

const initialState: PublicCustomerState = {
  isLoading: false,
  publicCustomerActionLoading: false,
  publicCustomerInfo: {},
  publicCustomerChitTransactionsList: [],
  success: {},
  error: {}
}

export const publicCustomerSlice = createSlice({
  name: 'publicCustomer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPublicCustomerInfo.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPublicCustomerInfo.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.publicCustomerInfo = payload.data.data
      state.success = {}
    })
    builder.addCase(getPublicCustomerInfo.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getPublicCustomerChitTransactions.pending, (state) => {
      state.publicCustomerActionLoading = true
    })
    builder.addCase(getPublicCustomerChitTransactions.fulfilled, (state, { payload }) => {
      state.publicCustomerActionLoading = false
      state.success = {}
      state.publicCustomerChitTransactionsList = payload.data.data
    })
    builder.addCase(getPublicCustomerChitTransactions.rejected, (state, { payload }: PayloadAction<any>) => {
      state.publicCustomerActionLoading = false
      state.error = payload?.response?.data?.error
    })
  }
})

export const publicCustomerReducer = publicCustomerSlice.reducer
