import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  getAllCustomerTransactionList,
  getCustomerTransactionList,
  getDefaultCustomerTransaction,
  createCustomerTransaction,
  updateCustomerTransaction,
  deleteCustomerTransaction
} from '../thunks/customerTransaction'
import { userLogout } from '../actions'
import { CustomerTransactionState } from '../../types/redux'

const initialState: CustomerTransactionState = {
  isLoading: false,
  customerTransactionActionLoading: false,
  customerTransactionList: [],
  pageMetaData: {},
  defaultCustomerTransaction: {},
  success: {},
  error: {}
}

export const customerTransactionSlice = createSlice({
  name: 'customerTransaction',
  initialState,
  reducers: {
    clearSuccessErrorData: (state) => {
      state.success = {}
      state.error = {}
    },
    clearDefaultCustomerTransactionData: (state) => {
      state.defaultCustomerTransaction = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCustomerTransactionList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllCustomerTransactionList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.customerTransactionList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getAllCustomerTransactionList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getCustomerTransactionList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCustomerTransactionList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.customerTransactionList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getCustomerTransactionList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDefaultCustomerTransaction.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDefaultCustomerTransaction.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.defaultCustomerTransaction = payload.data.data
      state.success = {}
    })
    builder.addCase(getDefaultCustomerTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createCustomerTransaction.pending, (state) => {
      state.customerTransactionActionLoading = true
    })
    builder.addCase(createCustomerTransaction.fulfilled, (state, { payload }) => {
      state.customerTransactionActionLoading = false
      state.success = payload.data
    })
    builder.addCase(createCustomerTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerTransactionActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateCustomerTransaction.pending, (state) => {
      state.customerTransactionActionLoading = true
    })
    builder.addCase(updateCustomerTransaction.fulfilled, (state, { payload }) => {
      state.customerTransactionActionLoading = false
      state.success = payload.data
    })
    builder.addCase(updateCustomerTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerTransactionActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(deleteCustomerTransaction.pending, (state) => {
      state.customerTransactionActionLoading = true
    })
    builder.addCase(deleteCustomerTransaction.fulfilled, (state, { payload }) => {
      state.customerTransactionActionLoading = false
      state.success = payload.data
    })
    builder.addCase(deleteCustomerTransaction.rejected, (state, { payload }: PayloadAction<any>) => {
      state.customerTransactionActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        customerTransactionActionLoading: false,
        customerTransactionList: [],
        pageMetaData: {},
        defaultCustomerTransaction: {},
        success: {},
        error: {}
      }
    })
  }
})

export const { clearSuccessErrorData, clearDefaultCustomerTransactionData } = customerTransactionSlice.actions

export const customerTransactionReducer = customerTransactionSlice.reducer
