import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getDashboardStatusCount, getDashboardPaymentGraph, getAnalytics } from '../thunks/analytics'
import { userLogout } from '../actions'
import { AnalyticsState } from '../../types/redux'

const initialState: AnalyticsState = {
  isLoading: false,
  dashboardStatusCount: {},
  paymentTransactionGraphData: {},
  analyticsData: {},
  success: {},
  error: {}
}

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearAnalyticsData: (state) => {
      state.analyticsData = {}
      state.dashboardStatusCount = {}
      state.paymentTransactionGraphData = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboardStatusCount.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDashboardStatusCount.fulfilled, (state, { payload }) => {
      const data = payload.data.data
      state.isLoading = false
      state.success = {}
      state.dashboardStatusCount = data
    })
    builder.addCase(getDashboardStatusCount.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDashboardPaymentGraph.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDashboardPaymentGraph.fulfilled, (state, { payload }) => {
      const data = payload.data.data
      state.isLoading = false
      state.success = {}
      state.paymentTransactionGraphData = data
    })
    builder.addCase(getDashboardPaymentGraph.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getAnalytics.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAnalytics.fulfilled, (state, { payload }) => {
      const data = payload.data.data
      state.isLoading = false
      state.success = {}
      state.analyticsData = data
    })
    builder.addCase(getAnalytics.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        dashboardStatusCount: {},
        paymentTransactionGraphData: {},
        analyticsData: {},
        success: {},
        error: {}
      }
    })
  }
})

export const { clearAnalyticsData } = analyticsSlice.actions
export const analyticsReducer = analyticsSlice.reducer
