import { useTranslation } from 'react-i18next'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { Button } from 'antd'
import config from '../../../config'
import HeroSectionImg from '../../../assets/images/heroSection.svg'

const HeroSection: React.FC = () => {
  const { t } = useTranslation()
  const navigate: NavigateFunction = useNavigate()

  const handleNavigateToEnquiry = () => {
    navigate(config.pages.enquiry)
  }

  return (
    <div id='hero' className="landingPage-heroSection">
      <div className="landingPage-heroSection-container">
        <div>
          <div className="landingPage-heroSection-title">{t('landingPage.heroSection.title')}</div>
          <div className="landingPage-heroSection-description">{t('landingPage.heroSection.description')}</div>
          <div>
            <Button type="primary" size="large" shape="round">
              {t('landingPage.heroSection.knowMore')}
            </Button>
            <Button className="landingPage-header-menuOption" size="large" shape="round" onClick={handleNavigateToEnquiry}>
              {t('landingPage.heroSection.demo')}
            </Button>
          </div>
        </div>
        <div className="landingPage-heroSection-imgContainer">
          <img src={HeroSectionImg} className="landingPage-heroSection-img" alt="People Chit" />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
