import queryString, { ParsedQuery } from 'query-string'
import dayjs from 'dayjs'
import type { RangePickerProps } from 'antd/es/date-picker'
import posthog from 'posthog-js'

import constants from '../constants'
const getMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

import { Store, userLogout } from '../redux'
import router from '../router/routes'
import CONFIG from '../config'

export const handleLogout = () => {
  posthogCaptureEvent('user_logout')
  Store.dispatch(userLogout())
  window.localStorage.removeItem('jwt')
  router.navigate(CONFIG.pages.login)
}

export const posthogCaptureEvent = (eventName: string, eventData?: any) => {
  posthog.capture(eventName, eventData)
}

export const getLocale = () => {
  return window.localStorage.getItem('CHIT_APP_LANG_KEY') || 'en'
}

export const setLocale = (value: string) => {
  window.localStorage.setItem('CHIT_APP_LANG_KEY', value)
}

export const woCurrencyFormatter = (value: number) => {
  return new Intl.NumberFormat('en-IN').format(value)
}

export const currencyFormatter = (value: number) => {
  return value ? `₹${new Intl.NumberFormat('en-IN').format(value)}` : '₹0'
}

export const getTodayDate = () => {
  return dayjs().locale('en').startOf('day')
}

export const disabledAfterTodayDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current > dayjs().endOf('day')
}

export const disabledBeforeTodayDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < dayjs().endOf('day').subtract(1, 'day')
}

export const getDate = (value: number) => {
  const date = new Date(value).getDate()
  const month = new Date(value).getMonth()
  const year = new Date(value).getFullYear()
  return `${date} ${getMonth[month]} ${year}`
}

export const getBEFormat = (value: number) => {
  const date = new Date(value).getDate()
  const month = new Date(value).getMonth()
  const year = new Date(value).getFullYear()
  return `${year}-${month < 9 ? `0${month + 1}` : month + 1}-${date < 10 ? `0${date}` : date}`
}

export const getMonthYearBEFormat = (value: number) => {
  const month = new Date(value).getMonth()
  const year = new Date(value).getFullYear()
  return `${year}-${month < 9 ? `0${month + 1}` : month + 1}`
}

export const getDateWithTime = (value: number) => {
  const data = new Date(value)
  const date = getDate(value)
  const hours = data.getHours() > 12 ? data.getHours() - 12 : data.getHours()
  const roundedHours: string = hours < 9 ? `0${hours}` : `${hours}`
  const am_pm = data.getHours() >= 12 ? ' PM' : ' AM'
  const mins = new Date(value).getMinutes()
  const roundedMins: string = mins < 9 ? `0${mins}` : `${mins}`

  return `${date} ${roundedHours}:${roundedMins} ${am_pm}`
}

export const getFirstLetterCapitalize = (value: any) => {
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const handleFilterValuesFromData = (value: any, options: any[]) => {
  return options.filter((option: any) => (option.key || option.value) === value)[0]
}

export const allowOnlyNumbers = (value: any) => {
  const regex = constants.regex.number
  if (value === '' || regex.test(value)) {
    return value || value === ''
  }
}

export const phoneNoValidation = (value: any) => {
  return constants.regex.phoneNo.test(value)
}

export const emailValidation = (value: any) => {
  return constants.regex.email.test(value)
}

export const textValidation = (value: any) => {
  return constants.regex.text.test(value)
}

export const alpheNumericValidation = (value: any) => {
  return constants.regex.alphanNmeric.test(value)
}

export const passwordValidation = (value: any) => {
  return constants.regex.password.test(value)
}

export const formatURL = (installment: number, customerId: string, paidStatus?: string, chitgroupId?: string) => {
  const queryParams: ParsedQuery<string | number | boolean> = {}

  if (installment) {
    queryParams.installment = installment
  }

  if (customerId) {
    queryParams.customerId = customerId
  }

  if (chitgroupId) {
    queryParams.chitgroupId = chitgroupId
  }

  if (paidStatus) {
    queryParams.paidStatus = paidStatus
  }

  return queryString.stringify(queryParams)
}

export const formatPayload = (chitgroupId?: string, installment?: number, customerId?: string, customerName?: string) => {
  const queryParams: ParsedQuery<string | number | string> = {}

  if (chitgroupId) {
    queryParams.chitgroupID = chitgroupId
  }

  if (installment) {
    queryParams.installmentNumber = installment
  }

  if (customerId) {
    queryParams.customerID = customerId
  }

  if (customerName) {
    queryParams.customerName = customerName
  }

  return queryParams
}

export const formatStringEqURL = (installment?: number, customer?: string, chitGroup?: string) => {
  const filters = []

  if (chitGroup) {
    filters.push(`chitgroupID eq ${chitGroup}`)
  }
  if (installment) {
    filters.push(`installmentNumber eq ${installment}`)
  }
  if (customer) {
    filters.push(`customerID eq ${customer}`)
  }

  return filters.join(',')
}
