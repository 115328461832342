import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormatter, getDate, handleFilterValuesFromData } from '../../../utils'
import { getChitTakenList } from '../../../redux'
import { paymentMethodOptions } from '../../../utils/data'
import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { PageLoader } from '../../common/PageLoader'
import constants from '../../../constants'
import type { RootState } from '../../../redux'

import { Card, Tag, Pagination } from 'antd'

interface ChitTakenListProps {
  chitName?: string
  chitGroupId: any
}

const ChitTakenListMobile: React.FC<ChitTakenListProps> = ({ chitGroupId }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isMobile } = useAppSelector((state) => state.common)
  const { chitTakenListLoading, chitGroupTakenList, pageMetaData } = useAppSelector((state: RootState) => state.chitGroup)

  useEffect(() => {
    dispatch(getChitTakenList({ chitGroupId, currentPage: 1, size: constants.PAGE_SIZE }))
  }, [dispatch])

  const handleChangePagination = (pageNo: number) => {
    dispatch(getChitTakenList({ chitGroupId, currentPage: pageNo, size: constants.PAGE_SIZE }))
  }

  return (
    <div>
      {chitTakenListLoading ? (
        <PageLoader />
      ) : (
      <>
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
        {chitGroupTakenList.map((record: any, index: any) => {
            const paymentMethodValue = handleFilterValuesFromData(record.paymentMethod, paymentMethodOptions)
            return (
              <Card size="small" style={{ width: '100%' }} bordered key={record.collectionTransactionId}>
              <div className="d-flex flex-column" style={{ gap: '4px' }}>
                <div className="d-flex justify-between align-center">
                  <div>
                    {' '}
                    <span className="text-gray"> {t('common.tableHeaders.sNo')}: </span> {(pageMetaData.currentPage - 1) * 10 + index + 1}{' '}
                  </div>
                  <div className="d-flex flex-wrap justify-end" style={{ gap: '6px' }}>
                    <Tag className="m-0" color="orange"> {getDate(record.chitDate)} </Tag>
                    <Tag className="m-0" color='lime'> {paymentMethodValue?.label || '-'} </Tag>
                  </div>
                </div>
                <div>
                  {' '}
                  <span> {record.firstName} {record.lastName || ''} </span>
                </div>
                <div className="d-flex flex-column" style={{ gap: '4px' }}>
                  <div className="d-flex justify-between">
                    <div>
                      <span className="text-gray"> {t('chitTakenList.tableHeaders.installment')} </span>
                      <span> | </span>
                      <span className="text-gray"> {t('chitTakenList.tableHeaders.amount')} </span>
                    </div>
                    <div className="d-flex align-center" style={{ gap: '4px' }}>
                      <span> {record.installmentNumber} </span>
                      <span> | </span>
                      <span> {currencyFormatter(record.amount)} </span>
                    </div>
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('chitTakenList.tableHeaders.disbursementDate')} </span>
                    <div className="d-flex align-center" style={{ gap: '4px' }}>
                      <span> {getDate(record.disbursementDate)} </span>
                    </div>
                  </div>
                  <div className="d-flex justify-between">
                    <span className="text-gray"> {t('chitTakenList.tableHeaders.triedUserCount')} </span>
                    <span> {record.triedUserCount || '-'} </span>{' '}
                  </div>
                </div>
              </div>
            </Card>  
            )
          })}  
        </div>
        {pageMetaData && (
          <Pagination
            className="d-flex justify-end align-center"
            style={{ marginTop: '20px' }}
            onChange={handleChangePagination}
            total={pageMetaData.totalRecords}
            current={pageMetaData.currentPage}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            hideOnSinglePage={true}
            showSizeChanger={false}
            showQuickJumper={false}
            simple={isMobile}
          />
        )}
      </>)}
    </div>
  )
}

export default ChitTakenListMobile
