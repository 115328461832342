const constants = {
  ACTION: 'ACTION',
  DELETE: 'DELETE',
  CHITGROUP: 'chitGroup',
  CUSTOMER: 'customer',
  CHIT_TRANSACTION: 'chitTransaction',
  CUSTOMER_TRANSACTION: 'customerTransaction',
  USER: 'user',
  CHANNEL: 'channel',
  PAGE_SIZE: 10,
  format: {
    DATE_FORMAT: 'YYYY/MM/DD',
    MONTH_FORMAT: 'YYYY/MM'
  },
  role: {
    SYSTEM_ADMIN: 'SYSTEM_ADMIN',
    SUPERADMIN: 'SUPERADMIN',
    ADMIN: 'ADMIN',
    USER: 'USER'
  },
  chitStatus: {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    ENDED: 'ENDED'
  },
  filterType: {
    CHIT: 'CHIT',
    CUSTOMER: 'CUSTOMER',
    USER: 'USER',
    CHANNEL: 'CHANNEL',
    ANALYTICS: 'ANALYTICS',
    CHIT_TRANSACTION: 'CHIT_TRANSACTION',
    CUSTOMER_TRANSACTION: 'CUSTOMER_TRANSACTION',
    CHIT_REPORT: 'CHIT_REPORT',
    CUSTOMER_REPORT: 'CUSTOMER_REPORT',
    CREATE_ACTIONS: ['CHIT', 'CUSTOMER', 'CHIT_TRANSACTION', 'CUSTOMER_TRANSACTION', 'USER'],
    REPORTS: ['CHIT_REPORT', 'CUSTOMER_REPORT'],
    MASS_UPLOAD: ['CUSTOMER', 'CHIT_TRANSACTION', 'CUSTOMER_TRANSACTION'],
  },
  regex: {
    phoneNo: /^[6-9]\d{9}$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    text: /^[a-zA-Z]+$/,
    number: /^[0-9\b]+$/,
    alphanNmeric: /^[a-zA-Z0-9\s]+$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{5,}$/
  },
  pageName: {
    '/dashboard': 'headerTitle.dashboard',
    '/chitGroup': 'headerTitle.chitGroup',
    '/chitGroup/create': 'headerTitle.create_chitGroup',
    '/chitGroup/edit': 'headerTitle.edit_chitGroup',
    '/chitGroup/view': 'headerTitle.view_chitGroup',
    '/customer': 'headerTitle.customer',
    '/customer/create': 'headerTitle.create_customer',
    '/customer/edit': 'headerTitle.edit_customer',
    '/customer/view': 'headerTitle.view_customer',
    '/transaction/chitGroup': 'headerTitle.chitGroupTransaction',
    '/transaction/customer': 'headerTitle.customerTransaction',
    '/analytics': 'headerTitle.analytics',
    '/reports/chit': 'headerTitle.chitReports',
    '/reports/customer': 'headerTitle.customerReports',
    '/notification': 'headerTitle.notification',
    '/users': 'headerTitle.user',
    '/support': 'headerTitle.support',
    '/settings': 'headerTitle.settings',
  },
  length: {
    PASSWORD: 5,
    PHONE_NUMBER: 10,
    CURRENCY: 10,
    MAX_NOTIFICATION_AMOUNT: 8
  },
  paymentRequiredTemplates: ['CHIT_PARTIAL_DETAILS', 'CHIT_FULL_DETAILS', 'CHIT_PARTIAL_AMOUNT_RECEIVED', 'CHIT_AMOUNT_RECEIVED', 'CHIT_AMOUNT_SENT', 'PAYMENT_REMINDER'],
  partialPaymentRequiredTemplates: ['CHIT_PARTIAL_AMOUNT_RECEIVED'],
  chitDayRequiredTemplates: ['CHIT_MONTH_REMINDER'],
  keys: {
    AMOUNT: 'AMOUNT',
    BALANCE_AMOUNT: 'BALANCE_AMOUNT'
  },
  reportType: {
    CHIT_REPORT: 'Chitgroup',
    CUSTOMER_REPORT: 'Customer',
  }
}

export default constants
