import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ProfilePic from '../../assets/images/avataaar.svg'
import { useAppSelector } from '../../redux/hooks'
import { handleLogout } from '../../utils'
import type { MenuProps } from 'antd'
import { Layout, theme, Dropdown } from 'antd'
import constants from '../../constants'

const { Header } = Layout

const AppHeader: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { isMobile } = useAppSelector((state) => state.common)
  const { userData, isSystemAdmin, isSuperAdmin, isAdmin } = useAppSelector((state) => state.auth)

  const {
    token: { colorBgContainer }
  } = theme.useToken()

  const getPageTitle = () => {
    const name: string = location.pathname
    const pageNameObj: object = constants.pageName
    const pageName: string = t(pageNameObj[name as keyof object])

    if (isMobile) {
      return pageName?.length > 40 ? `${pageName.slice(0, 39)}...` : pageName
    }
    return pageName
  }

  const userOptions: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <p onClick={handleLogout} className="m-0">
          {t('user.logout')}
        </p>
      )
    }
  ]

  const handleUserName = () => {
    const mobileVersionName = userData?.name.length > 10 ? `${userData?.name.slice(0, 10)}...` : userData?.name
    return (isMobile ? mobileVersionName : userData?.name) || t('user.admin')
  }

  const getUserRole = () => {
    return isSystemAdmin ? t('user.systemAdmin') : isSuperAdmin ? t('user.superAdmin') : isAdmin ? t('user.admin') : t('user.user')
  }

  return (
    <Header className="d-flex justify-between" style={{ padding: '15px', background: colorBgContainer, position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
      <div className="d-flex align-center" style={{ marginLeft: '40px' }}>
        {getPageTitle()}
      </div>
      {!isMobile && <div className="d-flex">
        <Dropdown menu={{ items: userOptions }} placement="bottom">
          <div className="d-flex align-center c-pointer" style={{ marginLeft: '20px' }}>
            <div className="mr-2">
              <p className="m-0 header-user-name">{handleUserName()}</p>
              <p className="m-0 header-role-name">{getUserRole()}</p>
            </div>
            <img src={ProfilePic} className="c-pointer" style={{ width: '38px', height: '38px', marginLeft: '10px' }} alt="Profile" />
          </div>
        </Dropdown>
      </div>}
    </Header>
  )
}

export default AppHeader
