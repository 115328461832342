import React, { useState } from 'react'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { userForgotPassword } from '../../redux'
import config from '../../config'
import configurableData from '../../config/configurable.json'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { emailValidation, posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [emailErr, setEmailErr] = useState<string>('')
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isLoading, isForgotPasswordMailSent } = useAppSelector((state: RootState) => {
    return state.auth
  })

  const handleEmailValidation = () => {
    if (!email.length) {
      setEmailErr(t('errMsg.mustFilled'))
    } else if (!emailValidation(email)) {
      setEmailErr(t('errMsg.email'))
    }
  }

  const handleFogotPassword = async () => {
    handleEmailValidation()
    if (!emailErr && !!email.length) {
      posthogCaptureEvent('trigger_forgot_password', { type: 'password', email })
      await dispatch(userForgotPassword({ email }))
    }
  }

  const handleNavigateLogin = (): void => {
    navigate(config.pages.login)
  }

  return (
    <div className="forgot-password">
      <div>
        <span onClick={handleNavigateLogin} className="c-pointer">
          {configurableData.fullName}
        </span>
      </div>
      {isForgotPasswordMailSent ? (
        <div className="forgot-password-content">
          <p className="font-medium text-green-600 text-center pb-4">{t('forgotPassword.successInfo')}</p>
        </div>
      ) : (
        <div className="forgot-password-content">
          <p style={{ fontSize: '30px', margin: '10px 0', textAlign: 'center' }}>{t('forgotPassword.title')}</p>
          <p style={{ margin: '5px 0 30px 0' }}>{t('forgotPassword.info')}</p>
          <Input
            allowClear={true}
            size="large"
            status={emailErr ? 'error' : ''}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.toLowerCase())
              setEmailErr('')
            }}
            onBlur={handleEmailValidation}
            placeholder={t('forgotPassword.placeholder.email')}
          />
          {emailErr && <p className="input-err-msg">{emailErr}</p>}
          <Button type="primary" loading={isLoading} size="large" style={{ marginTop: '20px' }} onClick={handleFogotPassword}>
            {t('forgotPassword.sendPasswordLink')}
          </Button>
          <div style={{ margin: '20px 0 0 0' }}>
            <span className="c-pointer" onClick={() => navigate(config.pages.forgotPassword)}>
              {t('forgotPassword.loginInfo')}
            </span>
            <span className="signIn" onClick={handleNavigateLogin}>
              {t('login.signin')}
            </span>
          </div>
        </div>
      )}
      <div className="p-6 font-light text-left">{configurableData.shortName} © 2024</div>
    </div>
  )
}

export default ForgotPassword
