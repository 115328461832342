import { useTranslation } from 'react-i18next'
import { Card, Tag, Button, Space } from 'antd'
import { MdOutlineBorderColor, MdOutlineDelete } from 'react-icons/md'
import { AiOutlineCopy } from 'react-icons/ai'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'
import { PageLoader } from '../../common/PageLoader'

interface CustomerTableMobileProps {
  handleViewClick: any
  handleEditClick: any
  handleDeleteClick: any
  handleCopyCustomerToken: any
}

const CustomerTableMobile: React.FC<CustomerTableMobileProps> = ({ handleViewClick, handleEditClick, handleDeleteClick, handleCopyCustomerToken }) => {
  const { t } = useTranslation()
  const { elementSize } = useAppSelector((state: RootState) => state.common)
  const { isSuperAdmin, isSystemAdmin, isUser } = useAppSelector((state: RootState) => state.auth)
  const { isLoading, customerActionLoading, customerList, pageMetaData } = useAppSelector((state: RootState) => state.customer)

  return (
    <>
      {isLoading || customerActionLoading ? (
        <PageLoader />
      ) : (
        <div className="d-flex flex-column" style={{ gap: '10px' }}>
          {customerList.map(({ ID, firstName, lastName, isActive, phoneNumber, token, pendingTransactionsCount, activeChits }: any, index: any) => (
            <Card size="small" style={{ width: '100%' }} bordered key={ID}>
              <div className="d-flex flex-column" style={{ gap: '6px' }}>
                <div className="d-flex justify-between align-center">
                  <div>
                    {' '}
                    <span className="text-gray"> {t('customers.tableHeaders.sNo')}: </span> {(pageMetaData.currentPage - 1) * 10 + index + 1}{' '}
                  </div>
                  <Tag className="m-0" color={isActive ? 'green' : 'red'}>
                    {t(`common.${isActive ? 'yes' : 'no'}`)}
                  </Tag>
                </div>
                <div className="c-pointer d-flex align-center" style={{ gap: '4px' }} onClick={() => handleCopyCustomerToken(token)}>
                  {`${firstName} ${lastName ? lastName : ''}`}
                  <span className="text-gray">|</span>
                  <span className="text-gray d-flex align-center">
                    <AiOutlineCopy />{' '}
                  </span>
                </div>
                <div className="d-flex flex-column" style={{ gap: '6px' }} onClick={() => handleViewClick(ID)}>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('customers.tableHeaders.phoneNumber')} </span> {phoneNumber}{' '}
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('customers.tableHeaders.pendingTransaction')} </span>
                    <span
                      style={{
                        fontWeight: pendingTransactionsCount ? 'bolder' : '',
                        color: pendingTransactionsCount > 1 ? 'red' : pendingTransactionsCount === 1 ? '#ff9966' : 'grey'
                      }}
                    >
                      {pendingTransactionsCount || '-'}
                    </span>
                  </div>
                  <div className="d-flex justify-between">
                    {' '}
                    <span className="text-gray"> {t('customers.tableHeaders.activeChits')} </span> <span> {activeChits || 0} </span>{' '}
                  </div>
                </div>
              </div>
              <Space className="d-flex justify-between" style={{ marginTop: '10px', gap: '10px' }}>
                {(isSuperAdmin || isSystemAdmin) && (
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} danger onClick={() => handleDeleteClick(ID, phoneNumber)}>
                    <span> {t('common.delete')} </span>
                    <MdOutlineDelete />
                  </Button>
                )}
                {!isUser && (
                  <Button size={elementSize} className="d-flex align-center" style={{ gap: '4px' }} type="primary" ghost onClick={() => handleEditClick(ID)}>
                    <span> {t('common.edit')} </span>
                    <MdOutlineBorderColor />
                  </Button>
                )}
              </Space>
            </Card>
          ))}
        </div>
      )}
    </>
  )
}

export default CustomerTableMobile
