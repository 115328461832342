import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  getChitGroupList,
  getChitTakenList,
  getChitGroupOverview,
  getChitGroupTimeline,
  getAutocompleteChitGroupList,
  getAutocompleteChitGroupCustomerList,
  getDefaultChitGroup,
  createChitGroup,
  updateChitGroup,
  deleteChitGroup
} from '../thunks/chitGroupThunk'
import { userLogout } from '../actions'
import { ChitGroupState } from '../../types/redux'

const initialState: ChitGroupState = {
  isLoading: false,
  chitActionLoading: false,
  chitTakenListLoading: false,
  chitAutoCompleteGroupLoading: false,
  chitAutoCompleteGroupCustomerLoading: false,
  chitGroupList: [],
  chitGroupOverview: [],
  chitGroupTimeline: [],
  chitGroupTakenList: [],
  filteredChitGroupList: [],
  filteredChitGroupCustomerList: [],
  removedCustomers: [],
  pageMetaData: {},
  defaultChitGroup: {},
  success: {},
  error: {}
}

export const chitGroupSlice = createSlice({
  name: 'chitGroup',
  initialState,
  reducers: {
    clearDefaultChitGroupData: (state) => {
      state.defaultChitGroup = {}
    },
    clearAutoCompleteChitGroupData: (state) => {
      state.filteredChitGroupList = []
    },
    clearAutoCompleteCustomerData: (state) => {
      state.filteredChitGroupCustomerList = []
    },
    setRemovedCustomers: (state, action) => {
      state.removedCustomers = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getChitGroupList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChitGroupList.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.success = {}
      state.chitGroupList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getChitGroupList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getAutocompleteChitGroupList.pending, (state) => {
      state.chitAutoCompleteGroupLoading = true
    })
    builder.addCase(getAutocompleteChitGroupList.fulfilled, (state, { payload }) => {
      state.chitAutoCompleteGroupLoading = false
      state.success = {}
      state.filteredChitGroupList = payload.data.data
    })
    builder.addCase(getAutocompleteChitGroupList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitAutoCompleteGroupLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getAutocompleteChitGroupCustomerList.pending, (state) => {
      state.chitAutoCompleteGroupCustomerLoading = true
    })
    builder.addCase(getAutocompleteChitGroupCustomerList.fulfilled, (state, { payload }) => {
      state.chitAutoCompleteGroupCustomerLoading = false
      state.success = {}
      state.filteredChitGroupCustomerList = payload.data.data
    })
    builder.addCase(getAutocompleteChitGroupCustomerList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitAutoCompleteGroupCustomerLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getDefaultChitGroup.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDefaultChitGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.defaultChitGroup = payload.data.data
    })
    builder.addCase(getDefaultChitGroup.rejected, (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(createChitGroup.pending, (state) => {
      state.chitActionLoading = true
    })
    builder.addCase(createChitGroup.fulfilled, (state, { payload }) => {
      state.chitActionLoading = false
      state.success = payload.data
    })
    builder.addCase(createChitGroup.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(updateChitGroup.pending, (state) => {
      state.chitActionLoading = true
    })
    builder.addCase(updateChitGroup.fulfilled, (state, { payload }) => {
      state.chitActionLoading = false
      state.success = payload.data
    })
    builder.addCase(updateChitGroup.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(deleteChitGroup.pending, (state) => {
      state.chitActionLoading = true
    })
    builder.addCase(deleteChitGroup.fulfilled, (state, { payload }) => {
      state.chitActionLoading = false
      state.success = payload.data
    })
    builder.addCase(deleteChitGroup.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitActionLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getChitTakenList.pending, (state) => {
      state.chitTakenListLoading = true
    })
    builder.addCase(getChitTakenList.fulfilled, (state, { payload }) => {
      state.chitTakenListLoading = false
      state.chitGroupTakenList = payload.data.data
      state.pageMetaData = payload.data.pageMetaData
    })
    builder.addCase(getChitTakenList.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitTakenListLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getChitGroupOverview.pending, (state) => {
      state.chitTakenListLoading = true
    })
    builder.addCase(getChitGroupOverview.fulfilled, (state, { payload }) => {
      state.chitTakenListLoading = false
      state.chitGroupOverview = payload.data.data
    })
    builder.addCase(getChitGroupOverview.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitTakenListLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(getChitGroupTimeline.pending, (state) => {
      state.chitTakenListLoading = true
    })
    builder.addCase(getChitGroupTimeline.fulfilled, (state, { payload }) => {
      state.chitTakenListLoading = false
      state.chitGroupTimeline = payload.data.data
    })
    builder.addCase(getChitGroupTimeline.rejected, (state, { payload }: PayloadAction<any>) => {
      state.chitTakenListLoading = false
      state.error = payload?.response?.data?.error
    })
    builder.addCase(userLogout, () => {
      return {
        isLoading: false,
        chitActionLoading: false,
        chitTakenListLoading: false,
        chitAutoCompleteGroupLoading: false,
        chitAutoCompleteGroupCustomerLoading: false,
        chitGroupList: [],
        filteredChitGroupList: [],
        filteredChitGroupCustomerList: [],
        removedCustomers: [],
        pageMetaData: {},
        defaultChitGroup: {},
        chitGroupTakenList: [],
        chitGroupOverview: [],
        chitGroupTimeline: [],
        success: {},
        error: {}
      }
    })
  }
})

export const { clearDefaultChitGroupData, clearAutoCompleteChitGroupData, clearAutoCompleteCustomerData, setRemovedCustomers } = chitGroupSlice.actions

export const chitGroupReducer = chitGroupSlice.reducer
