import React, { useState, useEffect } from 'react'
import { roleOptions, loginDisabledOptions } from '../../utils/data'
import { getUserList, createUser, updateUser, clearDefaultUserData, setToast, createLogSnag } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { handleFilterValuesFromData, emailValidation, phoneNoValidation, allowOnlyNumbers } from '../../utils'
import constants from '../../constants'
import { Input, Button, Modal, Select, InputNumber, } from 'antd'
import { useTranslation } from 'react-i18next'
import { ModalLoader } from '../../components/common/PageLoader'
import UserInfo from '../../components/users/userInfo'
import type { RootState } from '../../redux'

interface ModalProps {
  visibleModal: boolean
  isView: boolean
  closeModal: any
  currentPage: number
}

const CreateUserModal: React.FC<ModalProps> = ({ visibleModal, isView, closeModal, currentPage }) => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [role, setRole] = useState<any>(roleOptions[2])
  const [isLoginDisabled, setIsLoginDisabled] = useState<any>(loginDisabledOptions[1])

  const [firstNameErr, setFirstNameErr] = useState<boolean>(false)
  const [phoneNumberErr, setPhoneNumberErr] = useState<boolean>(false)
  const [emailErr, setEmailErr] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isLoading, userActionLoading, defaultUser, success } = useAppSelector((state: RootState) => state.user)
  const { isMobile, elementSize } = useAppSelector((state) => state.common)

  useEffect(() => {
    setFirstNameErr(false)
    setPhoneNumberErr(false)
    setEmailErr(false)
  }, [visibleModal])

  useEffect(() => {
    handleSetUserValues()
  }, [defaultUser])

  useEffect(() => {
    if (success.type === 'Success') {
      dispatch(getUserList({ currentPage, size: constants.PAGE_SIZE }))
      closeModal()
    }
  }, [success])

  const handleSetUserValues = () => {
    const data = defaultUser
    if (Object.keys(defaultUser).length) {
      setFirstName(data.firstName)
      setLastName(data.lastName)
      setPhoneNumber(data.phoneNumber)
      setEmail(data.email)
      setRole(
        data.role === constants.role.SYSTEM_ADMIN ? { label: constants.role.SYSTEM_ADMIN, value: constants.role.SYSTEM_ADMIN } : handleFilterValuesFromData(data.role, roleOptions)
      )
      setIsLoginDisabled(handleFilterValuesFromData(data.isLoginDisabled, loginDisabledOptions))
    } else {
      setFirstName('')
      setLastName('')
      setPhoneNumber('')
      setEmail('')
      setRole(roleOptions[2])
      setIsLoginDisabled(loginDisabledOptions[1])
    }
  }

  const handleDisableUpdateButton = () => {
    return (
      firstName !== defaultUser.firstName ||
      lastName !== defaultUser.lastName ||
      parseInt(phoneNumber) !== parseInt(defaultUser.phoneNumber) ||
      (isLoginDisabled?.label ? isLoginDisabled?.value : isLoginDisabled) !== defaultUser.isLoginDisabled ||
      email !== defaultUser.email ||
      (role?.value || role) !== defaultUser.role
    )
  }

  const handleCreateUser = async () => {
    setFirstNameErr(firstName.length < 4)
    setEmailErr(!emailValidation(email))
    setPhoneNumberErr(!phoneNoValidation(phoneNumber))

    if (firstName.length > 4 && emailValidation(email) && phoneNoValidation(phoneNumber)) {
      const payload: any = {
        firstName: firstName.trim(),
        lastName: lastName ? lastName.trim() : '',
        extension: '91',
        phoneNumber: phoneNumber.toString(),
        email,
        role: role.value ? role.value : role,
        isActive: defaultUser.isActive,
        isLoginDisabled: isLoginDisabled.label ? isLoginDisabled.value : isLoginDisabled
      }
      if (defaultUser.ID) {
        payload['ID'] = parseInt(defaultUser.ID, 10)
        const res: any = await dispatch(updateUser(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.updatedUserSuccess')
          }))
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      } else {
        const logSnagPayload = {
          channel: 'user-activity',
          event: 'New User Created',
          description: JSON.stringify(payload)
        }
        dispatch(createLogSnag(logSnagPayload))
        const res: any = await dispatch(createUser(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.createdUserSuccess')
          }))
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      }
    }
  }

  const handleMobileNumber = (value: any) => {
    if (allowOnlyNumbers(value)) {
      setPhoneNumber(value)
      setPhoneNumberErr(false)
    }
  }

  const handleNavigateToUsers = () => {
    dispatch(clearDefaultUserData())
    closeModal()
  }

  const handlePhoneNoValidation = () => {
    setPhoneNumberErr(!phoneNoValidation(phoneNumber))
  }

  const handleEmailValidation = () => {
    setEmailErr(!emailValidation(email))
  }

  const modalStyle = {
    body: {
      padding: '10px 0 20px 0'
    }
  }

  return (
    <Modal
      open={visibleModal}
      title={`${isView ? t('common.view') : defaultUser.ID ? t('common.edit') : t('common.create')} ${t('createUser.user')}`}
      onOk={handleCreateUser}
      onCancel={handleNavigateToUsers}
      maskClosable={false}
      style={{ top: isMobile ? 20 : undefined }}
      styles={modalStyle}
      width={1000}
      footer={
        isView
          ? [
              <Button size={elementSize} key="back" onClick={handleNavigateToUsers}>
                {isView ? t('common.close') : t('common.cancel')}
              </Button>
            ]
          : [
              <Button size={elementSize} key="back" disabled={userActionLoading} onClick={handleNavigateToUsers}>
                {isView ? t('common.close') : t('common.cancel')}
              </Button>,
              <Button size={elementSize} key="submit" type="primary" loading={userActionLoading} onClick={handleCreateUser} disabled={defaultUser.ID ? !handleDisableUpdateButton() : false}>
                {defaultUser.ID ? t('common.update') : t('common.create')}
              </Button>
            ]
      }
    >
      {isLoading || userActionLoading ? (
        <ModalLoader />
      ) : (
        <div className="modal-split">
          <div className="w-100">
            <div className="input-container">
              <label className="input-label">
                {t('createUser.firstName')}
                <span className="input-mandatory">*</span>
              </label>
              <Input
                allowClear={true}
                status={firstNameErr ? 'error' : ''}
                readOnly={isView}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                  setFirstNameErr(false)
                }}
                placeholder={t('createUser.placeholder.firstName')}
              />
              {firstNameErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">{t('createUser.lastName')}</label>
              <Input
                allowClear={true}
                readOnly={isView}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t('createUser.placeholder.lastName')}
              />
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createUser.email')}
                <span className="input-mandatory">*</span>
              </label>
              <Input
                allowClear={true}
                readOnly={isView}
                value={email}
                onBlur={handleEmailValidation}
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase())
                  setEmailErr(false)
                }}
                status={emailErr ? 'error' : ''}
                placeholder={t('createUser.placeholder.email')}
              />
              {emailErr && <p className="input-err-msg">{t('errMsg.email')}</p>}
            </div>
          </div>
          <div className="w-100">
            <div className="input-container">
              <label className="input-label">
                {t('createUser.phoneNumber')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                status={phoneNumberErr ? 'error' : ''}
                readOnly={isView}
                value={phoneNumber}
                maxLength={constants.length.PHONE_NUMBER}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                onBlur={handlePhoneNoValidation}
                onChange={handleMobileNumber}
                style={{ width: '100%' }}
                placeholder={t('createUser.placeholder.phoneNumber')}
              />
              {phoneNumberErr && <p className="input-err-msg">{t('errMsg.phoneNo')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createUser.role')}
                <span className="input-mandatory">*</span>
              </label>
              <Select
                value={role}
                defaultValue={role}
                disabled={isView || defaultUser.role === constants.role.SYSTEM_ADMIN}
                options={roleOptions}
                onChange={(option) => setRole(option)}
              />
            </div>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%', marginBottom: '20px' }}>
              <label className="input-label">{t('createUser.loginDisabled')}</label>
              <Select
                value={isLoginDisabled}
                defaultValue={isLoginDisabled}
                disabled={isView || defaultUser.role === constants.role.SYSTEM_ADMIN}
                options={loginDisabledOptions}
                onChange={(option) => setIsLoginDisabled(option)}
              />
            </div>
            {isView && <UserInfo userInfo={defaultUser} />}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CreateUserModal
