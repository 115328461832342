import { useTranslation } from 'react-i18next'
import { Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { currencyFormatter, getDate, handleFilterValuesFromData } from '../../../utils'
import { paymentMethodOptions } from '../../../utils/data'
import { useAppSelector } from '../../../redux/hooks'
import type { RootState } from '../../../redux'

interface ChitReportTableDesktopProps {
  data: any
}

interface DataType {
  id: number
  customerName: string
  installmentNumber: number
  paymentReceived: boolean
  isPartial: boolean
  payableAmount: number
  amount: string
  balanceAmount: string
  transactionDate: Date
  pendingMonths: number
  paymentMethod: Date
}

const ChitReportTableDesktop: React.FC<ChitReportTableDesktopProps> = ({ data }) => {
  const { t } = useTranslation()
  const { isLoading, chitReportPageMetaData } = useAppSelector((state: RootState) => state.report)

  const columns: ColumnsType<DataType> = [
    {
      title: t('transactions.customers.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(chitReportPageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('transactions.customers.tableHeaders.customerName'),
      dataIndex: 'customerName',
      key: 'customerName'
    },
    {
      title: t('transactions.customers.tableHeaders.monthlyInstallment'),
      dataIndex: 'installmentNumber',
      key: 'installmentNumber'
    },
    {
      title: t('transactions.customers.tableHeaders.paymentReceived'),
      dataIndex: 'paymentReceived',
      render: (_, record) => <Tag color={record.balanceAmount === '0' ? 'green' : 'red'}>{record.balanceAmount === '0' ? t('common.yes') : t('common.no')}</Tag>
    },
    {
      title: t('transactions.customers.tableHeaders.partialPayment'),
      dataIndex: 'isPartial',
      key: 'isPartial',
      render: (isPartial) => <Tag color={isPartial ? 'green' : ''}>{isPartial ? t('common.yes') : t('common.no')}</Tag>
    },
    {
      title: t('transactions.customers.tableHeaders.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.balanceAmount'),
      dataIndex: 'balanceAmount',
      key: 'balanceAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.payableAmount'),
      dataIndex: 'payableAmount',
      key: 'payableAmount',
      render: (value) => <div>{currencyFormatter(value)}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.transactionDate'),
      dataIndex: 'transactionDate',
      key: 'transactionDate',
      render: (value) => <div>{value ? getDate(value) : '-'}</div>
    },
    {
      title: t('transactions.customers.tableHeaders.paymentMethod'),
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (value) => {
        const paymentMethodValue = handleFilterValuesFromData(value, paymentMethodOptions)
        return <div>{paymentMethodValue?.label || '-'}</div>
      }
    }
  ]

  return (
    <>
      <Table size="middle" rowKey={(record: { id: number }) => record.id} loading={isLoading} columns={columns as any} dataSource={data} pagination={false} scroll={{ x: 1300 }} />
    </>
  )
}

export default ChitReportTableDesktop
