import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { CheckBadgeIcon } from '@heroicons/react/24/solid'
import { userResetPassword } from '../../redux'
import config from '../../config'
import configurableData from '../../config/configurable.json'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useTranslation } from 'react-i18next'
import { Input, Button } from 'antd'
import constants from '../../constants'
import { posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [errMsg, setErrMsg] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation().search
  const queryParams = new URLSearchParams(location)
  const { t } = useTranslation()

  const { isLoading, isResetPassword, error } = useAppSelector((state: RootState) => state.auth)

  const handleResetPassword = (): void => {
    if (password.length && confirmPassword.length && password.length === confirmPassword.length) {
      posthogCaptureEvent('trigger_reset_password', { type: 'password' })
      dispatch(userResetPassword({ resetString: queryParams.get('resetString'), password: confirmPassword }))
    } else {
      handleValidateFields()
    }
  }

  const handleValidateFields = () => {
    if (!password.length || !confirmPassword.length) {
      setErrMsg(t('errMsg.mustFilled'))
    } else if (password.length < constants.length.PASSWORD || confirmPassword.length < constants.length.PASSWORD) {
      setErrMsg(t('errMsg.passwordLength'))
    } else if (password !== confirmPassword) {
      setErrMsg(t('resetPassword.info'))
    } else {
      setErrMsg('')
    }
  }

  const handleNavigateLogin = (): void => {
    navigate(config.pages.login)
  }

  return (
    <div className="forgot-password">
      <div>
        <span onClick={handleNavigateLogin}>{configurableData.fullName}</span>
      </div>
      {!isResetPassword ? (
        <div className="forgot-password-content">
          <h2 className="m-0" style={{ textAlign: 'center', fontSize: '30px' }}>
            {t('resetPassword.title')}
          </h2>
          <p style={{ color: '#666', margin: '10px 0 30px 0' }}>{t('resetPassword.info')}</p>
          <div className="input-container">
            <label className="input-label">{t('resetPassword.password')}</label>
            <Input.Password
              allowClear={true}
              status={errMsg ? 'error' : ''}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={handleValidateFields}
              placeholder={t('resetPassword.placeholder.password')}
            />
          </div>
          <div className="input-container">
            <label className="input-label">{t('resetPassword.confirmPassword')}</label>
            <Input.Password
              allowClear={true}
              status={errMsg ? 'error' : ''}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={handleValidateFields}
              placeholder={t('resetPassword.placeholder.confirmPassword')}
            />
            {(errMsg || error) && <p className="input-err-msg">{errMsg || error}</p>}
          </div>
          <Button type="primary" style={{ margin: '10px 0' }} size="large" loading={isLoading} onClick={handleResetPassword}>
            {t('resetPassword.updatePassword')}
          </Button>
        </div>
      ) : (
        <div className="forgot-password-content">
          <div className="d-flex justify-center align-center">
            <CheckBadgeIcon style={{ color: 'green', width: '80px', height: 'auto' }} />
          </div>
          <h2 style={{ margin: 0, textAlign: 'center' }}>{t('resetPassword.reset')}</h2>
          <p className="font-medium text-gray-400 text-center pb-7">{t('resetPassword.resetSuccessInfo')}</p>
          <Button type="primary" style={{ margin: '20px 0' }} size="large" onClick={handleNavigateLogin}>
            {t('resetPassword.continue')}
          </Button>
        </div>
      )}
      <div className="p-6 font-light text-left">{configurableData.shortName} © 2024</div>
    </div>
  )
}

export default ResetPassword
