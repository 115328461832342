import { useTranslation } from 'react-i18next'
import { allowOnlyNumbers } from '../../utils'
import { dateOptions } from '../../utils/data'
import constants from '../../constants'

import { Input, InputNumber, DatePicker, Select } from 'antd'
const { TextArea } = Input

interface ChitGroupDetailProps {
  isMobile: boolean
  viewChitGroup: boolean
  isChitActive: boolean
  nameErr: string
  groupNameErr: string
  startDateErr: string
  amountErr: string
  totalMonthsErr: string
  chitGroupName: string
  setChitGroupName: any
  chitName: string
  setChitName: any
  description: string
  setDescription: any
  tnc: string
  setTnC: any
  auctionDay: any
  setAuctionDay: any
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
  amount: any
  setAmount: any
  totalMonths: any
  frequencyMonths: any
  setTotalMonths: any
  setFrequencyMonths: any
  setNameErr: any
  setGroupNameErr: any
  setStartDateErr: any
  setAmountErr: any
  setTotalMonthsErr: any
}

const ChitGroupDetails: React.FC<ChitGroupDetailProps> = ({
  viewChitGroup,
  isChitActive,
  nameErr,
  groupNameErr,
  startDateErr,
  amountErr,
  totalMonthsErr,
  chitName,
  setChitName,
  chitGroupName,
  setChitGroupName,
  description,
  setDescription,
  tnc,
  setTnC,
  auctionDay,
  setAuctionDay,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  amount,
  setAmount,
  totalMonths,
  frequencyMonths,
  setTotalMonths,
  setFrequencyMonths,
  setNameErr,
  setGroupNameErr,
  setStartDateErr,
  setAmountErr,
  setTotalMonthsErr
}) => {
  const { t } = useTranslation()

  const handleChitAmount = (value: any) => {
    if (allowOnlyNumbers(value)) {
      setAmount(value)
      setAmountErr('')
    }
  }

  const handleChitMonths = (value: any, type: string) => {
    if (allowOnlyNumbers(value)) {
      if (type === 'FREQUENCY') {
        setFrequencyMonths(value)
      } else {
        setTotalMonths(value)
        setTotalMonthsErr('')
      }
    }
  }

  return (
    <div className="create-page-container">
      <div className="create-page-section">
        <div className="input-container">
          <label className="input-label">
            {t('createChitGroup.chitName')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={nameErr ? 'error' : ''}
            readOnly={viewChitGroup}
            value={chitName}
            placeholder={t('createChitGroup.placeholder.chitName')}
            onChange={(e) => {
              setChitName(e.target.value)
              setNameErr(e.target.value.length > 3 ? '' : 'ERROR')
            }}
          />
          {nameErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">
            {t('createChitGroup.chitGroupName')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={groupNameErr ? 'error' : ''}
            readOnly={viewChitGroup}
            value={chitGroupName}
            placeholder={t('createChitGroup.placeholder.chitGroupName')}
            onChange={(e) => {
              setChitGroupName(e.target.value)
              setGroupNameErr(e.target.value.length > 3 ? '' : 'ERROR')
            }}
          />
          {groupNameErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <div className="d-flex" style={{ gap: '10px' }}>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
              <label className="input-label">
                {t('createChitGroup.chitAmount')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                addonBefore="₹"
                status={amountErr ? 'error' : ''}
                readOnly={viewChitGroup || isChitActive}
                value={amount}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={constants.length.CURRENCY}
                formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('createChitGroup.placeholder.chitAmount')}
                onChange={handleChitAmount}
              />
            </div>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
              <label className="input-label">
                {t('createChitGroup.auctionDate')}
                <span className="input-mandatory">*</span>
              </label>
              <Select
                value={auctionDay}
                options={dateOptions}
                disabled={viewChitGroup || isChitActive}
                onChange={(option: any) => setAuctionDay(option)}
                style={{ width: '100%' }}
              />
            </div>
          </div>
          {amountErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>

        <div className="input-container">
          <div className="d-flex" style={{ gap: '10px' }}>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
              <label className="input-label">
                {t('createChitGroup.totalMonths')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                readOnly={viewChitGroup || isChitActive}
                status={totalMonthsErr ? 'error' : ''}
                value={totalMonths}
                min={1}
                max={50}
                maxLength={2}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder={t('createChitGroup.placeholder.totalMonths')}
                onChange={e => handleChitMonths(e, '')}
                style={{ width: '100%' }}
              />
              {totalMonthsErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
              <label className="input-label"> {t('createChitGroup.frequency')} </label>
              <InputNumber
                controls={false}
                readOnly={viewChitGroup || isChitActive}
                value={frequencyMonths}
                min={1}
                max={9}
                maxLength={1}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                placeholder={t('createChitGroup.placeholder.frequency')}
                onChange={e => handleChitMonths(e, 'FREQUENCY')}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>

        <div className="input-container">
          <div className="d-flex" style={{ gap: '10px' }}>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
              <label className="input-label">
                {t('createChitGroup.startingDate')}
                <span className="input-mandatory">*</span>
              </label>
              <DatePicker
                value={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  setStartDateErr('')
                }}
                placeholder={t('createChitGroup.placeholder.startingDate')}
                disabled={viewChitGroup || isChitActive}
                format="YYYY-MM"
                picker="month"
                allowClear={!viewChitGroup || !isChitActive}
                status={startDateErr ? 'error' : ''}
              />
            </div>
            <div className="d-flex" style={{ flexDirection: 'column', width: '100%' }}>
              <label className="input-label">{t('createChitGroup.endingDate')}</label>
              <DatePicker
                value={endDate}
                onChange={(date) => setEndDate(date)}
                placeholder={t('createChitGroup.placeholder.endingDate')}
                disabled={viewChitGroup || isChitActive}
                allowClear={!viewChitGroup || !isChitActive}
                picker="month"
                format="YYYY-MM"
              />
            </div>
          </div>
          {startDateErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
      </div>

      <div className="create-page-section">
        <div className="input-container">
          <label className="input-label">{t('createChitGroup.tnc')}</label>
          <TextArea
            rows={4}
            placeholder={t('createChitGroup.placeholder.tnc')}
            style={{ resize: 'none' }}
            allowClear={!viewChitGroup}
            readOnly={viewChitGroup}
            value={tnc}
            onChange={(e) => setTnC(e.target.value)}
          />
        </div>
        <div className="input-container">
          <label className="input-label">{t('createChitGroup.chitDescription')}</label>
          <TextArea
            rows={4}
            placeholder={t('createChitGroup.placeholder.chitDescription')}
            style={{ resize: 'none' }}
            allowClear={!viewChitGroup}
            readOnly={viewChitGroup}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default ChitGroupDetails
