import { Bar } from '@ant-design/plots'

const CollectionDetailsByAmount = ({ t, data }) => {
  const config = {
    data,
    isGroup: true,
    xField: 'value',
    yField: 'label',
    color: ['green', '#c52125'],
    seriesField: 'type',
    marginRatio: 0,
    dodgePadding: 1,
    intervalPadding: 13,
    label: {
      position: 'right',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    },
    barStyle: {
      radius: [2, 2, 0, 0]
    },
    legend: {
      position: 'bottom-left'
    },
    minColumnWidth: 30,
    maxColumnWidth: 40
  }

  return (
    <div className="w-100">
      <p className="text-center">{t('analytics.collectionDetailAmountTitle')}</p>
      <Bar {...config} />
    </div>
  )
}

export default CollectionDetailsByAmount
