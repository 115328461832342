import React, { useState, useEffect } from 'react'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { userLogin, createLogSnag } from '../../redux'
import config from '../../config'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import configurableData from '../../config/configurable.json'
import { emailValidation } from '../../utils'
import { Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { getErrorMessage } from '../../utils/errMsg'
import { posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

import LoginImage3 from '../../assets/images/login/digital.svg'

const contentStyle: React.CSSProperties = {
  width: '55%',
  height: '55%'
}

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()
  const [userName, setUserName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errMsg, setErrMsg] = useState<string>('')
  const [emailErr, setEmailErr] = useState<string>('')
  const { t } = useTranslation()

  const { isLoading, error } = useAppSelector((state: RootState) => state.auth)

  useEffect(() => {
    setErrMsg(getErrorMessage(error?.message))
  }, [error])

  useEffect(() => {
    const token: any = window.localStorage.getItem('jwt')
    if (token) {
      posthogCaptureEvent('user_login')
      navigate(config.pages.dashboard)
    }
  }, [])

  const handleEmailValidation = () => {
    setEmailErr(!emailValidation(userName) ? t('errMsg.email') : '')
  }

  const handleNavigateToHome = () => {
    navigate(config.pages.landingPage)
  }

  const handleSignIn = async () => {
    const payload = {
      email: userName,
      password
    }
    if (!emailValidation(userName) || password?.length < 6) {
      setErrMsg(t('errMsg.inValidLoginData'))
    } else {
      setErrMsg('')
      const res: any = await dispatch(userLogin(payload))
      if (res.payload.status === 200) {
        window.localStorage.setItem('jwt', res.payload.data.data || '')
        const payload = {
          channel: 'user-activity',
          event: 'User Logged In',
          description: res.payload.data.data
        }
        dispatch(createLogSnag(payload))
        navigate(config.pages.dashboard)
      }
    }
  }

  return (
    <div className="broken-page-container">
      <div className="login-img-container">
        <div className="c-pointer" onClick={handleNavigateToHome}>
          {configurableData.fullName}
        </div>
        <div className='d-flex flex-column justify-center align-center gap-3'>
          <img src={LoginImage3} style={contentStyle} alt='Login' />
          <div className='d-flex flex-column align-center gap-1'>
            <h2 style={{ color: '#333', margin: '10px 0' }}> {t('login.title')} </h2>
            <div style={{ width: '60%', lineHeight: '24px', textAlign: 'center' }}> {t('login.description')} </div>
          </div>
        </div>
        <div className="p-6 font-light text-left">{configurableData.shortName} © 2024</div>
      </div>
      <div className="login-page-content">
        <div className="login-form">
          <h2 style={{ color: '#333', margin: '10px 0' }}>{t('login.welcomeBack')}</h2>
          <p style={{ color: '#666', margin: '0 0 30px 0' }}>{t('login.info')}</p>
          <div className="input-container">
            <label className="input-label">{t('login.email')}</label>
            <Input
              allowClear={!isLoading}
              status={errMsg || emailErr ? 'error' : ''}
              name="email"
              type="email"
              size="large"
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value.toLowerCase())
                setErrMsg('')
              }}
              onBlur={handleEmailValidation}
              placeholder={t('login.placeholder.email')}
            />
            {emailErr && <p className="input-err-msg">{emailErr}</p>}
          </div>
          <div className="input-container relative">
            <label className="input-label">{t('login.password')}</label>
            <Input.Password
              allowClear={!isLoading}
              size="large"
              status={errMsg ? 'error' : ''}
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setErrMsg('')
              }}
              placeholder={t('login.placeholder.password')}
            />
            {errMsg && <p className="input-err-msg">{errMsg}</p>}
          </div>
          <div style={{ margin: '10px 0 20px 0' }}>
            <span className="c-pointer" onClick={() => navigate(config.pages.forgotPassword)}>
              {t('login.forgotPassword')}
            </span>
          </div>
          <Button type="primary" style={{ margin: '10px 0' }} size="large" onClick={handleSignIn} loading={isLoading}>
            {t('login.signin')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
