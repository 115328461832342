import { Card, Space } from 'antd'
import { currencyFormatter } from '../../utils'

interface OverAllDataProps {
  t: any
  isMobile: boolean
  data: any
}

const OverAllData: React.FC<OverAllDataProps> = ({ t, isMobile, data }) => {
  const formattedData = [
    {
      label: t('analytics.allTransactions'),
      value: data?.allChitTransactions?.amount,
      count: data?.allChitTransactions?.count
    },
    {
      label: t('analytics.fullTransactions'),
      value: data?.completedChitTransactions?.amount,
      count: data?.completedChitTransactions?.count
    },
    {
      label: t('analytics.partialTransactions'),
      value: data?.partialChitTransactions?.amount,
      count: data?.partialChitTransactions?.count,
      danger: true
    },
    {
      label: t('analytics.pendingTransactions'),
      value: data?.pendingChitTransactions?.amount,
      count: data?.pendingChitTransactions?.count,
      danger: true
    },
    {
      label: t('analytics.onTimeTransactions'),
      value: data?.onTimeChitTransactions?.amount,
      count: data?.onTimeChitTransactions?.count
    },
    {
      label: t('analytics.delayedTransactions'),
      value: data?.delayedChitTransactions?.amount,
      count: data?.delayedChitTransactions?.count,
      danger: true
    }
  ]

  return (
    <div style={{ marginTop: '10px' }}>
      <Space direction="horizontal" size={20} wrap className="justify-center">
        {formattedData.map(({ label, value, count, danger }: any, index) => (
          <Card size="small" title={label} style={{ width: isMobile ? '150px' : '222px' }} key={index}>
            <p className={`text-center m-0 ${danger ? 'danger' : ''}`}>{count}</p>
            <p style={{ marginTop: '5px' }} className={`text-center m-0 ${danger ? 'danger' : ''}`}>
              {currencyFormatter(value)}
            </p>
          </Card>
        ))}
      </Space>
    </div>
  )
}

export default OverAllData
